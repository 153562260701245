import useTrainTracks, { TrainTrackLayer } from "./useTrainTracks";

interface IProps {
    layer?: TrainTrackLayer;
}

export default function useResponsivePadding(props: IProps = {}) {
    const { layer = "0" } = props;

    const trainTracks = useTrainTracks();

    return trainTracks[layer].padding;
}
