import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { getCookiePreferences } from "./CookiePreferenceStorageService";
import ICookiePreferences from "./ICookiePreferences";

interface IProps {
    children: ReactNode;
    onPreferencesUpdate: (preferences: ICookiePreferences) => void;
}

export interface ICookieContext {
    cookieBannerIsOpen: boolean;
    preferences?: ICookiePreferences;
    onCloseOverlay: () => void;
    savePreferences: (preferences: ICookiePreferences) => void;
}

const defaultCookieContext: ICookieContext = {
    cookieBannerIsOpen: false,
    preferences: undefined,
    onCloseOverlay: () => {},
    savePreferences: () => {},
};

const CookieContext = createContext<ICookieContext>(defaultCookieContext);

export const CookieContextProvider = (props: IProps) => {
    const { onPreferencesUpdate, children } = props;

    const localStoragePreferences = getCookiePreferences();

    const [preferences, setPreferences] = useState<ICookiePreferences | undefined>(localStoragePreferences);

    const [cookieBannerIsOpen, setCookieBannerIsOpen] = useState<boolean>(typeof preferences === "undefined");

    const onCloseOverlay = () => {
        setCookieBannerIsOpen(false);
    };

    const savePreferences = (_preferences: ICookiePreferences) => {
        setPreferences(_preferences);
        onPreferencesUpdate(_preferences);
    };

    const cookieStore: ICookieContext = {
        cookieBannerIsOpen: cookieBannerIsOpen,
        preferences: preferences,
        onCloseOverlay: onCloseOverlay,
        savePreferences: savePreferences,
    };

    useEffect(() => {
        if (typeof preferences === "undefined") return;

        onPreferencesUpdate(preferences);
    }, [preferences]);

    useEffect(() => {
        document.body.addEventListener("truste-consent", () => onCloseOverlay());

        return () => {
            document.body.removeEventListener("truste-consent", () => {});
        };
    }, [cookieBannerIsOpen]);

    return <CookieContext.Provider value={cookieStore}>{children}</CookieContext.Provider>;
};

export const useCookieContext = () => useContext(CookieContext);
