import { ReactNode } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Spacer from "common/components/Spacer";
import background from "assets/images/backgrounds/error.jpg";
import { ITheme } from "@material-ui/core";
import useResponsivePaddingClass from "hooks/useResponsivePaddingClass";
import clsx from "clsx";
import { styleGuide } from "theme";
import { isRaceNetMobileApp, isRaceNetTabletApp, webViewPostMessage } from "utils/webViewTools";
import StyledButton from "common/components/buttons/StyledButton";
import { WebViewEnum } from "common/enums/WebViewEventsEnum";

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        root: {
            background: theme.palette.background.default,
            width: "100%",
            height: "100vh",
            display: "grid",
        },
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        header: {
            color: theme.variants.lightWhite,
        },
        row: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
        },
        title: {
            color: theme.palette.info.main,
        },
        text: {
            fontWeight: "normal",
            color: styleGuide.color.white,
        },
    })
);

interface IProps {
    title?: string;
    text?: string;
    action?: ReactNode;
}

export default function ErrorPage(props: IProps) {
    const { title, text, action } = props;
    const paddingClass = useResponsivePaddingClass({ layer: "0" });
    const classes = useStyles();

    const nativeBackButton = (
        <StyledButton variant="infoPrimary" onClick={() => webViewPostMessage(WebViewEnum.GoBack)}>
            <Typography>Go back</Typography>
        </StyledButton>
    );

    return (
        <div className={classes.root}>
            <div className={clsx(classes.row, paddingClass)}>
                <Typography className={classes.header} variant="h2">
                    EA Racenet
                </Typography>
            </div>
            <div className={clsx(classes.content, paddingClass)}>
                {title === undefined ? null : (
                    <Typography className={classes.title} variant="h4" align="center">
                        {title}
                    </Typography>
                )}
                {text === undefined ? null : (
                    <Typography className={classes.text} variant="h6" align="center" gutterBottom>
                        {text}
                    </Typography>
                )}
                {isRaceNetMobileApp || isRaceNetTabletApp ? (
                    nativeBackButton
                ) : action === undefined ? null : (
                    <>
                        <Spacer size={1.5} />
                        {action}
                    </>
                )}
            </div>
        </div>
    );
}
