import { ReactNode } from "react";
import StyledButton, { IStyledButtonProps } from "./StyledButton";
import NewTabLink from "common/components/links/NewTabLink";

interface IProps extends IStyledButtonProps {
    children?: ReactNode;
    href: string;
}

export default function NewTabLinkButton(props: IProps) {
    const { children, href, ...rest } = props;

    return (
        <StyledButton
            //@ts-ignore
            component={NewTabLink}
            href={href}
            underline="none"
            {...rest}
        >
            {children}
        </StyledButton>
    );
}
