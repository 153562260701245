import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ProfileIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M11.985,11.592c1.514,0,2.821-0.544,3.894-1.616s1.616-2.38,1.616-3.894s-0.544-2.821-1.616-3.894s-2.38-1.616-3.894-1.616 S9.164,1.115,8.091,2.188s-1.616,2.38-1.616,3.894s0.544,2.821,1.616,3.894C9.164,11.049,10.472,11.592,11.985,11.592z" />
                <path d="M21.625,18.161c-0.029-0.441-0.088-0.926-0.191-1.44c-0.088-0.514-0.206-0.999-0.353-1.455 c-0.147-0.456-0.353-0.911-0.602-1.352c-0.264-0.456-0.558-0.852-0.896-1.176c-0.353-0.338-0.793-0.617-1.293-0.808 c-0.5-0.206-1.058-0.294-1.646-0.294c-0.235,0-0.456,0.103-0.896,0.382c-0.264,0.176-0.588,0.382-0.926,0.602 c-0.294,0.191-0.705,0.367-1.205,0.529c-0.485,0.162-0.985,0.235-1.469,0.235s-0.985-0.073-1.469-0.235 c-0.5-0.162-0.911-0.338-1.205-0.529c-0.353-0.22-0.661-0.426-0.94-0.602c-0.441-0.279-0.661-0.382-0.896-0.382 c-0.588,0-1.146,0.103-1.646,0.294c-0.5,0.206-0.94,0.47-1.293,0.808c-0.338,0.323-0.647,0.72-0.896,1.176 c-0.25,0.441-0.456,0.896-0.602,1.352c-0.147,0.441-0.264,0.94-0.353,1.455c-0.088,0.514-0.162,0.999-0.191,1.44 c-0.029,0.441-0.044,0.896-0.044,1.352c0,1.19,0.382,2.16,1.131,2.88c0.735,0.705,1.719,1.058,2.909,1.058h11.006 c1.19,0,2.16-0.353,2.909-1.058c0.749-0.705,1.131-1.675,1.131-2.88C21.669,19.057,21.654,18.601,21.625,18.161z" />
            </g>
        </SvgIcon>
    );
}
