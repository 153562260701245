import Typography from "@material-ui/core/Typography";
import Spacer from "common/components/Spacer";
import Img from "common/components/img/Img";
import { forwardRef, useEffect } from "react";
import { styleGuide } from "theme";
import { SCROLL_OFFSET, SET_SELECTED_MENU_EVENT } from "../hero/HeroDesktop";

interface IProps {
    isMobile: boolean;
    cdnPath: string;
    title: string;
    description: string;
    id: string;
}

const SectionWrcClubs = forwardRef(({ isMobile, cdnPath, title, description, id }: IProps, ref: any) => {
    const image = `${cdnPath}/screens_clubs.png`;

    useEffect(() => {
        const sendSelectedMenu = () => {
            if (
                window.scrollY + SCROLL_OFFSET >= ref.current.offsetTop &&
                window.scrollY + SCROLL_OFFSET < ref.current.offsetTop + ref.current.clientHeight
            ) {
                window.dispatchEvent(new CustomEvent(SET_SELECTED_MENU_EVENT, { detail: id, bubbles: false }));
            }
        };

        window.addEventListener("scroll", sendSelectedMenu);

        return () => window.removeEventListener("scroll", sendSelectedMenu);
    }, [ref]);

    return (
        <div
            ref={ref}
            style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                padding: isMobile ? `25% 0` : `3% 0 2% 0`,
                gap: styleGuide.spacing(isMobile ? 6 : 2),
                justifyContent: "center",
            }}
        >
            <Img
                src={image}
                width={1129}
                height={718}
                style={{ width: isMobile ? "124%" : "60%", marginLeft: isMobile ? "-25%" : "-9%" }}
                disableLazyLoad
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isMobile ? "center" : "start",
                    justifyContent: "center",
                    width: isMobile ? "100%" : "50%",
                    padding: isMobile ? `0 ${styleGuide.spacing(2)}px` : 0,
                }}
            >
                <div style={{ display: "flex", maxWidth: 405, alignSelf: "center", flexDirection: "column" }}>
                    <Typography
                        style={{
                            ...(isMobile ? styleGuide.typography.heading : styleGuide.typography.title),
                            textAlign: isMobile ? "center" : "left",
                        }}
                    >
                        {title}
                    </Typography>
                    <Spacer size={0.5} />
                    <Typography style={{ ...styleGuide.typography.copyText, textAlign: isMobile ? "center" : "left" }}>
                        {description}
                    </Typography>
                </div>
            </div>
        </div>
    );
});

export default SectionWrcClubs;
