import INotification from "common/components/notifications/interfaces/INotification";

export interface INewNotificationStore {
    notifications: INotification[];
    isLastPage: boolean;
    loading: boolean;
    count: number;
    refreshable: boolean;
    nextPageCursor?: string;
}

export interface IOldNotificationStore {
    notifications: INotification[];
    isLastPage: boolean;
    loading: boolean;
    nextPageCursor?: string;
}

export interface INotificationStore {
    actedFriendRequestSsids: string[];
    receivedFriendRequestSsids: string[];
    openTray: boolean;
}

export interface INotificationContextStore {
    newNotificationStore: INewNotificationStore;
    oldNotificationStore: IOldNotificationStore;
    notificationStore: INotificationStore;
}

export const defaultNotificationContext: INotificationContextStore = {
    newNotificationStore: {
        notifications: [],
        isLastPage: true,
        loading: false,
        count: 0,
        refreshable: false,
    },
    oldNotificationStore: {
        notifications: [],
        isLastPage: true,
        loading: false,
    },
    notificationStore: {
        actedFriendRequestSsids: [],
        receivedFriendRequestSsids: [],
        openTray: false,
    },
};
