import useIsMobile from "hooks/useIsMobile";
import PageCircularProgress from "common/components/loaders/PageCircularProgress";
import { Suspense, createRef, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useApplicationContext } from "contexts/application/ApplicationContext";
import { ILandingPageCdnConfig } from "apiServices/cdn/responses/ILandigPageCdnConfigResponse";
import { getLandingCdnConfig } from "apiServices/cdn/CdnApiService";
import { trimEndSlash } from "utils/urlHelper";
import GamesEnum from "common/enums/GamesEnum";
import SectionF1LeagueSimple from "./components/sections/SectionF1LeagueSimple";
import SectionWrcClubs from "./components/sections/SectionWrcClubs";
import SectionTimetrialAnalysis from "./components/sections/SectionTimetrialAnalysis";
import SectionRacenetAppFooter from "./components/sections/SectionRacenetAppFooter";
import Footer from "./components/footer/Footer";
import classes from "./landing.module.scss";
import SectionF12023Leagues from "./components/sections/SectionF12023Leagues";

const HeroDesktop = lazy(() => import("./components/hero/HeroDesktop"));
const HeroMobile = lazy(() => import("./components/hero/HeroMobile"));
const SectionSupportedGamesDesktop = lazy(() => import("./components/sections/SectionSupportedGamesDesktop"));
const SectionSupportedGamesMobile = lazy(() => import("./components/sections/SectionSupportedGamesMobile"));

const landingPagePath = "/Landing_Page";

type LandingType = "f12023" | "wrc";

interface IProps {
    variant?: LandingType;
}

export default function LandingPage({ variant }: IProps) {
    const applicationStore = useApplicationContext().state.applicationStore;
    const cdnBaseUrl = applicationStore.cdnUrl;
    const supportedGames = applicationStore.supportedGames;
    const playStoreUrl = applicationStore.playStoreUrl;
    const iOsUrl = applicationStore.iosUrl;
    const isMobile = useIsMobile();

    const [loader, setLoader] = useState(false);
    const [cdnConfig, setCdnConfig] = useState<ILandingPageCdnConfig>();

    const elementRefs = {
        hero: createRef<HTMLDivElement>(),
        supportedGames: createRef<HTMLDivElement>(),
        leagueRacing: createRef<HTMLDivElement>(),
        F12023LeagueRacing: createRef<HTMLDivElement>(),
        WRCClubs: createRef<HTMLDivElement>(),
        timetrial: createRef<HTMLDivElement>(),
        racenetAppFooter: createRef<HTMLDivElement>(),
    };

    const resolveGameParam = useMemo(() => {
        if (variant === "wrc") return GamesEnum.WRC2023;
        if (variant === "f12023") return GamesEnum.F12023;

        return undefined;
    }, [variant]);

    const [selectedGame] = useState(resolveGameParam);

    useEffect(() => {
        setLoader(true);
        getLandingCdnConfig(cdnBaseUrl)
            .then((r) => {
                setCdnConfig(r);
            })
            .finally(() => {
                setLoader(false);
            });
    }, []);

    const cdnPath = `${trimEndSlash(cdnBaseUrl)}${landingPagePath}`;
    const game = selectedGame ?? GamesEnum.F12024;

    const handleNavTo = (id: string) => {
        if (id === navItems.gettingStarted.id && elementRefs.hero.current !== null) {
            elementRefs.hero.current.scrollIntoView({ behavior: "smooth" });
        } else if (id === navItems.supportedGames.id && elementRefs.supportedGames.current !== null) {
            elementRefs.supportedGames.current.scrollIntoView({ behavior: "smooth" });
        } else if (id === navItems.leagueRacing.id && elementRefs.leagueRacing.current !== null) {
            elementRefs.leagueRacing.current.scrollIntoView({ behavior: "smooth" });
        } else if (id === navItems.f12023LeagueRacing.id && elementRefs.F12023LeagueRacing.current !== null) {
            elementRefs.F12023LeagueRacing.current.scrollIntoView({ behavior: "smooth" });
        } else if (id === navItems.wrcClubs.id && elementRefs.WRCClubs.current !== null) {
            elementRefs.WRCClubs.current.scrollIntoView({ behavior: "smooth" });
        } else if (id === navItems.timetrialAnalysis.id && elementRefs.timetrial.current !== null) {
            elementRefs.timetrial.current.scrollIntoView({ behavior: "smooth" });
        } else if (id === navItems.racenetAppFooter.id && elementRefs.racenetAppFooter.current !== null) {
            elementRefs.racenetAppFooter.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const navItems = {
        gettingStarted: { id: "started", label: cdnConfig?.hero?.menu ?? "", action: handleNavTo },
        supportedGames: { id: "supported", label: cdnConfig?.supportedGameSection?.menu ?? "", action: handleNavTo },
        leagueRacing: { id: "league", label: cdnConfig?.leagueRacingSection?.menu ?? "", action: handleNavTo },
        f12023LeagueRacing: {
            id: "f12023-league-racing",
            label: cdnConfig?.f12023LeagueRacingSection?.menu ?? "",
            action: handleNavTo,
        },
        wrcClubs: { id: "wrc-clubs", label: cdnConfig?.wrc2023ClubSection?.menu ?? "", action: handleNavTo },
        timetrialAnalysis: {
            id: "timetrial-analysis",
            label: cdnConfig?.timetrialSection?.menu ?? "",
            action: handleNavTo,
        },
        racenetAppFooter: {
            id: "racenet-app",
            label: cdnConfig?.mobileAppFooterSection?.menu ?? "",
            action: handleNavTo,
        },
    };

    const nav = useCallback(() => {
        if (selectedGame === GamesEnum.WRC2023)
            return [
                navItems.gettingStarted,
                navItems.supportedGames,
                navItems.timetrialAnalysis,
                navItems.wrcClubs,
                navItems.leagueRacing,
                navItems.racenetAppFooter,
            ];

        if (selectedGame === GamesEnum.F12023)
            return [
                navItems.gettingStarted,
                navItems.supportedGames,
                navItems.f12023LeagueRacing,
                navItems.timetrialAnalysis,
                navItems.wrcClubs,
                navItems.racenetAppFooter,
            ];

        return [
            navItems.gettingStarted,
            navItems.supportedGames,
            navItems.leagueRacing,
            navItems.wrcClubs,
            navItems.timetrialAnalysis,
            navItems.racenetAppFooter,
        ];
    }, [selectedGame, loader]);

    const heroSection = isMobile ? (
        <HeroMobile
            id={navItems.gettingStarted.id}
            ref={elementRefs.hero}
            cdnPath={cdnPath}
            title={cdnConfig?.hero.title ?? ""}
            description={cdnConfig?.hero.description ?? ""}
            game={game}
            nav={nav()}
            iOsUrl={iOsUrl}
            playStoreUrl={playStoreUrl}
            appDescription={cdnConfig?.mobileAppSection.descriptionMobile ?? ""}
        />
    ) : (
        <HeroDesktop
            id={navItems.gettingStarted.id}
            ref={elementRefs.hero}
            cdnPath={cdnPath}
            title={cdnConfig?.hero?.title ?? ""}
            description={cdnConfig?.hero?.description ?? ""}
            game={game}
            nav={nav()}
            iOsUrl={iOsUrl}
            playStoreUrl={playStoreUrl}
            appDescription={cdnConfig?.mobileAppSection?.description ?? ""}
        />
    );

    const supportedGamesSection = isMobile ? (
        <SectionSupportedGamesMobile
            id={navItems.supportedGames.id}
            ref={elementRefs.supportedGames}
            game={game}
            supportedGames={cdnConfig?.supportedGameSection.supportedGamesMobile ?? { row1: [], row2: [] }}
            gamesInfo={supportedGames}
            title={cdnConfig?.supportedGameSection.title ?? ""}
            description={cdnConfig?.supportedGameSection.description ?? ""}
            cdnPath={cdnPath}
        />
    ) : (
        <SectionSupportedGamesDesktop
            id={navItems.supportedGames.id}
            ref={elementRefs.supportedGames}
            game={game}
            supportedGames={cdnConfig?.supportedGameSection?.supportedGamesDesktop ?? []}
            gamesInfo={supportedGames}
            cdnPath={cdnPath}
            title={cdnConfig?.supportedGameSection?.title ?? ""}
            description={cdnConfig?.supportedGameSection?.description ?? ""}
        />
    );

    const leagueRacingSection = (
        <SectionF1LeagueSimple
            id={navItems.leagueRacing.id}
            ref={elementRefs.leagueRacing}
            isMobile={isMobile}
            cdnPath={cdnPath}
            title={cdnConfig?.leagueRacingSection?.title ?? ""}
            description={cdnConfig?.leagueRacingSection?.description ?? ""}
        />
    );

    const wrcClubsSection = (
        <SectionWrcClubs
            id={navItems.wrcClubs.id}
            ref={elementRefs.WRCClubs}
            isMobile={isMobile}
            cdnPath={cdnPath}
            title={cdnConfig?.wrc2023ClubSection?.title ?? ""}
            description={cdnConfig?.wrc2023ClubSection?.description ?? ""}
        />
    );

    const f12023LeagueSection = (
        <SectionF12023Leagues
            id={navItems.f12023LeagueRacing.id}
            ref={elementRefs.F12023LeagueRacing}
            isMobile={isMobile}
            cdnPath={cdnPath}
            title={cdnConfig?.f12023LeagueRacingSection?.title ?? ""}
            description={cdnConfig?.f12023LeagueRacingSection?.description ?? ""}
        />
    );

    const timetrialAnalysisSection = (
        <SectionTimetrialAnalysis
            id={navItems.timetrialAnalysis.id}
            ref={elementRefs.timetrial}
            isMobile={isMobile}
            cdnPath={cdnPath}
            title={cdnConfig?.timetrialSection?.title ?? ""}
            description={cdnConfig?.timetrialSection?.description ?? ""}
            game={game}
        />
    );

    const racenetAppFooterSection = (
        <SectionRacenetAppFooter
            id={navItems.racenetAppFooter.id}
            ref={elementRefs.racenetAppFooter}
            isMobile={isMobile}
            cdnPath={cdnPath}
            game={game}
            playStore={playStoreUrl}
            iOsUrl={iOsUrl}
            title={cdnConfig?.mobileAppFooterSection?.title ?? ""}
            description={cdnConfig?.mobileAppFooterSection?.description ?? ""}
        />
    );

    const sections = useCallback(() => {
        if (selectedGame === GamesEnum.WRC2023)
            return [
                heroSection,
                supportedGamesSection,
                timetrialAnalysisSection,
                wrcClubsSection,
                leagueRacingSection,
                racenetAppFooterSection,
            ];

        if (selectedGame === GamesEnum.F12023)
            return [
                heroSection,
                supportedGamesSection,
                f12023LeagueSection,
                timetrialAnalysisSection,
                wrcClubsSection,
                racenetAppFooterSection,
            ];

        return [
            heroSection,
            supportedGamesSection,
            leagueRacingSection,
            wrcClubsSection,
            timetrialAnalysisSection,
            racenetAppFooterSection,
        ];
    }, [selectedGame, loader]);

    if (loader) return <PageCircularProgress />;

    return (
        <div className={classes.scroll} style={{ display: "flex", flexDirection: "column", position: "relative" }}>
            <Suspense fallback={<PageCircularProgress />}>
                {sections().map((s) => s)}
                <Footer
                    isMobile={isMobile}
                    termsUrl={cdnConfig?.termsAndConditionsUrl ?? ""}
                    policyUrl={cdnConfig?.privacyPolicyUrl ?? ""}
                />
                {/* <ScrollButton isMobile={isMobile} scrollElRef={elementRefs.hero} /> */}
            </Suspense>
        </div>
    );
}
