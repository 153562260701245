import { useNotificationContext } from "contexts/notification/NotificationContext";
import RefreshButton from "common/components/RefreshButton";
import MoreActionsMenu from "../MoreActionsMenu";
import {
    dispatchClearAllNotifications,
    dispatchFetchAllNotifications,
} from "contexts/notification/actions/NotificationActions";

export default function NotificationsActions() {
    const notificationContext = useNotificationContext();

    const refreshing =
        notificationContext.state.newNotificationStore.loading ||
        notificationContext.state.oldNotificationStore.loading;

    const clearNotifications = () => {
        dispatchClearAllNotifications(
            notificationContext.dispatch,
            notificationContext.state.newNotificationStore.notifications,
            notificationContext.state.oldNotificationStore.notifications
        );
    };

    return (
        <div>
            <MoreActionsMenu
                actions={[
                    {
                        label: "Clear notifications",
                        onClick: clearNotifications,
                    },
                ]}
                iconButtonProps={{ size: "small" }}
            />
            <RefreshButton
                loading={refreshing}
                onClick={() =>
                    dispatchFetchAllNotifications(
                        notificationContext.dispatch,
                        notificationContext.state.oldNotificationStore.notifications,
                        notificationContext.state.newNotificationStore.notifications
                    )
                }
                disabled={!notificationContext.state.newNotificationStore.refreshable}
                size="small"
            />
        </div>
    );
}
