import { ReactNode, useEffect, useRef, useState } from "react";
import { IStyles, styleGuide } from "theme";
import { ITheme, useTheme } from "@material-ui/core";
import useIsMobile from "hooks/useIsMobile";
import { isTablet } from "react-device-detect";

export const scrollBarWidth = 10;

interface IProps {
    children: ReactNode;
    scrollConfig?: {
        disableButtons?: boolean;
        currentStep?: number;
        disableShadow?: boolean;
        customHeight?: string;
        mobileShadowHeight?: string;
        shadowHeight?: string;
        color?: string;
        gradientHeight?: string;
    };
    mobileShadowHeight?: string;
    shadowHeight?: string;
}

const CustomScroll = ({ children, scrollConfig, shadowHeight = "40%", mobileShadowHeight = "50%" }: IProps) => {
    const theme: ITheme = useTheme();
    const [onScrollHover, setScrollHover] = useState<boolean>(true);
    const [bottomReached, setIsBottomReached] = useState<boolean>(false);
    const [listScrollHeight, setListScrollHeight] = useState<number>(0);
    const [scrollHeight, setScrollHeight] = useState<number>(0);
    const listRef = useRef<HTMLDivElement>(null!);
    const ref = useRef<HTMLDivElement>(null!);
    const isMobile = useIsMobile();

    const disableButtons = scrollConfig?.disableButtons ? scrollConfig?.disableButtons : false;
    const currentStep = scrollConfig?.currentStep ? scrollConfig.currentStep : 0;
    const disableShadow = scrollConfig?.disableShadow ? scrollConfig.disableShadow : false;
    const customHeight = scrollConfig?.customHeight ? scrollConfig.customHeight : "100%";
    const color = scrollConfig?.color ? scrollConfig.color : styleGuide.color.grey;
    const gradientHeight = scrollConfig?.gradientHeight ? scrollConfig.gradientHeight : "10%";

    const styles: IStyles = {
        children: {
            transition: isMobile ? "none" : theme.styles.hoverEaseInOut,
            height: customHeight,
            overflow: disableButtons ? "hidden" : isMobile || isTablet || onScrollHover ? "auto" : "hidden",
            scrollbarGutter: "stable",
            overflowX: "hidden",
            paddingRight: isMobile ? scrollBarWidth : scrollBarWidth,
        },
        blur: {
            transition: theme.styles.hoverEaseInOut,
            position: "absolute",
            width: "100%",
            zIndex: 2,
            bottom: 0,
            right: 0,
            height: isMobile ? 300 : 500,
            pointerEvents: "none",
            background: isMobile
                ? `linear-gradient(0deg, ${color} ${
                      scrollConfig?.mobileShadowHeight ? scrollConfig.mobileShadowHeight : mobileShadowHeight
                  }, rgba(0, 0, 0, 0) 90%)`
                : `linear-gradient(0deg, ${color} ${gradientHeight}, rgba(0, 0, 0, 0) ${
                      scrollConfig?.shadowHeight ? scrollConfig.shadowHeight : shadowHeight
                  })`,
        },
        listItem: {
            overflow: "auto",
            height: "100%",
            marginRight: isMobile ? -scrollBarWidth : -scrollBarWidth * 1.5,
        },
    };

    useEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver(() => {
            setScrollHeight(Number(ref?.current?.scrollHeight));
        });

        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }, [currentStep]);

    useEffect(() => {
        if (!listRef.current) return;

        const listResizeObserver = new ResizeObserver(() => {
            setListScrollHeight(Number(listRef?.current?.scrollHeight));
        });

        listResizeObserver.observe(listRef.current);
        return () => listResizeObserver.disconnect();
    }, [currentStep]);

    useEffect(() => {
        if (listRef) {
            const divElement = listRef?.current;

            if (divElement) {
                const handleInitialHover = () => {
                    setScrollHover(true);
                };

                divElement.addEventListener("mouseover", handleInitialHover);

                return () => {
                    divElement.removeEventListener("mouseover", handleInitialHover);
                };
            }
        }
    }, [listRef?.current]);

    const isScroll = !disableShadow && scrollHeight > listScrollHeight;

    const handleReachBottom = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const bottomOffset = 12;

        const bottom =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= e.currentTarget.clientHeight + bottomOffset;

        if (bottom) {
            setIsBottomReached(true);
        } else setIsBottomReached(false);
    };

    return (
        <div
            ref={listRef}
            style={styles.listItem}
            onMouseEnter={() => setScrollHover(true)}
            onMouseLeave={() => setScrollHover(false)}
            onTouchStart={(e) => e.preventDefault()}
        >
            <div ref={ref} style={styles.children} onScroll={handleReachBottom}>
                {children}
                {isScroll && !bottomReached && !disableShadow && <div style={styles.blur} />}
            </div>
        </div>
    );
};

export default CustomScroll;
