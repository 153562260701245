import Badge, { BadgeProps } from "@material-ui/core/Badge";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import clsx from "clsx";
import { ITheme } from "@material-ui/core";
import { styleGuide } from "theme";

const useStyles = makeStyles((theme: ITheme) => {
    return createStyles({
        badge: {},
        primary: {
            backgroundColor: theme.palette.primary.main,
            color: styleGuide.color.white,
            border: `1px solid ${styleGuide.color.white}`,
        },
        secondary: {
            backgroundColor: theme.palette.primary.dark,
            color: styleGuide.color.white,
            border: `1px solid ${styleGuide.color.white}`,
        },
        white: {
            backgroundColor: theme.variants.lightWhite,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    });
});

type Variant = "primary" | "secondary" | "white";

interface IProps extends Omit<BadgeProps, "variant"> {
    variant?: Variant;
}

export default function NotificationsBadge({ variant = "primary", classes: overrideClasses, ...rest }: IProps) {
    const classes = useStyles();

    const notificationContext = useNotificationContext();

    return (
        <Badge
            // style={{ width: "100%" }}
            badgeContent={notificationContext.state.newNotificationStore.count}
            max={9}
            classes={{ ...overrideClasses, badge: clsx(overrideClasses?.badge, classes[variant]) }}
            {...rest}
        />
    );
}
