export const trimEndSlash = (url: string) => {
    const isSlash = url !== null && url.lastIndexOf("/") === url.length - 1;
    if (isSlash) {
        return url.substring(0, url.length - 1);
    }
    return url;
};

export const getQueryParams = (search: string) => {
    search = search.replace("?", "");

    let parsedObject = JSON.parse(
        '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    );

    return parsedObject;
};

export const escapeUrl = (url: string) => url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
