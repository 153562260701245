export default interface INotificationRequests {
    cursor?: string;
    take: number | undefined;
    filter: NotificationFilter;
}

export enum NotificationFilter {
    UnRead,
    Read,
    All,
}
