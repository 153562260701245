import { fetchWebAcceptanceText, postWebAcceptance } from "apiServices/identity/IdentityApiService";
import { ActionMap } from "common/types/ActionMapType";
import { IWebAcceptanceStore, IWebAcceptanceText } from "./WebAcceptanceStore";

enum WebAcceptanceType {
    FetchAcceptanceText = "FETCH_WEB_ACCEPTANCE_TEXT",
    SetWebAcceptance = "SET_WEB_ACCEPTANCE",
    SubmitWebAcceptance = "SUBMIT_WEB_ACCEPTANCE",
}

type WebAcceptanceTypePayload = {
    [WebAcceptanceType.FetchAcceptanceText]: IWebAcceptanceText | undefined;
    [WebAcceptanceType.SetWebAcceptance]: boolean;
    [WebAcceptanceType.SubmitWebAcceptance]: boolean;
};

type WebAcceptanceActions = ActionMap<WebAcceptanceTypePayload>[keyof ActionMap<WebAcceptanceTypePayload>];

export const dispatchFetchWebAcceptanceText = async (
    dispatch: React.Dispatch<any>
): Promise<IWebAcceptanceText | undefined> => {
    const response = await fetchWebAcceptanceText();

    dispatch({
        type: WebAcceptanceType.FetchAcceptanceText,
        payload: response,
    });

    return response;
};

export const dispatchSetWebAcceptance = (dispatch: React.Dispatch<any>, hasAccepted: boolean): boolean => {
    dispatch({
        type: WebAcceptanceType.SetWebAcceptance,
        payload: hasAccepted,
    });

    return hasAccepted;
};

export const dipatchPostWebAcceptance = async (
    dispatch: React.Dispatch<any>,
    acceptanceId: string
): Promise<boolean> => {
    const response = await postWebAcceptance(acceptanceId);

    dispatch({
        type: WebAcceptanceType.SubmitWebAcceptance,
        payload: response,
    });

    return response;
};

export const webAcceptanceReducer = (state: IWebAcceptanceStore, action: WebAcceptanceActions): IWebAcceptanceStore => {
    switch (action.type) {
        case WebAcceptanceType.FetchAcceptanceText:
            return {
                ...state,
                acceptanceText: action.payload,
            };
        case WebAcceptanceType.SetWebAcceptance:
        case WebAcceptanceType.SubmitWebAcceptance:
            return {
                ...state,
                hasAcceptedTerms: action.payload,
            };
        default:
            return state;
    }
};
