import { createContext } from "react";
import { TrainTrackLayer } from "./TrainTrackLayer";

export interface ITrainTrack {
    borderRadius: number;
    padding: number;
}

export type ITrainTracks = {
    [key in TrainTrackLayer]: ITrainTrack;
};

const TrainTrackContext = createContext<ITrainTracks>({
    "0": {
        borderRadius: 0,
        padding: 0,
    },
    "1": {
        borderRadius: 0,
        padding: 0,
    },
    "2": {
        borderRadius: 0,
        padding: 0,
    },
});

export default TrainTrackContext;
