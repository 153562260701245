import { HTMLAttributes } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { routingUrls, settings } from "settings";
import RouterLinkButton from "common/components/buttons/RouterLinkButton";
import StyledButton from "common/components/buttons/StyledButton";
import NotificationsBadge from "common/components/notifications/NotificationsBadge";
import useIsMobile from "hooks/useIsMobile";
import { useConfig } from "contexts/configuration/ConfigurationContext";
import { logoutUser } from "../../../../apiServices/identity/IdentityApiService";
import { trimEndSlash } from "utils/urlHelper";
import { Typography } from "@material-ui/core";
import { useApplicationContext } from "contexts/application/ApplicationContext";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                marginBottom: theme.spacing(2),
            },
            "& > *:last-child": {
                marginBottom: 0,
            },
        },
        buttonItem: { width: "100%" },
        badge: {
            right: 5,
            top: 5,
        },
    });
});

interface IProps extends HTMLAttributes<HTMLDivElement> {
    setClose?: () => void;
}

export default function SettingsDropdownContents(props: IProps) {
    const { className, children, setClose, ...rest } = props;
    const isMobile = useIsMobile();
    const classes = useStyles();
    const config = useConfig();
    const { state } = useApplicationContext();

    const handleLogout = () => {
        logoutUser().then((res) => {
            //window.location.href = `${routingUrls.loggedOut}`;
            window.location.href = `${config.authUrls.logout}${trimEndSlash(settings.basePath)}${
                routingUrls.loggedOut
            }`;
        });
    };

    const closeDropdown = () => typeof setClose !== "undefined" && setClose();

    const buttonVariant = "transparentLight";

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            {isMobile && (
                <NotificationsBadge classes={{ badge: classes.badge }} style={{ width: "100%" }}>
                    <RouterLinkButton
                        style={{ width: "100%" }}
                        variant={buttonVariant}
                        onClick={closeDropdown}
                        to={routingUrls.notificationCenter}
                    >
                        Notifications
                    </RouterLinkButton>
                </NotificationsBadge>
            )}
            <RouterLinkButton
                className={classes.buttonItem}
                variant={buttonVariant}
                onClick={closeDropdown}
                to={routingUrls.profile}
            >
                Profile
            </RouterLinkButton>

            <RouterLinkButton
                className={classes.buttonItem}
                variant={buttonVariant}
                onClick={closeDropdown}
                to={routingUrls.preferences}
            >
                Settings
            </RouterLinkButton>
            <RouterLinkButton
                className={classes.buttonItem}
                variant={buttonVariant}
                onClick={closeDropdown}
                to={routingUrls.releaseNotes}
            >
                Release notes
            </RouterLinkButton>
            <RouterLinkButton
                className={classes.buttonItem}
                variant={buttonVariant}
                onClick={closeDropdown}
                to={routingUrls.faq}
            >
                FAQ
            </RouterLinkButton>
            <StyledButton className={classes.buttonItem} variant={buttonVariant} onClick={handleLogout}>
                Log out
            </StyledButton>

            <Typography variant="body2">{`v${state.applicationStore.version.version}`}</Typography>
        </div>
    );
}
