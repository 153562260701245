import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path d="M8.64,23.47v-7.69h-3.19c-.29,0-.53,.24-.53,.53v7.17c0,.29,.24,.53,.53,.53h3.28c-.05-.17-.09-.34-.09-.53Z" />
            <path d="M23.06,6.05L18.03,.18c-.2-.23-.56-.23-.77,0l-5.03,5.87c-.28,.33-.05,.83,.38,.83h2.15V23.47c0,.29,.24,.53,.53,.53h4.71c.29,0,.53-.24,.53-.53V6.88h2.15c.43,0,.66-.51,.38-.83Z" />
            <path d="M3.71,23.47v-4.45H.53c-.29,0-.53,.24-.53,.53v3.92c0,.29,.24,.53,.53,.53H3.81c-.05-.17-.09-.34-.09-.53Z" />
            <path d="M13.56,23.47V10.21h-3.19c-.29,0-.53,.24-.53,.53v12.74c0,.29,.24,.53,.53,.53h3.28c-.05-.17-.09-.34-.09-.53Z" />
        </SvgIcon>
    );
};
