import Platforms from "common/enums/PlatformsEnum";
import GamesEnum from "common/enums/GamesEnum";
import { trimEndSlash } from "utils/urlHelper";

export const mobileAppUserAgent = "RaceNetMobileApp";
export const tabletAppUserAgent = "RaceNetTabletApp";

const getBasePath = () => {
    try {
        return document.getElementsByTagName("base")[0].getAttribute("href") ?? "/";
    } catch (e) {
        return "/";
    }
};

export const MAX_SEARCH_FRIENDS_CHARACTERS = 15;
export const DEBOUNCE_SEARCH_MS = 900;

const origin = document.location.origin;

export const settings = {
    basePath: getBasePath(),
    appUrl: origin,
    apiUrl: "/",
    cachingDuration: 30,
};

export const gameHubUrls = {
    dirtrally: "/dirtRally",
    dirtrally2: "/dirtRally2",
    dirt5: "/dirt5",
    f1_2021: "/f1_2021",
    f1_2022: "/f1_22",
    f1_2023: "/f1_23",
    f1_2024: "/f1_24",
    gridLegends: "/gridLegends",
    wrc2023: "/ea_sports_wrc",
};

export const dirtRallyLeaguesUrl = (websiteUrl: string) => `${trimEndSlash(websiteUrl)}/us/leagues`;
export const dirtRally2ClubsUrl = (websiteUrl: string) => `${trimEndSlash(websiteUrl)}/clubs`;

export const routingUrls = {
    landing: "/",
    landingWRC: "/wrc",
    landingF12023: "/f12023",
    appStoreRedirect: "/app_store_redirect",
    notificationCenter: "/notifications",
    home: "/home",
    profile: "/profile",
    activityFeed: "/activityFeed",
    friends: "/friends",
    gamesCoach: "/gamesCoach",
    preferences: "/settings",
    newsFeed: "/newsFeed",
    loggedOut: "",
    oauthCallback: "/oauthCallback",
    games: "/gamesLibrary",
    releaseNotes: "/releaseNotes",
    faq: "/faq",
    dirtRally: {
        root: gameHubUrls.dirtrally,
        community: `${gameHubUrls.dirtrally}/community`,
        events: `${gameHubUrls.dirtrally}/events`,
        telemetry: `${gameHubUrls.dirtrally}/telemetry`,
        friends: `${gameHubUrls.dirtrally}/friends`,
        stats: `${gameHubUrls.dirtrally}/stats`,
        gameInfo: `${gameHubUrls.dirtrally}/gameInfo`,
    },
    dirtRally2: {
        root: gameHubUrls.dirtrally2,
        community: `${gameHubUrls.dirtrally2}/community`,
        stats: `${gameHubUrls.dirtrally2}/stats`,
        friends: `${gameHubUrls.dirtrally2}/friends`,
        performanceAnalysis: `${gameHubUrls.dirtrally2}/performanceanalysis`,
        events: `${gameHubUrls.dirtrally2}/events`,
        gameInfo: `${gameHubUrls.dirtrally2}/gameInfo`,
    },
    dirt5: {
        root: gameHubUrls.dirt5,
        community: `${gameHubUrls.dirt5}/community`,
        friends: `${gameHubUrls.dirt5}/friends`,
        stats: `${gameHubUrls.dirt5}/stats`,
        gameInfo: `${gameHubUrls.dirt5}/info`,
        playgrounds: `${gameHubUrls.dirt5}/playgrounds`,
    },
    f1_2021: {
        root: gameHubUrls.f1_2021,
        stats: `${gameHubUrls.f1_2021}/stats`,
        news: `${gameHubUrls.f1_2021}/news`,
        photos: `${gameHubUrls.f1_2021}/photos`,
        previousEvents: `${gameHubUrls.f1_2021}/previousEvents`,
        currentEvent: `${gameHubUrls.f1_2021}/currentEvents`,
        friends: `${gameHubUrls.f1_2021}/friends`,
        leaderboards: `${gameHubUrls.f1_2021}/leaderboards`,
        performanceAnalysis: `${gameHubUrls.f1_2021}/performanceAnalysis`,
        gameInfo: `${gameHubUrls.f1_2021}/gameInfo`,
        leagues: `${gameHubUrls.f1_2021}/leagues`,
        leaguesSearch: `${gameHubUrls.f1_2021}/leagues/search`,
        league: `${gameHubUrls.f1_2021}/league`,
        leaguesEvents: `${gameHubUrls.f1_2021}/leagues/your_events`,
        yourLeagues: `${gameHubUrls.f1_2021}/leagues/your_leagues`,
        leaguesDiscover: `${gameHubUrls.f1_2021}/leagues/discover`,
    },
    f1_2022: {
        root: gameHubUrls.f1_2022,
        stats: `${gameHubUrls.f1_2022}/stats`,
        news: `${gameHubUrls.f1_2022}/news`,
        photos: `${gameHubUrls.f1_2022}/photos`,
        previousEvents: `${gameHubUrls.f1_2022}/previousEvents`,
        currentEvent: `${gameHubUrls.f1_2022}/currentEvents`,
        friends: `${gameHubUrls.f1_2022}/friends`,
        leaderboards: `${gameHubUrls.f1_2022}/leaderboards`,
        performanceAnalysis: `${gameHubUrls.f1_2022}/performanceAnalysis`,
        gameInfo: `${gameHubUrls.f1_2022}/gameInfo`,
        leagues: `${gameHubUrls.f1_2022}/leagues`,
        leaguesSearch: `${gameHubUrls.f1_2022}/leagues/search`,
        league: `${gameHubUrls.f1_2022}/league`,
        leaguesEvents: `${gameHubUrls.f1_2022}/leagues/your_events`,
        yourLeagues: `${gameHubUrls.f1_2022}/leagues/your_leagues`,
        leaguesDiscover: `${gameHubUrls.f1_2022}/leagues/discover`,
    },
    f1_2023: {
        root: gameHubUrls.f1_2023,
        friends: `${gameHubUrls.f1_2023}/friends`,
        stats: `${gameHubUrls.f1_2023}/stats`,
        performanceAnalysis: `${gameHubUrls.f1_2023}/performanceAnalysis`,
        leaderboards: `${gameHubUrls.f1_2023}/leaderboards`,
        news: `${gameHubUrls.f1_2023}/news`,
        leagues: `${gameHubUrls.f1_2023}/leagues`,
        league: `${gameHubUrls.f1_2023}/leagues/league`,
        leaguesDiscover: `${gameHubUrls.f1_2023}/leagues/discover`,
        leaguesPreferences: `${gameHubUrls.f1_2023}/leagues/preferences`,
        leaguesAdmin: `${gameHubUrls.f1_2023}/leagues/league`,
        leagueHelp: `${gameHubUrls.f1_2023}/leagues/help`,
        redbull: `${gameHubUrls.f1_2023}/red_bull`,
    },
    f1_2024: {
        root: gameHubUrls.f1_2024,
        fanzone: `${gameHubUrls.f1_2024}/fanzone`,
        fanzonesVote: `${gameHubUrls.f1_2024}/fanzone_vote`,
        friends: `${gameHubUrls.f1_2024}/friends`,
        news: `${gameHubUrls.f1_2024}/news`,
        leagues: `${gameHubUrls.f1_2024}/leagues`,
        league: `${gameHubUrls.f1_2024}/leagues/league`,
        leaguesDiscover: `${gameHubUrls.f1_2024}/leagues/discover`,
        leaguesPreferences: `${gameHubUrls.f1_2024}/leagues/preferences`,
        leaguesAdmin: `${gameHubUrls.f1_2024}/leagues/league`,
        leagueHelp: `${gameHubUrls.f1_2024}/leagues/help`,
        redbull: `${gameHubUrls.f1_2024}/red_bull`,
        timeTrial: `${gameHubUrls.f1_2024}/time_trial`,
    },
    grid21: {
        root: gameHubUrls.gridLegends,
        stats: `${gameHubUrls.gridLegends}/stats`,
        friends: `${gameHubUrls.gridLegends}/friends`,
        leaderboards: `${gameHubUrls.gridLegends}/leaderboards`,
        news: `${gameHubUrls.gridLegends}/news`,
        leagues: `${gameHubUrls.gridLegends}/leagues`,
        gameInfo: `${gameHubUrls.gridLegends}/gameInfo`,
        events: `${gameHubUrls.gridLegends}/events`,
    },
    wrc2023: {
        root: gameHubUrls.wrc2023,
        clubs: `${gameHubUrls.wrc2023}/clubs`,
        club: `${gameHubUrls.wrc2023}/clubs`,
        clubMembership: `${gameHubUrls.wrc2023}/clubs/membership`,
        friendsClubs: `${gameHubUrls.wrc2023}/clubs/friends`,
        leaderboards: `${gameHubUrls.wrc2023}/leaderboards`,
        friends: `${gameHubUrls.wrc2023}/friends`,
        performanceAnalysis: `${gameHubUrls.wrc2023}/performanceAnalysis`,
        playerStats: `${gameHubUrls.wrc2023}/statistics`,
        playerStatsCompare: `${gameHubUrls.wrc2023}/statistics/compare`,
        playerStatsCareer: `${gameHubUrls.wrc2023}/statistics/career`,
        playerStatsSolo: `${gameHubUrls.wrc2023}/statistics/solo`,
        playerStatsMultiplayer: `${gameHubUrls.wrc2023}/statistics/multiplayer`,
        playerStatsChampionship: `${gameHubUrls.wrc2023}/statistics/championship`,
        playerStatsTimetrial: `${gameHubUrls.wrc2023}/statistics/timetrial`,
        playerStatsMoments: `${gameHubUrls.wrc2023}/statistics/moments`,
        news: `${gameHubUrls.wrc2023}/news`,
        performanceAnalysisClubsMobile: `${gameHubUrls.wrc2023}/performanceAnalysisClubs`,
    },
    errorPages: {
        "404": "/404",
    },
};

export const apiUrls = {
    getApplication: `${settings.apiUrl}api/application`,
    getAntiforgery: `${settings.apiUrl}api/application/antiforgery`,
    getIdentity: `${settings.apiUrl}api/identity`,
    getMobileIdentity: `${settings.apiUrl}api/identity/secured`,
    getGames: `${settings.apiUrl}api/profile/library`,
    gameLibraryAnnouncement: "/announcements",
    getStats: `${settings.apiUrl}api/profile/recentlyPlayedGames`,
    postPublicInfo: `${settings.apiUrl}api/preferences/publicInfo`,
    getProfile: `${settings.apiUrl}api/profile`,
    getSocialFeed: `${settings.apiUrl}api/feed/social`,
    getNewsFeed: `${settings.apiUrl}api/feed/news`,
    getReleaseNotes: `${settings.apiUrl}api/common/releaseNotes`,
    getFaq: `${settings.apiUrl}api/common/faq`,
    friends: {
        getFriends: `${settings.apiUrl}api/Friend`,
        getProfileFriends: `${settings.apiUrl}api/Friend/profile`,
        getFriendSearch: `${settings.apiUrl}api/Friend/search`,
        postFriendRequest: (ssid: string) => `${settings.apiUrl}api/Friend/request?friendSSID=${ssid}`,
        putBlockFriend: (friendSsid: string) => `${settings.apiUrl}api/Friend/block?friendSSID=${friendSsid}`,
        deleteCancelInviteFriend: (friendSsid: string) =>
            `${settings.apiUrl}api/Friend/cancelinvite?friendSSID=${friendSsid}`,
        putUnblockFriend: (friendSsid: string) => `${settings.apiUrl}api/Friend/unblock?friendSSID=${friendSsid}`,
        putAcceptFriend: (friendSsid: string) => `${settings.apiUrl}api/Friend/acceptinvite?friendSSID=${friendSsid}`,
        putDeclineFriend: (friendSsid: string) => `${settings.apiUrl}api/Friend/declineinvite?friendSSID=${friendSsid}`,
        deleteRemoveFriend: (friendSsid: string) => `${settings.apiUrl}api/Friend/remove?friendSSID=${friendSsid}`,
        deleteFriend: (friendSsid: string) => `${settings.apiUrl}api/Friend/remove?friendSSID=${friendSsid}`,
        bulkRequest: `${settings.apiUrl}api/Friend/request/bulk`,
        friendsRecommendations: `${settings.apiUrl}api/Friend/recommendations`,
        friendsByPlatform: `${settings.apiUrl}api/Friend/consolidated`,
        checkFriends: `${settings.apiUrl}api/Friend/checkFriends`,
    },
    identity: {
        getSecuredIdentity: `${settings.apiUrl}api/identity/secured`,
        getAccessToken: `${settings.apiUrl}api/identity/auth`,
        refreshTokens: `${settings.apiUrl}api/identity/refresh-auth`,
        logout: `${settings.apiUrl}api/identity/logout`,
        getWebAcceptanceText: `${settings.apiUrl}api/identity/acceptanceText`,
        postWebAcceptance: (acceptanceId: string) => `${settings.apiUrl}api/identity/acceptance/${acceptanceId}/submit`,
        postOnboarding: `${settings.apiUrl}api/identity/onboarding/submit`,
        postNotificationPreferences: `${settings.apiUrl}api/Identity/pushNotificationPreferences/submit`,
        getLmitedToken: `${settings.apiUrl}api/Identity/limitedToken`,
    },
    preferences: {
        getPreferences: `${settings.apiUrl}api/Preferences`,
        getProfilePictures: `${settings.apiUrl}api/common/profilePitures`,
        getCoverPhotosPictures: `${settings.apiUrl}api/common/coverPhotos`,
        getPlatforms: `${settings.apiUrl}api/common/platforms`,
        postProfilePicture: `${settings.apiUrl}api/preferences/profile-picture`,
        getProfilePicture: `${settings.apiUrl}api/preferences/profile-picture`,
        postCoverPhoto: `${settings.apiUrl}api/preferences/cover-photo`,
    },
    reporting: {
        reportLeagueName: `${settings.apiUrl}api/Reporting/proleagueName`,
        reportWrc2023ClubName: `${settings.apiUrl}api/Reporting/wrc2023ClubName`,
        reportLeagueDescription: `${settings.apiUrl}api/Reporting/proleagueDescription`,
        reportWrc2023ClubDescription: `${settings.apiUrl}api/Reporting/wrc2023ClubDescription`,
        reportPlayerName: `${settings.apiUrl}api/Reporting/playerName`,
    },
    dirtRally: {
        getPlayerStats: `${settings.apiUrl}api/statistics/rp11/player/stats`,
        getPlayerTrackResults: `${settings.apiUrl}api/statistics/rp11/player/trackResults`,
        getPlayerActivity: `${settings.apiUrl}api/statistics/rp11/player/recentActivity`,
        getPlayer: `${settings.apiUrl}api/statistics/rp11/player`,
        getPlayerRacedTrackLocationsWithDisciplines: `${settings.apiUrl}api/statistics/rp11/player/racedTracks`,
        getVehicleClasses: `${settings.apiUrl}api/statistics/rp11/vehicleClasses`, // *** TO DO ***
        getFriendsWithPlatforms: `${settings.apiUrl}api/statistics/rp11/platformFriends`,
        getFriends: `${settings.apiUrl}api/statistics/rp11/friends`,
        getCommunityStats: `${settings.apiUrl}api/statistics/rp11/community`,
        getfriendLeaderboard: `${settings.apiUrl}api/statistics/rp11/friendsLeaderboard`,
        getEvents: `${settings.apiUrl}api/statistics/rp11/events`,
        getEventLeaderboard: `${settings.apiUrl}api/statistics/rp11/events/leaderboard`,
    },
    dirt5: {
        getPlayer: `${settings.apiUrl}api/statistics/cp2/player`,
        getPlayerStats: `${settings.apiUrl}api/statistics/cp2/player/stats`,
        getFriendsWithPlatforms: `${settings.apiUrl}api/statistics/cp2/platformFriends`,
        getFriends: `${settings.apiUrl}api/statistics/cp2/friends`,
        getRecentActivity: `${settings.apiUrl}api/statistics/cp2/player/recentActivity`,
        getTracks: `${settings.apiUrl}api/statistics/cp2/tracks`,
        getRacedTracks: `${settings.apiUrl}api/statistics/cp2/player/racedTracks`,
        getRacedVehicleClasses: `${settings.apiUrl}api/statistics/cp2/player/racedClasses`,
        getVehicleClasses: `${settings.apiUrl}api/statistics/cp2/vehicleClasses`,
        getTrackResults: `${settings.apiUrl}api/statistics/cp2/trackResults`,
        getFriendsLeaderboard: `${settings.apiUrl}api/statistics/cp2/friendLeaderboards`,
        getCommunityStats: `${settings.apiUrl}api/statistics/cp2/community/stats`,
        getPlaygrounds: `${settings.apiUrl}api/statistics/cp2/playgrounds`,
        getPlayground: (playgroundId: string) => `${settings.apiUrl}api/statistics/cp2/playgrounds/${playgroundId}`,
        postPinPlayground: (playgroundId: string) =>
            `${settings.apiUrl}api/statistics/cp2/playgrounds/${playgroundId}/pin`,
        postUnpinPlayground: (playgroundId: string) =>
            `${settings.apiUrl}api/statistics/cp2/playgrounds/${playgroundId}/unpin`,
        getPlaygroundTags: `${settings.apiUrl}api/statistics/cp2/playgrounds/tags`,
        getRacenetRecommendedPlaygrounds: `${settings.apiUrl}api/statistics/cp2/playgrounds/recommended/racenet`,
        getSimilarPlaygrounds: `${settings.apiUrl}api/statistics/cp2/playgrounds/recommended/similar`,
        getGateCrasherRecommendedPlaygrounds: `${settings.apiUrl}api/statistics/cp2/playgrounds/recommended/gateCrasher`,
        getPlaygroundLeaderboard: (playgroundId: string) =>
            `${settings.apiUrl}api/statistics/cp2/playgrounds/leaderboard/${playgroundId}`,
        getPlaygroundAroundPlayerLeaderboard: (playgroundId: string) =>
            `${settings.apiUrl}api/statistics/cp2/playgrounds/leaderboard/${playgroundId}/aroundPlayer`,
        getPlaygroundCreativeIdeas: `${settings.apiUrl}api/statistics/cp2/playgrounds/creativeIdeas`,
        getPlayerPinnedPlaygrounds: `${settings.apiUrl}api/statistics/cp2/playgrounds/pinned`,
    },
    dirtRally2: {
        getPlayer: `${settings.apiUrl}api/statistics/rp17/player`,
        getPlayerStats: `${settings.apiUrl}api/statistics/rp17/player/stats`,
        getFriendsWithPlatforms: `${settings.apiUrl}api/statistics/rp17/platformFriends`,
        getFriends: `${settings.apiUrl}api/statistics/rp17/friends`,
        getRecentActivity: `${settings.apiUrl}api/statistics/rp17/player/recentActivity`,
        getCommunityStats: `${settings.apiUrl}api/statistics/rp17/community/stats`,
        getPlayerGarageStats: `${settings.apiUrl}api/statistics/rp17/player/garage`,
        getPlayerRacedTracks: `${settings.apiUrl}api/statistics/rp17/player/racedTracks`,
        getRacedVehicleClasses: `${settings.apiUrl}api/statistics/rp17/player/racedClasses`,
        getCommunityChallenges: `${settings.apiUrl}api/statistics/rp17/community/challenges`,
        getTrackResults: `${settings.apiUrl}api/statistics/rp17/player/trackResults`,
        getCombinedFriendsLeaderboard: `${settings.apiUrl}api/statistics/rp17/combinedFriendsLeaderboard`,
        getFriendsLeaderboard: `${settings.apiUrl}api/statistics/rp17/friendsLeaderboard`,
        getCommunityLeaderboard: `${settings.apiUrl}api/statistics/rp17/community/leaderboard`,
    },
    F12021: {
        getPlayer: `${settings.apiUrl}api/F12021Stats/player`,
        getFriendsWithPlatform: `${settings.apiUrl}api/F12021Stats/platformFriends`,
        getFriends: `${settings.apiUrl}api/F12021Stats/friends`,
        getPlayerStats: `${settings.apiUrl}api/F12021Stats/player/stats`,
        getValues: `${settings.apiUrl}api/F12021Stats/values`,
        getLeaderboard: `${settings.apiUrl}api/F12021Stats/leaderboard`,
        getLeaderboardSummary: `${settings.apiUrl}api/F12021Stats/leaderboardSummary`,
        getPlayerRacedTracks: `${settings.apiUrl}api/F12021Stats/player/racedTracks`,
        getEvents: `${settings.apiUrl}api/F12021Stats/events`,
        getEventLeaderboards: `${settings.apiUrl}api/F12021Stats/events/leaderboard/`,
        getPerformanceAnalysisPlayer: `${settings.apiUrl}api/F12021Stats/performanceAnalysis/player`,
        getPerformanceAnalysisFriends: `${settings.apiUrl}api/F12021Stats/performanceAnalysis/friends`,
        getPerformanceAnalysisSpline: `${settings.apiUrl}api/F12021Stats/performanceAnalysis/spline`,
        getPerformanceAnalysisGhost: `${settings.apiUrl}api/F12021Stats/performanceAnalysis/ghost`,
        getPhotosOfWeek: `${settings.apiUrl}api/F12021Stats/photosOfWeek`,
        getNews: `${settings.apiUrl}api/F12021Stats/news`,
    },
    F12022: {
        getPlayer: `${settings.apiUrl}api/F122Stats/player`,
        getFriendsWithPlatform: `${settings.apiUrl}api/F122Stats/platformFriends`,
        getFriends: `${settings.apiUrl}api/F122Stats/friends`,
        getPlayerStats: `${settings.apiUrl}api/F122Stats/player/stats`,
        getValues: `${settings.apiUrl}api/F122Stats/values`,
        getLeaderboard: `${settings.apiUrl}api/F122Stats/leaderboard`,
        getEvents: `${settings.apiUrl}api/F122Stats/events`,
        getEventLeaderboards: `${settings.apiUrl}api/F122Stats/events/leaderboard/`,
        getPhotosOfWeek: `${settings.apiUrl}api/F122Stats/photosOfWeek`,
        getNews: `${settings.apiUrl}api/F122Stats/news`,
        getPerformanceAnalysisPlayer: `${settings.apiUrl}api/F122Stats/performanceAnalysis/player`,
        getPerformanceAnalysisFriends: `${settings.apiUrl}api/F122Stats/performanceAnalysis/friends`,
        getPerformanceAnalysisSpline: `${settings.apiUrl}api/F122Stats/performanceAnalysis/spline`,
        getPerformanceAnalysisGhost: `${settings.apiUrl}api/F122Stats/performanceAnalysis/ghost`,
        getLeaderboardVersion: `${settings.apiUrl}api/F122Stats/leaderboard/version`,
        getPlayerRacedTracks: `${settings.apiUrl}api/F122Stats/player/racedTracks`,
    },
    F12023: {
        getPlayer: `${settings.apiUrl}api/F123Stats/player`,
        getFriendsWithPlatform: `${settings.apiUrl}api/F123Stats/platformFriends`,
        getFriends: `${settings.apiUrl}api/F123Stats/friends`,
        getPlayerStats: `${settings.apiUrl}api/F123Stats/player/stats`,
        getValues: `${settings.apiUrl}api/F123Stats/values`,
        getLeaderboard: `${settings.apiUrl}api/F123Stats/leaderboard`,
        getNews: `${settings.apiUrl}api/F123Stats/news`,
        getPerformanceAnalysisPlayer: `${settings.apiUrl}api/F123Stats/performanceAnalysis/player`,
        getPerformanceAnalysisFriends: `${settings.apiUrl}api/F123Stats/performanceAnalysis/friends`,
        getPerformanceAnalysisSpline: `${settings.apiUrl}api/F123Stats/performanceAnalysis/spline`,
        getPerformanceAnalysisGhost: `${settings.apiUrl}api/F123Stats/performanceAnalysis/ghost`,
        getLeaderboardVersion: `${settings.apiUrl}api/F123Stats/leaderboard/version`,
        getPlayerRacedTracks: `${settings.apiUrl}api/F123Stats/player/racedTracks`,
        getLeaderboardSummary: `${settings.apiUrl}api/F123Stats/player/leaderboardSummary`,
        getAnnouncements: `${settings.apiUrl}api/F123Stats/announcements`,
    },
    F12023Redbull: {
        getRedbullEventWinnersOfTheWeek: (weekId: string) =>
            `${settings.apiUrl}api/F123Stats/events/redbull/weeks/${weekId}/winners`,
        getRedbullEventWeeks: `${settings.apiUrl}api/F123Stats/events/redbull/weeks`,
        getRedbullEventLeaderboard: (weekId: string) =>
            `${settings.apiUrl}api/F123Stats/events/redbull/weeks/${weekId}/leaderboard`,
        getRedbullEventLeaderboardPlayerEntry: (weekId: string) =>
            `${settings.apiUrl}api/F123Stats/events/redbull/weeks/${weekId}/leaderboard/playerEntry`,
        postSignUpForRedbullEvent: `${settings.apiUrl}api/F123Stats/events/redbull/sign-up`,
        getIsUserSignedUp: `${settings.apiUrl}api/F123Stats/events/redbull/isSignedIn`,
    },
    F12023ProChallenges: {
        getProChallenges: `${settings.apiUrl}api/F123Stats/prochallenges`,
        getProChallengesGhostData: (id: number) => `${settings.apiUrl}api/F123Stats/prochallenges/${id}/ghostdata`,
    },
    F12024: {
        getPlayer: `${settings.apiUrl}api/F124Stats/player`,
        getFanzones: `${settings.apiUrl}api/F124Stats/fanzones`,
        getFanzonesVotes: `${settings.apiUrl}api/F124Stats/fanzones/votes`,
        fanzonesVote: (voteId: number) => `${settings.apiUrl}api/F124Stats/fanzones/votes/${voteId}`,
        getFriendsWithPlatform: `${settings.apiUrl}api/F124Stats/platformFriends`,
        getFriends: `${settings.apiUrl}api/F124Stats/friends`,
        getPlayerStats: `${settings.apiUrl}api/F124Stats/player/stats`,
        getValues: `${settings.apiUrl}api/F124Stats/values`,
        getLeaderboard: `${settings.apiUrl}api/F124Stats/leaderboard`,
        getNews: `${settings.apiUrl}api/F124Stats/news`,
        getPerformanceAnalysisPlayer: `${settings.apiUrl}api/F124Stats/performanceAnalysis/player`,
        getPerformanceAnalysisFriends: `${settings.apiUrl}api/F124Stats/performanceAnalysis/friends`,
        getPerformanceAnalysisSpline: `${settings.apiUrl}api/F124Stats/performanceAnalysis/spline`,
        getPerformanceAnalysisGhost: `${settings.apiUrl}api/F124Stats/performanceAnalysis/ghost`,
        getLeaderboardVersion: `${settings.apiUrl}api/F124Stats/leaderboard/version`,
        getPlayerRacedTracks: `${settings.apiUrl}api/F124Stats/player/racedTracks`,
        getLeaderboardSummary: `${settings.apiUrl}api/F124Stats/player/leaderboardSummary`,
        getAnnouncements: `${settings.apiUrl}api/F124Stats/announcements`,
    },
    F12024Redbull: {
        getRedbullEventWinnersOfTheWeek: (weekId: string) =>
            `${settings.apiUrl}api/F124Stats/events/redbull/weeks/${weekId}/winners`,
        getRedbullEventWeeks: `${settings.apiUrl}api/F124Stats/events/redbull/weeks`,
        getRedbullEventLeaderboard: (weekId: string) =>
            `${settings.apiUrl}api/F124Stats/events/redbull/weeks/${weekId}/leaderboard`,
        getRedbullEventLeaderboardPlayerEntry: (weekId: string) =>
            `${settings.apiUrl}api/F124Stats/events/redbull/weeks/${weekId}/leaderboard/playerEntry`,
        postSignUpForRedbullEvent: `${settings.apiUrl}api/F124Stats/events/redbull/sign-up`,
        getIsUserSignedUp: `${settings.apiUrl}api/F124Stats/events/redbull/isSignedIn`,
    },
    F12024ProChallenges: {
        getProChallenges: `${settings.apiUrl}api/F124Stats/prochallenges`,
        getProChallengesGhostData: (id: number) => `${settings.apiUrl}api/F124Stats/prochallenges/${id}/ghostdata`,
    },
    F12021InGameLeagues: {
        getMemberships: `${settings.apiUrl}api/F12021InGameLeagues/memberships`,
        getRecommendedLeagues: `${settings.apiUrl}api/F12021InGameLeagues/recommended-leagues`,
        getEvents: `${settings.apiUrl}api/F12021InGameLeagues/events`,
        searchLeagues: `${settings.apiUrl}api/F12021InGameLeagues/search-leagues`,
        getLeague: `${settings.apiUrl}api/F12021InGameLeagues/leagues`,
        getSeasonResults: `${settings.apiUrl}api/F12021InGameLeagues/season-results`,
        getFriendsLeagues: `${settings.apiUrl}api/F12021InGameLeagues/friend-leagues`,
        getBeginnerLeagues: `${settings.apiUrl}api/F12021InGameLeagues/leagues-beginner`,
        getIntermediateLeagues: `${settings.apiUrl}api/F12021InGameLeagues/leagues-intermediate`,
        getExpertLeagues: `${settings.apiUrl}api/F12021InGameLeagues/leagues-expert`,
        getBeginnerLeaguesDiscover: `${settings.apiUrl}api/F12021InGameLeagues/leagues-beginner-discover`,
        getIntermediateLeaguesDiscover: `${settings.apiUrl}api/F12021InGameLeagues/leagues-intermediate-discover`,
        getExpertLeaguesDiscover: `${settings.apiUrl}api/F12021InGameLeagues/leagues-expert-discover`,
    },
    F12022InGameLeagues: {
        getMemberships: `${settings.apiUrl}api/F122InGameLeagues/memberships`,
        getRecommendedLeagues: `${settings.apiUrl}api/F122InGameLeagues/recommended-leagues`,
        getEvents: `${settings.apiUrl}api/F122InGameLeagues/events`,
        searchLeagues: `${settings.apiUrl}api/F122InGameLeagues/search-leagues`,
        getLeague: `${settings.apiUrl}api/F122InGameLeagues/leagues`,
        getSeasonResults: `${settings.apiUrl}api/F122InGameLeagues/season-results`,
        getFriendsLeagues: `${settings.apiUrl}api/F122InGameLeagues/friend-leagues`,
        getBeginnerLeagues: `${settings.apiUrl}api/F122InGameLeagues/leagues-beginner`,
        getIntermediateLeagues: `${settings.apiUrl}api/F122InGameLeagues/leagues-intermediate`,
        getExpertLeagues: `${settings.apiUrl}api/F122InGameLeagues/leagues-expert`,
        getBeginnerLeaguesDiscover: `${settings.apiUrl}api/F122InGameLeagues/leagues-beginner-discover`,
        getIntermediateLeaguesDiscover: `${settings.apiUrl}api/F122InGameLeagues/leagues-intermediate-discover`,
        getExpertLeaguesDiscover: `${settings.apiUrl}api/F122InGameLeagues/leagues-expert-discover`,
    },
    proLeagues: {
        getValues: `${settings.apiUrl}api/ProLeagues/values`,
        createLeague: `${settings.apiUrl}api/Proleagues`,
        getMemberships: `${settings.apiUrl}api/ProLeagues/memberships`,
        getLeague: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}`,
        createSeason: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/seasons`,
        getTieringTracks: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/TieringTracks`,
        getTieringTimes: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/TieringTimes`,
        getUserPriorities: `${settings.apiUrl}api/ProLeagues/user/priorities`,
        editUserPriorities: `${settings.apiUrl}api/ProLeagues/user/priorities`,
        getAvailableDrivers: `${settings.apiUrl}api/ProLeagues/AvailableDrivers`,
        getCanUserVote: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/CanUserVote`,
        addUpvote: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/UpVote`,
        addDownvote: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/DownVote`,
        editLeagueSettings: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/editableSettings`,
        editLeagueSeasonSettings: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/seasonSettings`,
        searchLeagues: `${settings.apiUrl}api/ProLeagues/SearchLeagues`,
        joinLeague: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/join`,
        setTiers: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/tiers`,
        getTiersCount: (seasonId: number) => `${settings.apiUrl}api/ProLeagues/${seasonId}/tiersCount`,
        validateLeagueName: `${settings.apiUrl}api/ProLeagues/validateName`,
        validateLeagueDescription: `${settings.apiUrl}api/ProLeagues/validateDescription`,
        editWeekendStructure: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/weekendstructure`,
        getCurrentSeasonPastEvents: (leagueId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/currentSeason/pastEvents`,
        getCurrentSeasonUpcomingEvents: (leagueId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/currentSeason/upcomingEvents`,
        assistsProfile: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/assistsProfile`,
        assistsProfileById: `${settings.apiUrl}api/ProLeagues/member/assists`,
        seasonEnroll: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/currentSeason/enroll`,
        joinLeagueAndEnrollInSeason: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/joinandenroll`,
        getAssistsProfileByLevel: `${settings.apiUrl}api/ProLeagues/assistsProfileByLevel`,
        calculateAssistsProfile: `${settings.apiUrl}api/ProLeagues/calculateAssistsProfile`,
        getLeagueMembers: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members`,
        editMemberRole: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/updateRole`,
        editMemberPlatform: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/updatePlatform`,
        banMember: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/ban`,
        unbanMember: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/unban`,
        getTieringResults: (leagueId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/currentSeason/tieringResults`,
        warnMember: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/warn`,
        getSeasonStandings: (seasonId: number) => `${settings.apiUrl}api/ProLeagues/season/${seasonId}/standings`,
        getSeasonConstructorStandings: (seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/season/${seasonId}/constructorStandings`,
        getResults: (eventId: number) => `${settings.apiUrl}api/ProLeagues/events/${eventId}/results`,
        getQualifyingResults: (eventId: number) =>
            `${settings.apiUrl}api/ProLeagues/events/${eventId}/qualifyingResults`,
        editMemberTier: (leagueId: number, seasonId: number, userId: string) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/seasons/${seasonId}/members/${userId}/tier`,
        swapMemberTiers: (leagueId: number, seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/seasons/${seasonId}/swapMemberTiers`,
        getSeasons: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/allSeasons`,
        getSeasonEvents: (seasonId: number) => `${settings.apiUrl}api/ProLeagues/season/${seasonId}/events`,
        rescheduleAvailable: (eventId: number) =>
            `${settings.apiUrl}api/ProLeagues/events/${eventId}/rescheduleAvailable`,
        rescheduleEvent: (leagueId: number, eventId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/events/${eventId}/reschedule`,
        applyPenalty: (leagueId: number, eventId: number, leaderboardEntryId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/events/${eventId}/leaderboards/${leaderboardEntryId}/applyPenalty`,
        editResult: (leagueId: number, eventId: number, leaderboardId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/event/${eventId}/leaderboard/${leaderboardId}`,
        removeMember: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/currentSeason/members/remove`,
        canSeasonBeCreated: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/canSeasonBeCreated`,
        closeLeague: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/close`,
        closeCurrentSeason: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/closecurrentseason`,
        exportResults: (eventId: number) => `${settings.apiUrl}api/ProLeagues/events/${eventId}/results/export/csv`,
        exportQualifyingResults: (eventId: number) =>
            `${settings.apiUrl}api/ProLeagues/events/${eventId}/qualifyingResults/export/csv`,
        exportStandngs: (seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/season/${seasonId}/standings/export/csv`,
        exportConstructorStandings: (seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/season/${seasonId}/constructorStandings/export/csv`,
        addSeasonEvent: (leagueId: number, seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/seasons${seasonId}/events`,
        deleteSeasonEvent: (leagueId: number, eventId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/events${eventId}`,
        leaveLeague: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/leave`,
        updateEvent: (leagueId: number, eventId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/events${eventId}/conditions`,
        getFriendsLeagues: `${settings.apiUrl}api/ProLeagues/friendsLeagues`,
        getRecommendedAllLeagues: `${settings.apiUrl}api/proleagues/recommendedall`,
        getRecommendedLeagues: `${settings.apiUrl}api/ProLeagues/recommendedByType`,
        getUserPlatform: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/members/current/platform`,
        getUserAvailability: (seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/seasons/${seasonId}/members/availability`,
        editUserAvailability: (seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/seasons/${seasonId}/member/availability`,
        deleteLeague: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}`,
        editBannerImage: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/bannerImage`,
        editSocialLinks: (leagueId: number) => `${settings.apiUrl}api/ProLeagues/${leagueId}/socialLinks`,
        deleteMember: (leagueId: number, memberId: string) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/members/${memberId}/delete`,
        getTakenTeams: (seasonId: number) => `${settings.apiUrl}api/ProLeagues/seasons/${seasonId}/takenteams`,
        editTeams: (leagueId: number, seasonId: number) =>
            `${settings.apiUrl}api/ProLeagues/${leagueId}/seasons/${seasonId}/team`,
    },
    grid21: {
        getPlayer: `${settings.apiUrl}api/RP19Stats/player`,
        getFriendsWithPlatform: `${settings.apiUrl}api/RP19Stats/platformFriends`,
        getFriends: `${settings.apiUrl}api/RP19Stats/friends`,
        getPlayerStats: `${settings.apiUrl}api/RP19Stats/player/stats`,
        getPlayerRacedTracks: `${settings.apiUrl}api/RP19Stats/player/racedTracks`,
        getPlayerRacedClasses: `${settings.apiUrl}api/RP19Stats/player/racedClasses`,
        getLeaderboard: `${settings.apiUrl}api/RP19Stats/leaderboard`,
        getEvents: `${settings.apiUrl}api/RP19Stats/events`,
        getEventLeaderboards: `${settings.apiUrl}api/RP19Stats/event/`,
        getWeather: `${settings.apiUrl}api/RP19Stats/weather`,
        getNews: `${settings.apiUrl}api/RP19Stats/news`,
    },
    wrc2023Clubs: {
        getClub: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}`,
        getClubByCode: (accessCode: string) => `${settings.apiUrl}api/wrc2023clubs/accessCode/${accessCode}`,
        getSearchClubMembers: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}/members/search`,
        getClubMembership: `${settings.apiUrl}api/wrc2023clubs/memberships/active`,
        getOfficialClubs: `${settings.apiUrl}api/wrc2023clubs/official`,
        getPartneredClubs: `${settings.apiUrl}api/wrc2023clubs/partnered`,
        getChampionship: (championshipId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/championships/${championshipId}`,
        getSearchClubs: `${settings.apiUrl}api/wrc2023clubs/search`,
        postClub: `${settings.apiUrl}api/wrc2023clubs`,
        getClubChampionshipPointsLeaderboard: (clubId: string, championshipId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/championship/points/${championshipId}`,
        getClubChampionshipEventPointsLeaderboard: (clubId: string, eventId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/event/points/${eventId}`,
        getClubChampionshipTimetrialLeaderboard: (clubId: string, leaderboardId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/leaderboard/${leaderboardId}`,
        postClubReaction: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}/reaction`,
        postTransferClubOwnership: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}/transferOwnership`,
        postLeaveClub: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}/leave`,
        postCloseClub: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}/close`,
        getFriendsClubs: `${settings.apiUrl}api/wrc2023clubs/friendClubs`,
        postJoinClubByAccessCode: (clubId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/application/byAccessCode`,
        postJoinClubById: (clubId: string) => `${settings.apiUrl}api/wrc2023clubs/${clubId}/application`,
        getClubImages: `${settings.apiUrl}api/wrc2023clubs/imageCatalogue`,
        postBanClubMember: (clubId: string, userId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/members/${userId}/ban`,
        postUnbanClubMember: (clubId: string, userId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/members/${userId}/unban`,
        postkickClubMember: (clubId: string, userId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/members/${userId}/kick`,
        postPromoteClubMemberToAdmin: (clubId: string, userId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/members/${userId}/promote/toAdmin`,
        postDemoteClubMemberToMember: (clubId: string, userId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/members/${userId}/demote/toMember`,
        postValidateClubName: `${settings.apiUrl}api/wrc2023clubs/validation/name`,
        postValidateClubDescription: `${settings.apiUrl}api/wrc2023clubs/validation/description`,
        getExportStageLeaderboard: (clubId: string, leaderboardId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/leaderboard/${leaderboardId}/export`,
        getExportEventLeaderboard: (clubId: string, eventId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/event/points/${eventId}/export`,
        getExportChampionshipLeaderboard: (clubId: string, championshipId: string) =>
            `${settings.apiUrl}api/wrc2023clubs/${clubId}/championship/points/${championshipId}/export`,
        redeemCode: (eventID: number) => `${settings.apiUrl}api/wrc2023clubs/event/code/${eventID}`,
    },
    wrc2023: {
        getValues: `${settings.apiUrl}api/wrc2023Stats/values`,
        getPlayer: `${settings.apiUrl}api/wrc2023Stats/player`,
        getGhost: `${settings.apiUrl}api/wrc2023Stats/performanceAnalysis/ghost`,
        getPlayerStats: `${settings.apiUrl}api/wrc2023Stats/player/stats`,
        getFriends: `${settings.apiUrl}api/wrc2023Stats/friends`,
        getFriendsWithPlatform: `${settings.apiUrl}api/wrc2023Stats/platformFriends`,
        getTimetrialStageInfo: `${settings.apiUrl}api/wrc2023Stats/timetrialStageInfo`,
        getTimetrialLeaderboard: (route: string, vehicleClass: string, surfaceCondition: string) =>
            `${settings.apiUrl}api/wrc2023Stats/leaderboard/${route}/${vehicleClass}/${surfaceCondition}`,
        getFriendsTimetrialLeaderboard: (route: string, vehicleClass: string, surfaceCondition: string) =>
            `${settings.apiUrl}api/wrc2023Stats/leaderboard/${route}/${vehicleClass}/${surfaceCondition}/friends`,
        getNews: `${settings.apiUrl}api/wrc2023Stats/news`,
        getAnnouncements: `${settings.apiUrl}api/WRC2023Stats/announcements`,
    },
    putActivityFeedReaction: (activityId: string) => `${settings.apiUrl}api/activityFeed/activity/${activityId}/react`,
    getActivityFeed: `${settings.apiUrl}api/activityFeed`,
    deleteActivityFeed: (activityId: string) => `${settings.apiUrl}api/activityFeed/activity/${activityId}`,
    getNotificationFeed: `${settings.apiUrl}api/notifications`,
    getNotifications: `${settings.apiUrl}api/notification`,
};

export const externalSiteUrls = {
    codemasters: {
        termsAndConditions: "https://www.ea.com/terms-of-service.",
        privacyPolicy: "https://tos.ea.com/legalapp/WEBPRIVACY/US/en/PC/",
    },
    socials: {
        facebook: "https://www.facebook.com/codemasters/",
        twitter: "https://twitter.com/Codemasters",
    },
    shareUrls: {
        facebook: "https://www.facebook.com/sharer.php",
        twitter: "https://twitter.com/intent/tweet",
        whatsapp: "https://api.whatsapp.com/send?text=",
        discord: "https://discord.com/",
    },
    terms: {
        discord: "https://discord.com/terms",
        youtube: "https://www.youtube.com/static?gl=GB&template=terms",
    },
    racenetAccess:
        "https://forms.office.com/Pages/ResponsePage.aspx?id=dE0qnimjb0CjBbnEr1ACa7AK4oSBA_lKukJAF2hbdGhUMTYzTjFYWElESEJCR0pFMFVaNUxFNFVEVS4u",
};

export const icons = {
    raceNetIcon: `${settings.basePath}icons/maskable_icon-192x192.png`,
};

export const images = {
    defaultCover: `${settings.basePath}images/default_cover.jpg`,
    defaultAvatar: `${settings.basePath}images/default_avatar.jpg`, //require("./assets/images/avatars/default_avatar.jpg"),
};

export const EAPaths = {
    index: "/aboutme/index",
    linkedPlatforms: "/connectaccounts/index",
};

const dirtRallyCdnPath = "/RP11";
const dirt5CdnPath = "/Dirt5";
const dirtRally2CdnPath = "/RP17";
const grid21CdnPath = "/RP19";
const F12021CdnPath = "/F12021";
const F12022CdnPath = "/F12022";
const F12023CdnPath = "/F12023";
const WRC2023CdnPath = "/WRC2023";
const F12024CdnPath = "/F12024";

export const cdnPaths = {
    dirtRally: {
        vehicles: `${dirtRallyCdnPath}/Vehicles`,
        splines: `${dirtRallyCdnPath}/splines`,
        tracks: `${dirtRallyCdnPath}/tracks`,
        locations: `${dirtRallyCdnPath}/locations`,
        nationalities: `${dirtRallyCdnPath}/nationalities`,
        gameInfo: `${dirtRallyCdnPath}/gameInfo`,
    },
    dirt5: {
        eventTypes: `${dirt5CdnPath}/eventTypes`,
        locations: `${dirt5CdnPath}/locations`,
        vehicleClasses: `${dirt5CdnPath}/vehicleClasses`,
        vehicles: `${dirt5CdnPath}/vehicles`,
        gameInfo: `${dirt5CdnPath}/gameInfo`,
        hub: `${dirt5CdnPath}/hub`,
    },
    dirtRally2: {
        vehicles: `${dirtRally2CdnPath}/vehicles`,
        liveries: `${dirtRally2CdnPath}/liveries`,
        locations: `${dirtRally2CdnPath}/locations`,
        flags: `${dirtRally2CdnPath}/flags`,
        nationalities: `${dirtRally2CdnPath}/nationalities`,
        gameInfo: `${dirtRally2CdnPath}/gameInfo`,
    },
    f1_2021: {
        assists: `${F12021CdnPath}/assist_pngs`,
        backgrounds: `${F12021CdnPath}/card_bg_jpgs`,
        leagueAttendance: `${F12021CdnPath}/leagueattendance_pngs`,
        leagueResultsTile: `${F12021CdnPath}/league_results_tile`,
        leagueTile: `${F12021CdnPath}/league_tile`,
        leagueTileFlag: `${F12021CdnPath}/league_tile_flag`,
        locationCovers: `${F12021CdnPath}/location_header_bg`,
        leagues: `${F12021CdnPath}/leagues`,
        ranks: `${F12021CdnPath}/rank_pngs`,
        safety: `${F12021CdnPath}/safety_pngs`,
        trophies: `${F12021CdnPath}/trophy_pngs`,
        eventLocationsHub: `${F12021CdnPath}/event_location_hub`,
        nationalities: `${F12021CdnPath}/nationalities`,
        gameInfo: `${F12021CdnPath}/gameInfo`,
    },
    f1_2022: {
        assists: `${F12022CdnPath}/assist_pngs`,
        backgrounds: `${F12022CdnPath}/card_bg_jpgs`,
        leagueAttendance: `${F12022CdnPath}/leagueattendance_pngs`,
        leagueResultsTile: `${F12022CdnPath}/league_results_tile`,
        leagueTile: `${F12022CdnPath}/league_tile`,
        leagueTileFlag: `${F12022CdnPath}/league_tile_flag`,
        locationCovers: `${F12022CdnPath}/location_header_bg`,
        ranks: `${F12022CdnPath}/rank_pngs`,
        safety: `${F12022CdnPath}/safety_pngs`,
        trophies: `${F12022CdnPath}/trophy_pngs`,
        eventLocationsHub: `${F12022CdnPath}/event_location_hub`,
        nationalities: `${F12022CdnPath}/nationalities`,
        gameInfo: `${F12022CdnPath}/gameInfo`,
        leagues: `${F12022CdnPath}/leagues`,
    },
    f1_2023: {
        leagues: `${F12023CdnPath}/leagues`,
        leagueTile: `${F12023CdnPath}/league_tile`,
        leagueTileFlag: `${F12023CdnPath}/league_tile_flag`,
        leagueResultsTile: `${F12023CdnPath}/league_results_tile`,
        leagueCoverPhotos: `${F12023CdnPath}/league_cover_photos`,
        leagueCoverPhotosMobile: `${F12023CdnPath}/league_cover_photos_mobile`,
        eventLocationsHub: `${F12023CdnPath}/event_location_hub`,
        locationCovers: `${F12023CdnPath}/location_header_bg`,
        backgrounds: `${F12023CdnPath}/card_bg_jpgs`,
        ranks: `${F12023CdnPath}/rank_pngs`,
        leagueAttendance: `${F12023CdnPath}/leagueattendance_pngs`,
        safety: `${F12023CdnPath}/safety_pngs`,
        trophies: `${F12023CdnPath}/trophy_pngs`,
        nationalities: `${F12023CdnPath}/nationalities`,
        smallCovers: `${F12023CdnPath}/games_small_covers`,
        featuredLaps: `${F12023CdnPath}/featured_laps`,
        proChallenges: `${F12023CdnPath}/pro_challenges`,
        proChallengesMobile: `${F12023CdnPath}/pro_challenges_mobile`,
        proChallengesAvatars: `${F12023CdnPath}/pro_challenges_avatars`,
        onboarding: `${F12023CdnPath}/onboarding`,
        redbull: `${F12023CdnPath}/red_bull`,
    },
    grid21: {
        gameInfo: `${grid21CdnPath}/gameInfo`,
        hub: `${grid21CdnPath}/hub`,
        playerStats: `${grid21CdnPath}/playerStats`,
        chapters: `${grid21CdnPath}/chapters`,
        vehicles: `${grid21CdnPath}/vehicles`,
        locations: `${grid21CdnPath}/locations`,
        routes: `${grid21CdnPath}/routes`,
        nationalities: `${grid21CdnPath}/nationalities`,
        eventPanelLandscape: `${grid21CdnPath}/event_panel_landscape`,
        eventHubPanel: `${grid21CdnPath}/event_hub_panel`,
        classBadges: `${grid21CdnPath}/class_badges`,
        categoryBadges: `${grid21CdnPath}/category_badges`,
        disciplines: `${grid21CdnPath}/disciplines`,
    },
    wrc2023: {
        eventLocations: `${WRC2023CdnPath}/event_locations`,
        clubCoverPhotos: `${WRC2023CdnPath}/clubs/cover_images`,
        images: `${WRC2023CdnPath}/clubs/images`,
        timetrialLocations: `${WRC2023CdnPath}/timetrial_locations`,
        hubImages: `${WRC2023CdnPath}/hub_images`,
        statsLocationsDesktop: `${WRC2023CdnPath}/stats/locations_desktop`,
        statsLocationsMobile: `${WRC2023CdnPath}/stats/locations_mobile`,
        statsEventLocationsLogo: `${WRC2023CdnPath}/stats/event_location_logos`,
        vehicles: `${WRC2023CdnPath}/stats/vehicles`,
        statsIcons: `${WRC2023CdnPath}/stats/misc`,
        vehicleClasses: `${WRC2023CdnPath}/vehicle_classes`,
        rallyTVConfig: `${WRC2023CdnPath}/promo_events/rally_tv_23`,
    },
    f1_2024: {
        driverNumbers: `${F12024CdnPath}/driver_numbers`,
        constructorLogos: `${F12024CdnPath}/constructor_logo`,
        fanzones: `${F12024CdnPath}/fanzones`,
        fanzoneCoverConstructorLarge: `${F12024CdnPath}/fanzone_cover_constructor_large`,
        fanzoneCoverDriverLarge: `${F12024CdnPath}/fanzone_cover_driver_large`,
        fanzoneCoverConstructorSmall: `${F12024CdnPath}/fanzone_cover_constructor_small`,
        fanzoneCoverDriverSmall: `${F12024CdnPath}/fanzone_cover_driver_small`,
        fanzoneNavConstructorMobile: `${F12024CdnPath}/fanzone_nav_constructor_mobile`,
        fanzoneNavDriverMobile: `${F12024CdnPath}/fanzone_nav_driver_mobile`,
        fanzoneCoverConstructorMobile: `${F12024CdnPath}/fanzone_cover_constructor_mobile`,
        fanzoneCoverDriverMobile: `${F12024CdnPath}/fanzone_cover_driver_mobile`,
        fanzoneBackgroundConstructorDesktop: `${F12024CdnPath}/fanzone_background_constructor_desktop`,
        fanzoneBackgroundDriverDesktop: `${F12024CdnPath}/fanzone_background_driver_desktop`,
        fanzonesOnboarding: `${F12024CdnPath}/fanzones_onboarding`,
        fanzoneVoteDriver: `${F12024CdnPath}/fanzone_vote_driver`,
        fanzoneVoteConstructor: `${F12024CdnPath}/fanzone_vote_constructor`,
        fanzoneVoteEsports: `${F12024CdnPath}/fanzone_vote_esports`,
        fanzoneVoteEsportsConstructor: `${F12024CdnPath}/fanzone_vote_esports_constructor`,
        leagues: `${F12024CdnPath}/leagues`,
        leagueTileFlag: `${F12024CdnPath}/league_tile_flag`,
        leagueCoverPhotos: `${F12024CdnPath}/league_cover_photos`,
        leagueCoverPhotosMobile: `${F12024CdnPath}/league_cover_photos_mobile`,
        eventLocationsHub: `${F12024CdnPath}/event_location_hub`,
        backgrounds: `${F12024CdnPath}/card_bg_jpgs`,
        ranks: `${F12024CdnPath}/rank_pngs`,
        leagueAttendance: `${F12024CdnPath}/leagueattendance_pngs`,
        safety: `${F12024CdnPath}/safety_pngs`,
        trophies: `${F12024CdnPath}/trophy_pngs`,
        nationalities: `${F12024CdnPath}/nationalities`,
        smallCovers: `${F12024CdnPath}/games_small_covers`,
        featuredLaps: `${F12024CdnPath}/featured_laps`,
        proChallenges: `${F12024CdnPath}/pro_challenges`,
        proChallengesMobile: `${F12024CdnPath}/pro_challenges_mobile`,
        proChallengesAvatars: `${F12024CdnPath}/pro_challenges_avatars`,
        onboarding: `${F12024CdnPath}/onboarding`,
        redbull: `${F12024CdnPath}/red_bull`,
    },
    activityGamePackshots: "/Common/activity_games_packshots",
    gamePackshots: "/Common/games_packshots",
    gameMiniHeroes: "/Common/games_mini_heros",
    landing: "/Common/landing",
    gameSmallCovers: "/Common/games_small_covers",
    nationalities_flags: "/Common/nationalities_flags",
    qrCodes: "/Common/qr",
    gamesLibrary: "/Common/games_library",
};

interface IGameSetting {
    gameId: GamesEnum;
    route?: string;
    activityFeedEnabled: boolean;
}

// If there is no route, will be undefined & there will be no hub!

//TODO : Review supported platorms per game
export const gameSettings: IGameSetting[] = [
    {
        gameId: GamesEnum.DirtRally,
        route: routingUrls.dirtRally.root,
        activityFeedEnabled: true,
    },
    {
        gameId: GamesEnum.DirtRally2,
        route: routingUrls.dirtRally2.root,
        activityFeedEnabled: true,
    },
    {
        gameId: GamesEnum.Dirt4,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.Dirt5,
        route: routingUrls.dirt5.root,
        activityFeedEnabled: true,
    },
    {
        gameId: GamesEnum.Grid,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.Grid2021,
        route: routingUrls.grid21.root,
        activityFeedEnabled: true,
    },
    {
        gameId: GamesEnum.F12020,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.F12021,
        route: routingUrls.f1_2021.root,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.F12022,
        route: routingUrls.f1_2022.root,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.F12023,
        route: routingUrls.f1_2023.root,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.F12024,
        route: routingUrls.f1_2024.root,
        activityFeedEnabled: false,
    },
    {
        gameId: GamesEnum.WRC2023,
        route: routingUrls.wrc2023.root,
        activityFeedEnabled: false,
    },
];

export const Dirt5SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.Stadia,
];

export const DirtRallySupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.Oculus,
];

export const DirtRally2SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.Oculus,
    Platforms.WindowsStore,
];

export const F12021SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
];

export const F12022SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.EAPlay,
];

export const F12023SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.EAPlay,
];

export const F12024SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.EAPlay,
];

export const GRID21SupportedPlatforms: Platforms[] = [
    Platforms.Steam,
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.EAPlay,
];

export const WRC2023SupportedPlatforms: Platforms[] = [
    Platforms.MicrosoftLive,
    Platforms.PlaystationNetwork,
    Platforms.PC,
];

export const PlatformNames = {
    [Platforms.None]: "Cross-Platform",
    [Platforms.Stadia]: "Stadia",
    [Platforms.Steam]: "Steam",
    [Platforms.MicrosoftLive]: "Xbox",
    [Platforms.PlaystationNetwork]: "PlayStation",
    [Platforms.Oculus]: "Oculus",
    [Platforms.EAPlay]: "EA App",
    [Platforms.WindowsStore]: "Windows",
    [Platforms.PC]: "PC",
};

export const ShortPlatformNames = {
    [Platforms.None]: "X-Pl",
    [Platforms.Stadia]: "Stadia",
    [Platforms.Steam]: "Steam",
    [Platforms.MicrosoftLive]: "Xbox",
    [Platforms.PlaystationNetwork]: "PSN",
    [Platforms.Oculus]: "Oculus",
    [Platforms.EAPlay]: "EA App",
    [Platforms.WindowsStore]: "Windows",
    [Platforms.PC]: "PC",
};

export const analyticsIds = {
    gameHubVideo: "g-game-hub-trailer",
    identitySsid: "identity-ssid",
};

export const TabTitle = {
    RaceNet: "Racenet",
    Hub: "Hub",
    Stats: "Stats",
    Info: "Info",
    Friends: "Friends",
    Community: "Community",
    PerformanceAnalysis: "PA",
    Events: "Events",
    News: "News",
    NotFound: "Not found",
    Profile: "Profile",
    GameLib: "Games library",
    Settings: "Settings",
    ReleaseNotes: "Release notes",
    Playgrounds: "Playgrounds",
    PlaygroundPage: "Playground",
    Timetrial: "Timetrial",
    Leagues: "Leagues",
    League: "League",
    LeagueHelp: "League Racing - User Guide",
    LeaguesSearch: "Leagues search",
    Photos: "Photos",
    Faq: "FAQ",
    Clubs: "Clubs",
    Club: "Club",
    ClubMembers: "Club's members",
    CLubMembership: "Your clubs",
    FriendsClubs: "Friends clubs",
    F123RedbullPage: "Red Bull Event",
    F124RedbullPage: "Red Bull Event",
    Fanzone: "Fanzone",
};

export const proLeaguesConfig = {
    f1PlayerUserId: "42",
    MAX_SEASON_LENGTH: 30,
    F123_PRO_LEAGUES_VERSION: 1,
    F124_PRO_LEAGUES_VERSION: 2,
    F123_COVER_PHOTOS_NUMBER: 24,
    F124_COVER_PHOTOS_NUMBER: 20,
    ONDEMAND_MAX_MEMBER_COUNT: 20,
    SCHEDULED_MAX_MEMBER_COUNT: 1000,
    maxAiLevel: 110,
    F123_REPORTING_YEAR: 23,
    F124_REPORTING_YEAR: 24,
};

export const f124FanzonesConfig = {
    MAX_ROOM_COUNT: 50,
};

export const legalMessage = "Includes in-game purchases (includes random items).";
