import IWebAccpetanceTextResponse from "apiServices/identity/responses/IWebAcceptanceTextResponse";

export interface IWebAcceptanceText extends IWebAccpetanceTextResponse {}

export interface IWebAcceptanceStore {
    hasAcceptedTerms: boolean;
    acceptanceText?: IWebAcceptanceText;
}

export const defaultWebAcceptanceStore: IWebAcceptanceStore = {
    hasAcceptedTerms: false,
};
