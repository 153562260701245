import { HTMLAttributes, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SettingsDropdownContents from "../common/SettingsDropdownContents";
import DropDownToggleButton from "common/components/buttons/DropDownToggleButton";
import { fontTypes, styleGuide } from "theme";
import Typography from "@material-ui/core/Typography";
import { useIdentityContext } from "contexts/identity/IdentityContext";
import ProfileAvatar from "common/components/ProfileAvatar";
import RouterLink from "common/components/links/RouterLink";
import { routingUrls } from "settings";
import Collapse from "@material-ui/core/Collapse";
import { ITheme } from "@material-ui/core";

const useStyles = makeStyles((theme: ITheme) => {
    const dropdownLabelZindex = theme.zIndex.appBar;

    const profileIconSize = theme.spacing(4);
    const profileIconMargin = theme.spacing(1);

    const borderRadius = profileIconSize / 2 + profileIconMargin;

    const _transition = theme.transitions.create("all");

    return createStyles({
        root: {
            position: "relative",
            backgroundColor: theme.palette.info.main,
            color: styleGuide.color.white,
            borderRadius: borderRadius,
            zIndex: dropdownLabelZindex,
            boxShadow: theme.styles.defaultBoxShadow,
            cursor: "pointer",
        },
        dropdownLabel: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
        },
        dropdownHoverEffect: {
            "& $dropdownToggleButton": {
                opacity: theme.styles.defaultHoverOpacity,
            },
        },
        dropdownLabelOpen: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        dropdownContentsContainer: {
            zIndex: dropdownLabelZindex - 1,
            position: "relative",
            overflow: "hidden",
        },
        dropdownContentsPlaceholder: {
            visibility: "hidden",
        },
        dropdownContents: {
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "-100%",
            left: 0,
            backgroundColor: theme.palette.info.main,
            color: styleGuide.color.white,
            transition: _transition,
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            pointerEvents: "all",
            opacity: 0,
        },
        showDropdownContents: {
            top: 0,
            opacity: 1,
            pointerEvents: "all",
        },
        profileIcon: {
            margin: profileIconMargin,
            "&:hover": {
                opacity: theme.styles.defaultHoverOpacity,
            },
        },
        avatar: { width: profileIconSize, height: profileIconSize },
        dropdownToggleButton: {},
        userName: {
            ...fontTypes.mediumItalic,
        },
    });
});

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export default function DesktopDropDown(props: IProps) {
    const { className, children, ...rest } = props;

    const classes = useStyles();

    const identityContext = useIdentityContext();

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [showDropDownHoverState, setShowDropDownHoverState] = useState<boolean>(false);

    const closeDropDown = () => {
        setDropdownIsOpen(false);
    };

    const dropdownContent = <SettingsDropdownContents setClose={closeDropDown} />;

    const toggleDropDown = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    };

    return (
        <ClickAwayListener onClickAway={closeDropDown}>
            <div
                className={clsx(className, classes.root, {
                    [classes.dropdownHoverEffect]: showDropDownHoverState,
                })}
                {...rest}
            >
                <div
                    className={clsx(classes.dropdownLabel, {
                        [classes.dropdownLabelOpen]: dropdownIsOpen,
                    })}
                    onMouseEnter={() => setShowDropDownHoverState(true)}
                    onMouseLeave={() => setShowDropDownHoverState(false)}
                    onClick={toggleDropDown}
                >
                    <RouterLink
                        to={routingUrls.profile}
                        className={classes.profileIcon}
                        onMouseEnter={() => setShowDropDownHoverState(false)}
                        onMouseLeave={() => setShowDropDownHoverState(true)}
                    >
                        <ProfileAvatar
                            className={classes.avatar}
                            imageSrc={identityContext.state.preferences?.userPreferences?.profileImageUrl}
                        />
                    </RouterLink>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.userName} variant="h6">
                            {identityContext.state.identity.displayName}
                        </Typography>
                    </div>
                    <DropDownToggleButton
                        className={classes.dropdownToggleButton}
                        onClick={toggleDropDown}
                        color="inherit"
                        isOpen={dropdownIsOpen}
                    />
                </div>
                <div className={classes.dropdownContentsContainer}>
                    <Collapse in={dropdownIsOpen}>
                        <div className={classes.dropdownContentsPlaceholder}>{dropdownContent}</div>
                    </Collapse>
                    <div
                        className={clsx(classes.dropdownContents, {
                            [classes.showDropdownContents]: dropdownIsOpen,
                        })}
                    >
                        {dropdownContent}
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
}
