import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const CloseIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
            <polygon points="50,8.56 41.44,0 25,16.44 8.56,0 0,8.56 16.44,25 0,41.44 8.56,50 25,33.56 41.44,50 50,41.44 33.56,25 " />
        </svg>
    </SvgIcon>
);

export default CloseIcon;
