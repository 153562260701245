import { HTMLAttributes, ReactNode } from "react";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import MoreActionsMenu, { IMoreActionsMenuAction } from "common/components/MoreActionsMenu";
import Typography from "@material-ui/core/Typography";
import useCardBorderRadiusClass from "hooks/useCardBorderRadiusClass";
import useResponsivePaddingClass from "hooks/useResponsivePaddingClass";
import clsx from "clsx";
import Spacer from "common/components/Spacer";
import ProfileAvatar, { IProfileAvatarProps } from "common/components/ProfileAvatar";
import HideableCard from "common/components/HideableCard";
import INotification from "../../interfaces/INotification";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import { dispatchRemoveNotification } from "contexts/notification/actions/NotificationActions";
import { ITheme } from "@material-ui/core";
import { postAnalyticsEvent } from "utils/analyticsHelper";
import { NotificationType } from "common/enums/NotificationTypeEnum";
import { styleGuide } from "theme";
import { getTimeDifferenceInUTC } from "utils/dateTools";

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        root: {
            position: "relative",
            zIndex: 0,
            display: "flex",
            alignItems: "center",
            border: `solid 1px ${theme.palette.info.light}`,
            width: "100%",
            marginBottom: theme.spacing(1),
            color: theme.palette.text.secondary,
            backgroundColor: theme.variants.EALightBlue,
        },
        text: {
            flex: 1,
            display: "-webkit-box",
            margin: "0 auto",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        actionButtonRoot: {
            backgroundColor: styleGuide.color.white,
        },
    })
);

interface IProps extends HTMLAttributes<HTMLDivElement> {
    profileAvatarProps?: IProfileAvatarProps;
    notification: INotification;
    title?: string;
    text: ReactNode;
    actions?: IMoreActionsMenuAction[];
    onClick?: () => void;
}

export default function NotificationCard(props: IProps) {
    const { profileAvatarProps, notification, text, actions = [] } = props;
    const classes = useStyles();
    const theme = useTheme();
    const notificationContext = useNotificationContext();

    const borderRadiusClassName = useCardBorderRadiusClass({ layer: "1" });
    const paddingClassName = useResponsivePaddingClass({ layer: "2" });

    const commonActions: IMoreActionsMenuAction[] = [
        {
            label: "Remove notification",
            onClick: () => {
                notification.notificationType === NotificationType.FriendRequested &&
                    postAnalyticsEvent("notification.friend_request.remove");
                dispatchRemoveNotification(
                    notificationContext.dispatch,
                    notificationContext.state.oldNotificationStore.notifications,
                    notificationContext.state.newNotificationStore.notifications,
                    notification.notificationID,
                    notification.read
                );
            },
        },
    ];

    return (
        <HideableCard hidden={false}>
            <div className={clsx(classes.root, borderRadiusClassName, paddingClassName)}>
                {profileAvatarProps !== undefined && (
                    <>
                        <ProfileAvatar {...profileAvatarProps} borderColor={theme.palette.secondary.main} />
                        <Spacer />
                    </>
                )}
                <div
                    className={classes.text}
                    onClick={props.onClick}
                    style={{ cursor: props.onClick ? "pointer" : "auto" }}
                >
                    {props.title && <Typography style={{ fontWeight: "bold" }}>{props.title}</Typography>}
                    <Typography style={{ lineHeight: 1.2 }}>{text}</Typography>
                </div>
                {props.children}
                <Spacer />
                <span style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <MoreActionsMenu
                        actions={[...actions, ...commonActions]}
                        iconButtonProps={{
                            size: "small",
                            classes: {
                                root: classes.actionButtonRoot,
                            },
                        }}
                    />
                    <Typography variant="caption" align="right" style={{ color: theme.palette.info.light }}>
                        {getTimeDifferenceInUTC(notification.datePosted)}
                    </Typography>
                </span>
            </div>
        </HideableCard>
    );
}
