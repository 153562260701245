import { useEffect, useState } from "react";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { isIOS } from "react-device-detect";
import { icons } from "settings";
import StyledSnackbar, { IStyledSnackbarProps } from "common/components/StyledSnackbar";
import Spacer from "common/components/Spacer";
import { ITheme } from "@material-ui/core";
import CloseIcon from "assets/icons/CloseIcon";
import clsx from "clsx";

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        root: {
            position: "fixed",
            width: "100%",
            left: 0,
            right: 0,
            overflow: "hidden",
            top: 0,
            backgroundColor: theme.palette.common.white,
            maxHeight: 65,
            transition: "top 0.3s",
        },
        hide: {
            top: -65,
        },
        show: {
            top: 0,
        },
        snackbar: {
            display: "flex",
            // alignItems: "center",
            height: "100%",
        },
        contentLink: {
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            padding: 4,
            borderRadius: theme.styles.borderRadiusXs,
            width: "100%",
        },
        openApp: {
            backgroundColor: theme.palette.info.main,
            borderRadius: 4,
            padding: "4px 12px",
        },
    })
);

interface IProps extends IStyledSnackbarProps {
    onClose?: () => void;
    handleClose: (showAgain: boolean) => void;
}

export default function InstallAppSnackbar(props: IProps) {
    const { onClose, handleClose, open, ...rest } = props;
    const classes = useStyles();
    const [timeOpen, setTimeOpen] = useState(true);
    const theme: ITheme = useTheme();
    const [isAppInstalled, setIsAppInstalled] = useState<boolean>();

    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.ea.gp.racenet&pli=1";
    const nativeAppLink = "racenetapp://";

    const installAppMessage = (
        <>
            <Typography style={{ fontWeight: "bold" }}>EA Racenet</Typography>
            <Typography variant="caption" style={{ lineHeight: 1.2 }}>
                Download now on the Play Store.
            </Typography>
        </>
    );

    const openNativeApp = (
        <>
            <Typography style={{ fontWeight: "bold", lineHeight: 1.4 }}>EA Racenet</Typography>
            <Typography variant="caption" style={{ lineHeight: 1.4 }}>
                Open in the EA Racenet app.
            </Typography>
        </>
    );

    const isInStandaloneMode = () => {
        if (!isIOS) {
            return (
                window.matchMedia("(display-mode: standalone)").matches ||
                "standalone" in window.navigator ||
                //@ts-ignore
                window.navigator.standalone ||
                //@ts-ignore
                window.clientInformation.standalone
            );
        }
        return (
            window.matchMedia("(display-mode: standalone)").matches ||
            //@ts-ignore
            window.navigator.standalone ||
            //@ts-ignore
            window.clientInformation.standalone
        );
    };

    const installedApp = async () => {
        try {
            //@ts-ignore
            const apps = await navigator.getInstalledRelatedApps();

            // window.alert("Installed apps: " + JSON.stringify(apps));
            // console.log('Installed apps', apps);

            if (apps.length > 0) {
                const nativeApp = apps.find((app: { id: string }) => app.id === "com.ea.gp.racenet");
                if (nativeApp) {
                    setIsAppInstalled(true);
                } else {
                    setIsAppInstalled(false);
                }
            }
        } catch (err) {
            console.log("Error getting installed apps", err);
        }
    };

    const [scrollDirection, setScrollDirection] = useState<"down" | "up">();

    useEffect(() => {
        installedApp();
    }, []);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        };
    }, [scrollDirection]);

    return !isInStandaloneMode() && !isIOS ? (
        <>
            <div
                style={{
                    position: "sticky",
                    height: 65,
                    width: "100%",
                    display: scrollDirection === "down" || !open ? "none" : "block",
                }}
            />
            <StyledSnackbar
                // onClose={onClose}
                id={"banner"}
                {...rest}
                open={open && timeOpen}
                className={clsx(classes.root, {
                    [classes.hide]: scrollDirection === "down",
                    [classes.show]: scrollDirection === "up",
                })}
                style={{ padding: "0px 0px" }}
            >
                <div className={classes.snackbar}>
                    <IconButton
                        onClick={() => handleClose && handleClose(false)}
                        color="default"
                        style={{ width: 30, minHeight: "100%" }}
                    >
                        <CloseIcon style={{ width: 10, height: 10 }} />
                    </IconButton>
                    {/* <Spacer size={0.5} /> */}
                    <div className={classes.contentLink}>
                        <div style={{ width: 50, height: 50, display: "flex", alignItems: "center" }}>
                            <img
                                src={icons.raceNetIcon}
                                alt=""
                                style={{
                                    borderRadius: theme.styles.borderRadiusXs,
                                    width: "100%",
                                }}
                            />
                        </div>

                        <Spacer />

                        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            {isAppInstalled ? openNativeApp : installAppMessage}
                        </div>

                        <Spacer size={0.5} />

                        <a
                            className={classes.openApp}
                            href={isAppInstalled ? nativeAppLink : playStoreUrl}
                            onClick={() => handleClose(true)}
                        >
                            <Typography style={{ color: theme.palette.common.white, fontWeight: "bold" }}>
                                {isAppInstalled ? "OPEN" : "VIEW"}
                            </Typography>
                        </a>
                        <Spacer size={0.5} />
                    </div>
                </div>
            </StyledSnackbar>
        </>
    ) : null;
}
