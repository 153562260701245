import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";
import { isMobile, isTablet } from "react-device-detect";

const useIsMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("xs")) || (isMobile && !isTablet);
};

export default useIsMobile;
