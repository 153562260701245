import IFetch from "common/interfaces/IFetch";
import IPreference from "pages/preferences/interfaces/IPreference";
import { images } from "settings";
import IIdentity from "common/interfaces/IIdentity";
import IPlatform from "common/interfaces/IPlatform";
import { OnboardingType } from "apiServices/identity/types/OnboardingTypes";
import { IPostNotificationPreferencesRequestBody } from "apiServices/identity/requests/IPostNotificationPreferencesRequest";

export interface IIdentityStore {
    identity: IUserIdentityStore;
    preferences: IUserPreferencesStore;
    jwtTokens: IJwtTokensStore;
    completedOnboardings: OnboardingType[];
    pushNotificationPreferences: IPostNotificationPreferencesRequestBody;
}

export interface IUserIdentityStore extends IIdentity, IFetch {}

export interface IUserPreferencesStore extends IFetch {
    userPreferences: IPreference;
    preferencesValues: {
        coverPhotos: string[];
        profileImages: string[];
        providers: IPlatform[];
    };
}

export interface IJwtTokens {
    accessToken: string;
    refreshToken: string;
}

export interface IJwtTokensStore extends IFetch {
    tokens: IJwtTokens;
}

const defaultPreferences: IUserPreferencesStore = {
    userPreferences: {
        coverPhotoUrl: images.defaultCover,
        privacyLevelId: 1,
        profileImageUrl: images.defaultAvatar,
        providers: [],
    },
    preferencesValues: {
        coverPhotos: [],
        profileImages: [],
        providers: [],
    },
    hasFetched: false,
    loading: false,
};

const defaultJwtTokens: IJwtTokensStore = {
    tokens: {
        accessToken: "",
        refreshToken: "",
    },
    hasFetched: false,
    loading: false,
};

export const defaultIdentity: IIdentityStore = {
    identity: {
        ssid: "",
        isAuthenticated: false,
        displayName: "",
        isNewProfile: false,
        isTermsAccepted: false,
        hasFetched: false,
        loading: false,
        countryCode: "",
    },
    preferences: defaultPreferences,
    jwtTokens: defaultJwtTokens,
    completedOnboardings: [],
    pushNotificationPreferences: {
        f123: false,
        friendRequests: false,
    },
};
