import React, { ReactNode } from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ITheme, useTheme } from "@material-ui/core";
import Spacer from "./Spacer";
import { styleGuide } from "theme";

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        paper: {
            backgroundColor: styleGuide.color.white,
            color: theme.palette.common.black,
            border: `1px solid ${theme.palette.info.light}`,
        },
    })
);

export interface IMoreActionsMenuAction {
    label: ReactNode;
    onClick: () => void;
}

interface IProps {
    actions: IMoreActionsMenuAction[];
    iconButtonProps?: IconButtonProps;
}

export default function MoreActionsMenu({ actions, iconButtonProps }: IProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme: ITheme = useTheme();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                style={{
                    background: theme.variants.transparentColour,
                    color: theme.palette.common.white,
                }}
                {...iconButtonProps}
            >
                <MoreHorizIcon />
            </IconButton>
            {actions.length > 0 && (
                <Menu
                    classes={{ paper: classes.paper }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {actions.map((action, i) => (
                        <>
                            <MenuItem
                                style={{ margin: "0 4px 0 4px", borderRadius: 4 }}
                                key={i}
                                onClick={() => {
                                    action.onClick();
                                    handleClose();
                                }}
                                dense
                            >
                                {action.label}
                            </MenuItem>
                            {i < actions.length - 1 && <Spacer />}
                        </>
                    ))}
                </Menu>
            )}
        </>
    );
}
