import INotification from "../interfaces/INotification";
import FriendRequestCard from "./cards/FriendRequestCard";
import FriendRequestAcceptedCard from "./cards/FriendRequestAcceptedCard";
import { NotificationType } from "common/enums/NotificationTypeEnum";
import NotificationCard from "./cards/NotificationCard";
import LeagueNotificationCard from "./cards/LeagueNotificationCard";

export default function NotificationsCardResolver(notification: INotification) {
    switch (notification.notificationType) {
        case NotificationType.FriendRequested:
            return <FriendRequestCard {...notification} />;
        case NotificationType.FriendRequestAccepted:
            return <FriendRequestAcceptedCard {...notification} />;
        case NotificationType.ProLeagueLeagueLiked:
        case NotificationType.ProLeagueCreated:
        case NotificationType.ProLeagueSeasonCreated:
        case NotificationType.ProLeagueMemberJoined:
        case NotificationType.ProLeagueMemberPromotedToAdmin:
        case NotificationType.ProLeagueEventStartingSoon:
        case NotificationType.ProLeagueNameChanged:
        case NotificationType.ProLeagueMemberBanned:
        case NotificationType.ProLeagueBanLifted:
        case NotificationType.ProLeagueMemberEnroled:
        case NotificationType.ProLeaguePlatformChanged:
        case NotificationType.ProLeaguePrivacyLevelChanged:
        case NotificationType.ProLeagueTieringComplete:
        case NotificationType.ProLeagueWarningApplied:
        case NotificationType.ProLeagueResultsReceived:
        case NotificationType.ProLeaguePenaltyApplied:
        case NotificationType.ProLeagueRemovedFromSeason:
        case NotificationType.ProLeagueMemberUpdatedAvailability:
        case NotificationType.ProLeagueReserveSubbed:
        case NotificationType.ProLeagueEventLobbyOpened:
        case NotificationType.ProLeagueTieringFailed:
            return <LeagueNotificationCard {...notification} />;
        case NotificationType.ClubLiked:
        case NotificationType.ClubOwnershipTransferred:
        case NotificationType.ClubRoleChangedToAdmin:
        case NotificationType.ClubMemberUnbanned:
        case NotificationType.ClubMemberBanned:
        case NotificationType.ClubAccessLevelChanged:
        case NotificationType.ClubNameChanged:
        case NotificationType.ClubMemberJoined:
        case NotificationType.ClubPlatformChanged:
        case NotificationType.ClubEventStartingSoon:
        case NotificationType.ClubChampionshipCreated:
        case NotificationType.ClubChampionshipFinished:
        case NotificationType.ClubChampionshipCancelled:
            return <NotificationCard notification={notification} text={notification.content} />;
        default:
            return <NotificationCard notification={notification} text={notification.content} />;
    }
}
