import { useCookieContext } from "./CookieContext";
import CookieBanner from "./banner/CookieBanner";
import { useEffect } from "react";

export default function CookieConsent() {
    const cookieContext = useCookieContext();

    useEffect(() => {
        document.body.addEventListener("truste-consent", () => cookieContext.onCloseOverlay());

        return () => {
            document.body.removeEventListener("truste-consent", () => {});
        };
    }, [cookieContext.cookieBannerIsOpen]);

    return <CookieBanner open={cookieContext.cookieBannerIsOpen} />;
}
