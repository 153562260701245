import { isRaceNetMobileApp, isRaceNetTabletApp, webViewPostMessage } from "./webViewTools";

export const postAnalyticsEvent = (eventName: string, data?: any) => {
    if (isRaceNetMobileApp || isRaceNetTabletApp) {
        const messageData = JSON.stringify(data);
        webViewPostMessage(`#${eventName}${messageData?.length > 0 ? "#" + messageData : ""}`);
        return;
    }
    //@ts-ignore
    if (typeof dataLayer === "undefined") return;

    if (data) {
        //@ts-ignore
        dataLayer.push({
            event: eventName,
            custom_event_value: data,
        });
    } else {
        //@ts-ignore
        dataLayer.push({
            event: eventName,
        });
    }
};
