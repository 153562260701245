import React, { createContext, FunctionComponent, useEffect, useRef, useContext, useReducer } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { defaultNotificationContext, INotificationContextStore } from "./NotificationContextStore";
import { reducer } from "./NotificationReducer";
import {
    dispatchSetNewNotificationsCount,
    dispatchSetNewNotificationsRefreshable,
} from "./actions/NewNotificationActions";

import { dispatchFetchAllNotifications } from "./actions/NotificationActions";
import { useConfig } from "contexts/configuration/ConfigurationContext";
import { useIdentityContext } from "../identity/IdentityContext";

const NotificationContext = createContext<{ state: INotificationContextStore; dispatch: React.Dispatch<any> }>({
    state: defaultNotificationContext,
    dispatch: () => null,
});

export const NotificationContextProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultNotificationContext);
    const config = useConfig();
    const identityContext = useIdentityContext();

    const latestNotificationCount = useRef(0);
    latestNotificationCount.current = state.newNotificationStore.count;

    useEffect(() => {}, [state.oldNotificationStore.notifications]);

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(config.notificationHubUrl, {
                accessTokenFactory(): string {
                    return identityContext.state.jwtTokens.tokens.accessToken;
                },
            })
            .withAutomaticReconnect()
            .build();

        connection
            .start()
            .then(() => {
                connection.on("SendNotificationUpdate", () => {
                    dispatchSetNewNotificationsCount(dispatch, latestNotificationCount.current + 1);
                    dispatchSetNewNotificationsRefreshable(dispatch, true);
                });
            })
            .catch((e) => console.log("Connection failed: ", e));

        return function cleanUp() {
            connection.stop();
        };
    }, []);

    useEffect(() => {
        dispatchFetchAllNotifications(
            dispatch,
            state.oldNotificationStore.notifications,
            state.newNotificationStore.notifications
        );
    }, []);

    return <NotificationContext.Provider value={{ state, dispatch }}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => useContext(NotificationContext);
