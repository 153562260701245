import { useIdentityContext } from "contexts/identity/IdentityContext";
import AuthorisedRoutes from "./AuthorisedRoutes";
import AnonymousRoutes from "./AnonymousRoutes";
import { WebAcceptanceContextProvider } from "contexts/webAcceptance/WebAcceptanceContext";
import { useLocation } from "react-router-dom";
import { routingUrls } from "settings";
import AppStoreRedirect from "pages/appStoreRedirect/AppStoreRedirect";

export default function Routing() {
    const location = useLocation().pathname;
    const identityContext = useIdentityContext();

    return location === routingUrls.appStoreRedirect ? (
        <AppStoreRedirect />
    ) : identityContext.state.identity.isAuthenticated ? (
        <WebAcceptanceContextProvider>
            <AuthorisedRoutes />
        </WebAcceptanceContextProvider>
    ) : (
        <AnonymousRoutes />
    );
}
