import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import useTrainTracks, { TrainTrackLayer } from "./useTrainTracks";

const useStyles = (borderRadius: number) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            cardBorderRadius: {
                borderRadius: borderRadius,
            },
        });
    });

interface IProps {
    layer?: TrainTrackLayer;
}

export default function useCardBorderRadiusClass(props: IProps = {}) {
    const { layer = "0" } = props;

    const trainTracks = useTrainTracks();

    const classes = useStyles(trainTracks[layer].borderRadius)();

    return classes.cardBorderRadius;
}
