import { IFriendsResponse } from "apiServices/common/response/IFriendsResponse";
import Platforms from "common/enums/PlatformsEnum";
import RelationshipEnum from "common/enums/RelationshipEnum";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";

export interface ISearchResults {
    existing: IFriendsResponse;
    search: IFriendsResponse;
}

export interface IFriendsConsolidatedResponse {
    pageCount: string;
    totalSize: string;
    platform: Platforms;
    entries: IFriendsByPlatformEntryResponse[];
    operationStatus?: ResponseStatusEnum;
    error: IError;
}

interface IError {
    requestedPlatform?: string;
    message?: string;
}

export interface IFriendsByPlatformFailedResponse {
    requestedPlatform: string;
    message: string;
}

export interface IFriendsByPlatformResponse {
    pageCount: string;
    totalSize: string;
    platform: Platforms;
    entries: IFriendsByPlatformEntryResponse[];
}

export interface IFriendsByPlatformEntryResponse {
    ssid: string;
    displayName: string;
    isEAFriend: boolean;
    isPlatformFriend: boolean;
    platformDisplayName: string;
    profileImageUrl: string;
    relationshipType?: string;
    friendRelationship?: RelationshipEnum | string;
}

export const defaultConsolidatedFriendsResponse: IFriendsByPlatformResponse = {
    pageCount: "",
    totalSize: "",
    platform: Platforms.None,
    entries: [],
};

export const defaultConsolidatedFriendsErrorResponse: IFriendsByPlatformFailedResponse = {
    requestedPlatform: "",
    message: "",
};
