import { fetchIdentity, fetchSecureIdentity } from "apiServices/identity/IdentityApiService";
import { ActionMap } from "common/types/ActionMapType";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import { dispatchSetIdentityPreferences } from "./PreferencesActions";
import { IUserIdentityStore } from "../IdentityStore";
import { dispatchSetCompletedOnboarding } from "./OnboardingActions";
import { dispatchSetNotificationPreferences } from "./NotificationPreferencesActions";

export enum IdentityTypes {
    FetchIdentity = "FETCH_IDENTITY",
    FetchIdentityCompleted = "FETCH_IDENTITY_COMPLETED",
}

type IdentityTypePayload = {
    [IdentityTypes.FetchIdentity]: IUserIdentityStore;
    [IdentityTypes.FetchIdentityCompleted]: IUserIdentityStore;
};

export type IdentityActions = ActionMap<IdentityTypePayload>[keyof ActionMap<IdentityTypePayload>];

export const dispatchFetchIdentity = async (dispatch: React.Dispatch<any>) => {
    dispatch({
        type: IdentityTypes.FetchIdentity,
    });

    const response = await fetchSecureIdentity();

    if (response.responseStatus === ResponseStatusEnum.Error) throw new Error("Error while fetching identity data!");

    dispatchSetIdentityPreferences(dispatch, response.identity?.preferences);
    dispatchSetCompletedOnboarding(dispatch, response.identity?.completedOnboardings ?? []);
    dispatchSetNotificationPreferences(
        dispatch,
        response.identity?.pushNotificationPreferences ?? { f123: false, friendRequests: false }
    );

    dispatch({
        type: IdentityTypes.FetchIdentityCompleted,
        payload: {
            displayName: response.identity?.displayName,
            ssid: response.identity?.ssid,
            isAuthenticated: response.identity?.isAuthenticated,
            isNewProfile: response.identity?.isNewProfile,
            isTermsAccepted: response.identity?.isTermsAccepted,
            countryCode: response.identity.countryCode,
        },
    });

    return response.responseStatus;
};

export const identityReducer = (state: IUserIdentityStore, action: IdentityActions): IUserIdentityStore => {
    switch (action.type) {
        case IdentityTypes.FetchIdentity:
            return {
                ...state,
                loading: true,
            };
        case IdentityTypes.FetchIdentityCompleted:
            return {
                ...action.payload,
                loading: false,
                hasFetched: true,
            };
        default:
            return state;
    }
};
