import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import RouterLink from "common/components/links/RouterLink";
import { routingUrls } from "settings";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontWeight: "bold",
        },
    })
);

interface IProps {
    displayName: string;
    ssid: string;
    onClick?: () => void;
}

export default function DisplayNameLink(props: IProps) {
    const { displayName, ssid } = props;
    const classes = useStyles();

    const friendProfileLink = `${routingUrls.profile}/${ssid}`;

    return (
        <RouterLink className={classes.root} to={friendProfileLink} onClick={props.onClick} underline="hover">
            {displayName}
        </RouterLink>
    );
}
