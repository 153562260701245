import { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import NotificationsContent from "common/components/notifications/NotificationsContent";
import PageSection from "containers/layout/PageSection";
import NotificationsActions from "common/components/notifications/NotificationsActions";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import { dispatchMarkNewNotificationsAsRead } from "contexts/notification/actions/NotificationActions";
import { Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ITheme } from "@material-ui/core";

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            flex: 1,
            color: theme.palette.text.secondary,
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: theme.palette.text.primary,
            borderBottom: `2px solid ${theme.palette.info.light}`,
            marginBottom: theme.spacing(1),
        },
        popoverPaper: {
            width: "100%",
            height: "100%",
            top: 0 + theme.styles.cssImportant,
            left: 0 + theme.styles.cssImportant,
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: 0,
        },
        closeIcon: {
            color: theme.palette.text.secondary,
        },
    })
);

interface IProps {
    open: boolean;
}

export default function NotificationsPage({ open }: IProps) {
    const classes = useStyles();
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(true);
    const notificationContext = useNotificationContext();

    const markAsRead = () => {
        dispatchMarkNewNotificationsAsRead(
            notificationContext.dispatch,
            notificationContext.state.newNotificationStore.notifications,
            notificationContext.state.oldNotificationStore.notifications
        );
    };

    useEffect(() => {
        // if(notificationContext.state.notificationStore.openTray || open) {
        //     setIsOpen(!isOpen)
        // } else {
        //     setIsOpen(false);
        // }
        setIsOpen(!isOpen);
    }, [open, notificationContext.state.notificationStore.openTray]);

    return (
        <Popover
            open={isOpen}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            classes={{
                paper: classes.popoverPaper,
            }}
            style={{ top: 0, left: 0 }}
            onExited={() => {
                markAsRead();
                // history.goBack();
            }}
        >
            <div className={classes.root}>
                <PageSection>
                    <div className={classes.header}>
                        <IconButton className={classes.closeIcon} onClick={() => setIsOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5" align="center">
                            Notification centre
                        </Typography>
                        <NotificationsActions />
                    </div>
                    <NotificationsContent disableActions />
                </PageSection>
            </div>
        </Popover>
    );
}
