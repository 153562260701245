enum StorageKeyEnum {
    GameLibrary = 1,
    ProfilePictures = 2,
    CoverPhotos = 3,
    Countries = 4,
    Platforms = 5,
    PrivacyLevels = 6,
    RssFeed = 7,
    NewsFeed = 8,
    SocialFeed = 9,
    Advertisements = 10,
    // DirtRallyTracks = 11, // old tracks
    DirtRallyTracks = 13,
    DirtRallyVehicleClasses = 14,
    D5Tracks = 15,
    OriginalRouteDestination = "racenet_destination_link",
}

export default StorageKeyEnum;
