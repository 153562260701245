import { useRef, useState } from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import INavigationItem from "../INavigationItem";
import { Link } from "react-router-dom";
import { fontTypes, layout, styleGuide } from "theme";
import NotificationsBadge from "common/components/notifications/NotificationsBadge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import RouterLinkButton from "common/components/buttons/RouterLinkButton";
import StyledButton from "common/components/buttons/StyledButton";
import { useApplicationContext } from "contexts/application/ApplicationContext";
import { routingUrls, settings } from "settings";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import NotificationsPage from "pages/notifications/NotificationsPage";
import { useIdentityContext } from "contexts/identity/IdentityContext";
import NewTabLinkButton from "common/components/buttons/NewTabLinkButton";
import { ITheme } from "@material-ui/core";
import { logoutUser } from "../../../../apiServices/identity/IdentityApiService";
import { useConfig } from "../../../../contexts/configuration/ConfigurationContext";
import ProfileAvatar from "common/components/ProfileAvatar";
import FriendIconNoCircle from "assets/icons/FriendIconNoCircle";
import GamePadIcon from "assets/icons/navBar/GamePadIcon";
import MenuIcon from "@material-ui/icons/Menu";
import { trimEndSlash } from "utils/urlHelper";
import { isIOS, isMobile } from "react-device-detect";
import { postAnalyticsEvent } from "utils/analyticsHelper";
import MobileBottomNavSpacer from "./MobileBottomNavSpacer";
import HubV3MobileBackButton from "pages/gamesStats/layout/HubV3MobileBackButton";
import useResponsivePadding from "hooks/useResponsivePadding";
import Drawer from "common/components/uiComponents/drawers/Drawer";

export const topBarHeight = 44;

const useStyles = makeStyles((theme: ITheme) => {
    const dropdownLabelZindex = theme.zIndex.appBar;
    const iconSize = theme.spacing(5);

    return createStyles({
        appBar: {
            backgroundColor: styleGuide.color.richBlack,
            height: topBarHeight,
            padding: "0 8px",
        },
        title: {
            ...fontTypes.bold,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        topToolbar: {
            height: topBarHeight,
            minHeight: topBarHeight,
            display: "flex",
            justifyContent: "space-between",
        },
        bottomToolbar: {
            height: layout.toolBarHeight,
            minHeight: layout.toolBarHeight,
            display: "flex",
            justifyContent: "space-around",
        },
        iconButton: {
            height: iconSize,
            width: iconSize,
        },
        badge: {
            top: theme.spacing() / 2,
        },
        bottomNav: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: dropdownLabelZindex,
            backgroundColor: styleGuide.color.richBlack,
        },
        buttonContainer: {
            flex: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: isIOS && isMobile ? "4px 0" : "8px 0",
            color: theme.sidebar.color,
        },
        selected: {
            // ...theme.styles.navSelected,
        },
        bottomItem: {
            display: "flex",
            height: 40,
            width: 40,
            minWidth: "unset",
            justifyContent: "center",
            alignItems: "center",
            color: theme.sidebar.color,
        },
        bottmItemSelected: {
            // boxShadow: `0 0 6px 0 ${theme.variants.offWhite}`,
            color: theme.variants.offWhite,
            borderRadius: "100%",
            overflow: "hidden",
        },
        popover: {
            display: "flex",
            padding: theme.spacing(),
            flexDirection: "column",
            backgroundColor: theme.sidebar.backgroundColor,
            right: 0,
        },
        versioning: {
            color: theme.sidebar.color,
            textAlign: "center",
        },
        caption: {
            lineHeight: 1,
        },
    });
});

interface IProps {
    getTitle?: (title: string) => {};
}

export default function MobileNavigation(props: IProps) {
    const classes = useStyles();
    const config = useConfig();
    const applicationContext = useApplicationContext();
    const userAvatar = useIdentityContext().state.preferences.userPreferences.profileImageUrl;
    const navbarRef = useRef(null);
    const [selected, setSelected] = useState<number>(2);
    const theme = useTheme();
    const responsivePadding = useResponsivePadding();

    const handleClose = () => {
        setDropdownIsOpen(false);
    };

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    const handleLogout = () => {
        logoutUser().then((res) => {
            window.location.href = `${config.authUrls.logout}${trimEndSlash(settings.basePath)}${
                routingUrls.loggedOut
            }`;
        });
    };

    const buttonVariant = "transparentLight";
    const feedbackUrl = applicationContext.state.applicationStore.surveyUrl;

    const dropdownContent = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: styleGuide.spacing(1.25),
                padding: styleGuide.spacing(2),
            }}
        >
            <NewTabLinkButton href={feedbackUrl} variant={buttonVariant}>
                Feedback
            </NewTabLinkButton>
            <RouterLinkButton variant={buttonVariant} onClick={handleClose} to={routingUrls.preferences}>
                Settings
            </RouterLinkButton>
            <RouterLinkButton variant={buttonVariant} onClick={handleClose} to={routingUrls.releaseNotes}>
                Release notes
            </RouterLinkButton>
            <RouterLinkButton variant={buttonVariant} onClick={handleClose} to={routingUrls.faq}>
                FAQ
            </RouterLinkButton>
            <StyledButton variant={buttonVariant} onClick={handleLogout}>
                Log out
            </StyledButton>
            <Typography
                variant="body2"
                className={classes.versioning}
            >{`v${applicationContext.state.applicationStore.version.version}`}</Typography>
        </div>
    );

    const [openNotification, setOpenNotification] = useState(false);

    const toggleNotification = () => {
        postAnalyticsEvent("notification.click");
        setOpenNotification(!openNotification);
    };

    const toggleDropdown = () => {
        setDropdownIsOpen(!dropdownIsOpen);
        // setSelected(mobileNavigationItems.length + 1);
    };

    const mobileNavigationItems: INavigationItem[] = [
        {
            icon: (
                <GamePadIcon
                    style={{
                        color: selected === 0 ? "#FF4747" : theme.palette.primary.contrastText,
                        fontSize: "1.8rem",
                    }}
                />
            ),
            href: routingUrls.games,
            matchExactPath: true,
        },
        {
            icon: (
                <FriendIconNoCircle
                    style={{
                        color: selected === 1 ? "#FF4747" : theme.palette.primary.contrastText,
                        fontSize: "1.8rem",
                    }}
                />
            ),
            href: routingUrls.friends,
            matchExactPath: true,
        },
        {
            icon: (
                <Button onClick={toggleNotification} style={{ padding: 0 }} className={classes.bottomItem}>
                    <NotificationsBadge variant="secondary" classes={{ badge: classes.badge }}>
                        <NotificationsIcon
                            style={{
                                color: theme.palette.primary.contrastText,
                                fontSize: "1.8rem",
                            }}
                        />
                    </NotificationsBadge>
                </Button>
            ),
            href: "#",
        },
        {
            icon: (
                <ProfileAvatar
                    style={{
                        height: "85%",
                        maxWidth: "85%",
                        border:
                            selected === 3
                                ? `solid 2px ${theme.palette.info.main}`
                                : `solid 2px ${theme.palette.primary.contrastText}`,
                    }}
                    imageSrc={userAvatar}
                />
            ),
            href: routingUrls.profile,
            matchExactPath: true,
        },
        {
            icon: (
                <Button onClick={toggleDropdown} style={{ padding: 0 }} className={classes.bottomItem}>
                    <MenuIcon
                        style={{
                            color: selected === 4 ? "#FF4747" : theme.palette.primary.contrastText,
                            fontSize: "1.8rem",
                        }}
                    />
                </Button>
            ),
            href: "#",
            matchExactPath: false,
        },
        // {
        //     icon: (
        //         <NewsFeedIcon
        //             style={{
        //                 width: "100%",
        //                 height: "100%",
        //                 color: selected === 3 ? "#FF4747" : theme.palette.primary.contrastText,
        //             }}
        //         />
        //     ),
        //     label: "News",
        //     href: routingUrls.newsFeed,
        //     matchExactPath: true,
        // },
    ];

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    left: 0,
                    zIndex: theme.zIndex.drawer + 1,
                    padding: `${responsivePadding * 2}px ${responsivePadding * 2}px`,
                }}
            >
                <HubV3MobileBackButton />
            </div>
            <NotificationsPage open={openNotification} />
            <div className={clsx(classes.bottomNav, "navigationitem")} ref={navbarRef}>
                <Toolbar className={classes.bottomToolbar} disableGutters>
                    {mobileNavigationItems.map((item, index) => (
                        <div className={clsx(classes.buttonContainer, { [classes.selected]: index === selected })}>
                            <Link
                                onClick={() => setSelected((prevState) => (index === 2 ? prevState : index))}
                                to={item.href}
                                className={classes.bottomItem}
                                style={{
                                    padding: item.label === "Profile" ? 1 : 2,
                                    marginTop: item.label === "Profile" ? 1 : "unset",
                                }}
                            >
                                {item.icon}
                            </Link>
                            {item.label && (
                                <Typography
                                    className={classes.caption}
                                    style={index === selected ? { color: theme.palette.info.main } : {}}
                                    variant="caption"
                                >
                                    {item.label}
                                </Typography>
                            )}
                        </div>
                    ))}
                </Toolbar>
                <MobileBottomNavSpacer />
            </div>
            <Drawer
                // loader={loader}
                open={dropdownIsOpen}
                isMobile={isMobile}
                anchor={"bottom"}
                setOpen={setDropdownIsOpen}
                handleClose={handleClose}
            >
                {dropdownContent}
            </Drawer>
        </>
    );
}
