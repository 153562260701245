import Typography from "@material-ui/core/Typography";
import Img from "common/components/img/Img";
import { forwardRef } from "react";
import { styleGuide } from "theme";

interface IProps {
    isMobile: boolean;
    cdnPath: string;
    playStore: string;
    iOsUrl: string;
    description: string;
    id: string;
}

const SectionRacenetApp = forwardRef(({ isMobile, cdnPath, iOsUrl, playStore, description, id }: IProps, ref: any) => {
    const handleRedirectToPlayStore = () => {
        window.open(playStore, "_blank");
    };

    const handleRedirectToAppStore = () => {
        window.open(iOsUrl, "_blank");
    };

    return (
        <div
            ref={ref}
            style={{
                display: "flex",
                gap: styleGuide.spacing(1),
                width: "100%",
                padding: styleGuide.spacing(2),
                backgroundColor: styleGuide.color.richGrey,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                    gap: styleGuide.spacing(isMobile ? 2 : 1),
                    maxWidth: 900,
                    padding: isMobile ? `${styleGuide.spacing(1)}px 0` : `${styleGuide.spacing(2)}px 0`,
                    width: "100%",
                }}
            >
                {isMobile && (
                    <Typography
                        style={{
                            ...styleGuide.typography.subHeading,
                            textAlign: "center",
                        }}
                    >
                        {description}
                    </Typography>
                )}
                {!isMobile && (
                    <Typography
                        style={{
                            ...styleGuide.typography.subHeading,
                            fontWeight: styleGuide.fontTypes.regular.fontWeight,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {description}
                    </Typography>
                )}
                <div
                    style={{
                        display: "flex",
                        gap: styleGuide.spacing(2),
                        justifyContent: isMobile ? "center" : "end",
                        maxWidth: 350,
                        justifySelf: isMobile ? "center" : "unset",
                        width: "100%",
                    }}
                >
                    <Img
                        width={301}
                        height={101}
                        src={`${cdnPath}/app-store-badge.png`}
                        style={{ width: "100%", cursor: "pointer" }}
                        disableLazyLoad
                        onClick={handleRedirectToAppStore}
                    />
                    <Img
                        width={340}
                        height={101}
                        src={`${cdnPath}/google-play-badge.png`}
                        style={{ width: isMobile ? "113%" : "112%", cursor: "pointer" }}
                        disableLazyLoad
                        onClick={handleRedirectToPlayStore}
                    />
                </div>
            </div>
        </div>
    );
});

export default SectionRacenetApp;
