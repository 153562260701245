import { Dispatch, SetStateAction, useState } from "react";

function useLocalStorage<T>(key: string, initialValue: T) {
    type SetValue<T> = Dispatch<SetStateAction<T>>;

    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (e) {
            console.log("error localstorage", e);
            return initialValue;
        }
    });

    const setValue: SetValue<T> = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (e) {
            console.log("localStorage set values", e);
        }
    };
    return [storedValue, setValue];
}

export default useLocalStorage;
