import { makeStyles, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import useTrainTracks from "hooks/useTrainTracks";
import { ITrainTracks } from "hooks/useTrainTracks/TrainTrackContext";
import NotificationsContent from "common/components/notifications/NotificationsContent";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import { customScrollbar } from "GlobalCss";
import { dispatchMarkNewNotificationsAsRead } from "contexts/notification/actions/NotificationActions";
import { ITheme } from "@material-ui/core";

const useStyles = (trainTracks: ITrainTracks) =>
    makeStyles((theme: ITheme) => {
        const arrowSize = 12;
        const paperBackgroundColor = theme.palette.background.paper;

        const padding = trainTracks[1].padding;

        return createStyles({
            paperRoot: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: paperBackgroundColor,
                borderRadius: trainTracks[1].borderRadius,
                padding: padding,
                paddingRight: padding / 2,
                [theme.breakpoints.up("xs")]: {
                    marginRight: "24px",
                },
                overflow: "unset",
                marginTop: arrowSize + theme.spacing(1),
                color: theme.variants.grey,
            },
            //@ts-ignore
            notifications: {
                width: 450,
                overflowY: "auto",
                maxHeight: "40vh",
                paddingRight: padding / 2,
                ...customScrollbar(theme),
            },
            notificationTopArrow: {
                position: "absolute",
                top: -arrowSize,
                left: "calc(50% - 12px)",
                width: 0,
                height: 0,
                borderLeft: `${arrowSize}px solid transparent`,
                borderRight: `${arrowSize}px solid transparent`,
                borderBottom: `${arrowSize}px solid ${paperBackgroundColor}`,
            },
        });
    });

interface IProps {
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
}

export default function NotificationsPopover({ open, anchorEl, onClose }: IProps) {
    const trainTracks = useTrainTracks();
    const notificationContext = useNotificationContext();

    const classes = useStyles(trainTracks)();

    const markAsRead = () => {
        dispatchMarkNewNotificationsAsRead(
            notificationContext.dispatch,
            notificationContext.state.newNotificationStore.notifications,
            notificationContext.state.oldNotificationStore.notifications
        );
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
                onClose();
                markAsRead();
            }}
            // onExited={markAsRead} *** deprecated ***
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            classes={{
                paper: classes.paperRoot,
            }}
            disableScrollLock
        >
            <div className={classes.notificationTopArrow} />

            <div className={classes.notifications}>
                <NotificationsContent />
            </div>
        </Popover>
    );
}
