import axios from "axios";
import { apiUrls } from "settings";
import { trimEndSlash } from "utils/urlHelper";
import IPlatformResponse from "apiServices/preferences/responses/IPlatformResponse";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import QueryParameter, { constructQueryParameterString } from "apiServices/QueryParameter";

export const fetchCoverPhotos = async (): Promise<string[]> => {
    const response = await axios.get<string[]>(apiUrls.preferences.getCoverPhotosPictures);

    return response.data;
};

export const saveCoverPhoto = async (url: string) => {
    await axios.post(apiUrls.preferences.postCoverPhoto, { url: url });
};

export const fetchProviders = async (): Promise<IPlatformResponse[]> => {
    const response = await axios.get<IPlatformResponse[]>(trimEndSlash(apiUrls.preferences.getPlatforms));

    return response.data;
};

export const fetchProfilePictureForUser = async (ssid: string): Promise<string> => {
    const queryParams: QueryParameter[] = [new QueryParameter("ssid", ssid)];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get<{ ssid: string; profileImageUrl: string }>(
        `${apiUrls.preferences.getProfilePicture}${queryParamString}`
    );

    if (response.status === ResponseStatusEnum.NoContent) return "";

    return response.data.profileImageUrl ?? "";
};
