import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export interface IStyledCircularProgressProps extends CircularProgressProps {}

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            color: theme.palette.info.main,
        },
    });
});

export default function StyledCircularProgress(props: IStyledCircularProgressProps) {
    const classes = useStyles();

    return <CircularProgress classes={{ root: classes.root }} {...props} />;
}
