import { ReactNode, useEffect, useState } from "react";
import { useCookieContext } from "../cookieConsent/CookieContext";
import Typography from "@material-ui/core/Typography";
import Spacer from "../Spacer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FriendsIcon from "assets/icons/profileStats/FriendsIcon";
import TrophyIcon from "assets/icons/redbull/TrophyIcon";
import { useIdentityContext } from "contexts/identity/IdentityContext";
import { IStyles, styleGuide } from "theme";
import { useApplicationContext } from "contexts/application/ApplicationContext";
import { trimEndSlash } from "utils/urlHelper";
import { cdnPaths } from "settings";
import Img from "../img/Img";
import Popover from "../popover/Popover";
import playStore from "assets/images/appStore/playStore.png";
import appStore from "assets/images/appStore/appStore.png";
import useBreakpoint from "hooks/useBreakpoint";
import ProgressIcon from "assets/icons/onboarding/ProgressIcon";
import { postOnboarding } from "apiServices/identity/IdentityApiService";
import { dispatchAddCompletedOnboarding } from "contexts/identity/actions/OnboardingActions";
import { ScrollItems, useScrollContext } from "contexts/scroll/ScrollContext";

const currentOnboarding = "Racenet_mobile_app";

interface IInfo {
    icon: ReactNode;
    text: string;
}

export default () => {
    //Cookie context tells us if the cookie banner is already opened or not in which case
    //we need to wait for this one to be closed in order to open the onboarding popup
    const cookieContext = useCookieContext();
    const identityContext = useIdentityContext();
    const applicationContext = useApplicationContext();
    const [open, setOpen] = useState<boolean>(
        identityContext.state.completedOnboardings.find((o) => o === currentOnboarding) === undefined
    );
    const md = useBreakpoint(860, "down");
    const { handleInitialLoad, handleDisableScroll } = useScrollContext();

    const styles: IStyles = {
        icon: {
            color: styleGuide.color.white,
            height: 37,
            width: 37,
        },
        contentContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
            position: "relative",
            // marginTop: -styleGuide.spacing(4),
            height: "100%",
        },
        background: {
            position: "absolute",
            left: -styleGuide.spacing(5),
            right: -styleGuide.spacing(5),
            top: 100,
            zIndex: 0,
        },
        list: {
            listStyle: "none",
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "column",
            gap: styleGuide.spacing(3),
            paddingTop: styleGuide.spacing(2),
        },
        listItem: {
            display: "flex",
            gap: styleGuide.spacing(2),
            alignItems: "center",
            height: "max-content",
        },
        qr: {
            width: 130,
            height: "auto",
        },
        qrContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: styleGuide.spacing(2),
            padding: styleGuide.spacing(2),
            backgroundColor: styleGuide.color.richBlack,
            borderRadius: styleGuide.borderRadius,
        },
        mockImg: {
            width: 570,
            height: "auto",
            zIndex: 2,
            minHeight: 342.8,
            flex: 1,
        },
        swoosh: {
            height: 170,
            position: "absolute",
            top: md ? 100 : 120,
            right: 0,
            left: 0,
            zIndex: -1,
        },
        title: {
            ...styleGuide.typography.title,
            marginTop: -styleGuide.spacing(2),
        },
        row: {
            display: "flex",
            gap: styleGuide.spacing(3),
            padding: `${styleGuide.spacing(2)}px ${styleGuide.spacing(4)}px`,
        },
    };

    const cdnPathQrCodes = `${trimEndSlash(applicationContext.state.applicationStore.cdnUrl)}/${trimEndSlash(
        cdnPaths.qrCodes
    )}`;
    const playStoreUrl = applicationContext.state.applicationStore.playStoreUrl;
    const iOSUrl = applicationContext.state.applicationStore.iosUrl;

    const handleClose = async () => {
        setOpen(false);

        await postOnboarding(currentOnboarding);
        dispatchAddCompletedOnboarding(identityContext.dispatch, currentOnboarding);
    };

    useEffect(() => {
        if (open && !cookieContext.cookieBannerIsOpen) {
            handleInitialLoad(true);
            handleDisableScroll(ScrollItems.Popover);
        }
    }, [open]);

    const openState = open && !cookieContext.cookieBannerIsOpen;

    const info: IInfo[] = [
        {
            icon: <FriendsIcon style={styles.icon} />,
            text: "Connect with your friends, comparing your stats & laps on the go.",
        },
        {
            icon: <TrophyIcon style={styles.icon} />,
            text: "Receive real time notifications for new events and competitions.",
        },
        {
            icon: <ProgressIcon style={styles.icon} />,
            text: "Analyse your racing telemetry wherever you are.",
        },
        {
            icon: <NotificationsIcon style={styles.icon} />,
            text: "Never miss a F1® 23 League race again with our range of League Racing push notifications.",
        },
    ];

    const qrContainer = (
        <div style={styles.qrContainer}>
            <Img src={`${cdnPathQrCodes}/qr.png`} alt="qr-code" height={130} width={130} style={styles.qr} />
            <span style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <a href={iOSUrl} target="_blank" rel="noopener noreferrer">
                    <Img style={{ width: 130 }} src={appStore} alt="app-store" height={167} width={498} />
                </a>
                <Spacer size={2} />
                <a href={playStoreUrl} target="_blank" rel="noopener noreferrer">
                    <Img style={{ width: 130 }} src={playStore} alt="app-store" height={207.3} width={700} />
                </a>
            </span>
        </div>
    );

    const content = (
        <div style={styles.contentContainer}>
            <Img
                style={styles.swoosh}
                src={`${cdnPathQrCodes}/bg_swoosh.png`}
                alt="bg-swoosh"
                height={200}
                width={750}
            />
            <Spacer />
            <Img
                src={`${cdnPathQrCodes}/app_mock.png`}
                alt="app-mock"
                height={264}
                width={385}
                style={styles.mockImg}
            />
            <Spacer size={2} />
            <Typography align="center" style={styles.title}>
                Introducing the EA Racenet App
            </Typography>
            <Spacer size={0.5} />
            <Typography style={styleGuide.typography.heading3}>
                Our EA Racenet app is now here on iOS and Android.
            </Typography>
            <Spacer />
            <div style={styles.row}>
                <ul style={styles.list}>
                    {info.map((item: IInfo, index: number) => {
                        return (
                            <li key={`${index}_info_item`} style={styles.listItem}>
                                {item.icon}
                                <Typography style={styleGuide.typography.copyText}>{item.text}</Typography>
                            </li>
                        );
                    })}
                </ul>
                {qrContainer}
            </div>
        </div>
    );

    return (
        <Popover isOpen={openState} onClose={handleClose} maxWidth={776}>
            {content}
        </Popover>
    );
};
