import React, { ReactNode } from "react";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { calculateInnerBorderRadius, ICalculateInnerBorderRadiusProps } from "./calculateBorderRadius";
import TrainTrackContext, { ITrainTrack } from "./TrainTrackContext";

type BreakpointObject<T> = {
    [key in Breakpoint]: T;
};

interface IProps {
    children: ReactNode;
}

export default React.memo(function TrainTrackProvider(props: IProps) {
    const { children } = props;
    const theme = useTheme();

    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    const md = useMediaQuery(theme.breakpoints.only("md"));
    const lg = useMediaQuery(theme.breakpoints.only("lg"));
    const xl = useMediaQuery(theme.breakpoints.only("xl"));

    const layer0: BreakpointObject<ITrainTrack> = {
        xs: {
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(1),
        },
        sm: {
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(2),
        },
        md: {
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(3),
        },
        lg: {
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(4),
        },
        xl: {
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(5),
        },
    };

    const layer1Padding = {
        xs: theme.spacing(1),
        sm: theme.spacing(2),
        md: theme.spacing(2),
        lg: theme.spacing(2),
        xl: theme.spacing(2),
    };
    const layer1MinBorderRadius = {
        xs: theme.spacing(0.5),
        sm: theme.spacing(0.5),
        md: theme.spacing(0.5),
        lg: theme.spacing(0.5),
        xl: theme.spacing(0.5),
    };
    const layer1 = getNextLayer(layer0, layer1Padding, layer1MinBorderRadius);

    const layer2Padding = {
        xs: theme.spacing(1),
        sm: theme.spacing(1),
        md: theme.spacing(1),
        lg: theme.spacing(1),
        xl: theme.spacing(1),
    };
    const layer2MinBorderRadius = {
        xs: theme.spacing(1),
        sm: theme.spacing(1),
        md: theme.spacing(1),
        lg: theme.spacing(1),
        xl: theme.spacing(1),
    };
    const layer2 = getNextLayer(layer1, layer2Padding, layer2MinBorderRadius);

    if (xs) {
        return (
            <TrainTrackContext.Provider
                value={{
                    "0": layer0.xs,
                    "1": layer1.xs,
                    "2": layer2.xs,
                }}
            >
                {children}
            </TrainTrackContext.Provider>
        );
    }
    if (sm) {
        return (
            <TrainTrackContext.Provider
                value={{
                    "0": layer0.sm,
                    "1": layer1.sm,
                    "2": layer2.sm,
                }}
            >
                {children}
            </TrainTrackContext.Provider>
        );
    }
    if (md) {
        return (
            <TrainTrackContext.Provider
                value={{
                    "0": layer0.md,
                    "1": layer1.md,
                    "2": layer2.md,
                }}
            >
                {children}
            </TrainTrackContext.Provider>
        );
    }
    if (lg) {
        return (
            <TrainTrackContext.Provider
                value={{
                    "0": layer0.lg,
                    "1": layer1.lg,
                    "2": layer2.lg,
                }}
            >
                {children}
            </TrainTrackContext.Provider>
        );
    }
    if (xl) {
        return (
            <TrainTrackContext.Provider
                value={{
                    "0": layer0.xl,
                    "1": layer1.xl,
                    "2": layer2.xl,
                }}
            >
                {children}
            </TrainTrackContext.Provider>
        );
    }

    return (
        <TrainTrackContext.Provider
            value={{
                "0": layer0.xl,
                "1": layer1.xl,
                "2": layer2.xl,
            }}
        >
            {children}
        </TrainTrackContext.Provider>
    );
});

function getNextLayer(
    previousLayer: BreakpointObject<ITrainTrack>,
    padding: BreakpointObject<number>,
    minBorderRadius: BreakpointObject<number>
): BreakpointObject<ITrainTrack> {
    return {
        xs: {
            borderRadius: getNextLayerBorderRadius(
                {
                    outerBorderRadius: previousLayer.xs.borderRadius,
                    padding: previousLayer.xs.padding,
                },
                minBorderRadius.xs
            ),
            padding: padding.xs,
        },
        sm: {
            borderRadius: getNextLayerBorderRadius(
                {
                    outerBorderRadius: previousLayer.sm.borderRadius,
                    padding: previousLayer.sm.padding,
                },
                minBorderRadius.sm
            ),
            padding: padding.sm,
        },
        md: {
            borderRadius: getNextLayerBorderRadius(
                {
                    outerBorderRadius: previousLayer.md.borderRadius,
                    padding: previousLayer.md.padding,
                },
                minBorderRadius.md
            ),
            padding: padding.md,
        },
        lg: {
            borderRadius: getNextLayerBorderRadius(
                {
                    outerBorderRadius: previousLayer.lg.borderRadius,
                    padding: previousLayer.lg.padding,
                },
                minBorderRadius.lg
            ),
            padding: padding.lg,
        },
        xl: {
            borderRadius: getNextLayerBorderRadius(
                {
                    outerBorderRadius: previousLayer.xl.borderRadius,
                    padding: previousLayer.xl.padding,
                },
                minBorderRadius.xl
            ),
            padding: padding.xl,
        },
    };
}

function getNextLayerBorderRadius(props: ICalculateInnerBorderRadiusProps, minBorderRadius = 4) {
    const calculatedBorderRadius = calculateInnerBorderRadius(props);

    return calculatedBorderRadius < minBorderRadius ? minBorderRadius : calculatedBorderRadius;
}
