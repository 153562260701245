import axios from "axios";
import { apiUrls } from "settings";
import QueryParameter, { constructQueryParameterString } from "apiServices/QueryParameter";
import INotificationRequests from "./requests/INotificationsRequest";
import INotificationsResponse from "./responses/INotificationsResponse";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";

export async function getNotifications(request: INotificationRequests): Promise<INotificationsResponse> {
    const queryParams: QueryParameter[] = [
        new QueryParameter("cursor", request.cursor),
        new QueryParameter("take", request.take),
        new QueryParameter("filter", request.filter),
    ];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get<INotificationsResponse>(`${apiUrls.getNotifications}${queryParamString}`, {
        validateStatus: function (status) {
            return status <= 500;
        },
    });

    if (response.status !== ResponseStatusEnum.Ok)
        return {
            notifications: [],
            loadMore: false,
        };

    return response.data;
}

export async function markAsRead(notificationIds: string[]): Promise<void> {
    await axios.post(apiUrls.getNotifications, notificationIds);
}

export async function removeNotifications(notificationIds: string[]): Promise<void> {
    const queryParams: QueryParameter[] = [];

    notificationIds.forEach((id) => {
        queryParams.push(new QueryParameter("notificationIds", id));
    });

    const queryParamString = constructQueryParameterString(queryParams);

    await axios.delete(`${apiUrls.getNotifications}${queryParamString}`);
}
