import Button, { IButtonProps } from "./Button";

interface IProps extends IButtonProps {
    href: string;
}

const NewTabButton = ({
    variant = "regular",
    size = "standard",
    children,
    onClick,
    disabled = false,
    isMobile = false,
    customStyles,
    endIcon,
    startIcon,
    href,
    id,
}: IProps) => {
    return (
        <a
            href={href}
            style={
                {
                    // cursor: disabled || isMobile ? "default" : "pointer",
                }
            }
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button
                variant={variant}
                size={size}
                onClick={onClick ? onClick : () => null}
                isMobile={isMobile}
                disabled={disabled}
                customStyles={customStyles}
                endIcon={endIcon}
                startIcon={startIcon}
                id={id}
            >
                {children}
            </Button>
        </a>
    );
};

export default NewTabButton;
