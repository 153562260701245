import { ReactNode, useEffect, useRef } from "react";
import Portal from "./Portal";
import { IStyles, styleGuide } from "theme";
import { Transition, TransitionStatus } from "react-transition-group";
import { ScrollItems, useScrollContext } from "contexts/scroll/ScrollContext";
import { useClickOutside } from "hooks/useClickOutside";
import Typography from "@material-ui/core/Typography";
import Button from "../buttons/Button";
import NewTabButton from "../buttons/NewTabButton";

interface IProps {
    title?: string;
    children: ReactNode;
    actions?: {
        label: string;
        onClick: () => void;
        type: "primary" | "secondary";
        disabled?: boolean;
        actionType?: "button" | "externalLink";
        href?: string;
    }[];
    isMobile: boolean;
    open: boolean;
    onClose: () => void;
    loader?: boolean;
    disabled?: boolean;
    styleConfig?: {
        backgroundColor?: string;
        maxWidth?: number;
        width?: string;
        padding?: string | number;
        margin?: string | number;
        titleAlignement?: "left" | "right" | "center";
    };
}

export const rootZIndex: number = 3001;

const PortalDialog = ({ title, children, actions, isMobile, open, onClose, loader = false, styleConfig }: IProps) => {
    const node = useRef<HTMLDivElement>(null!);
    const { handleDisableScroll, handleEnableScroll, scrollItems } = useScrollContext();

    const defaultStyleConfig = {
        ...styleConfig,
        backgroundColor: styleGuide.color.richGrey,
    };

    useClickOutside(node, () => {
        if (loader) return;

        onClose();
    });

    useEffect(() => {
        if (scrollItems.includes(ScrollItems.Popover)) return;

        if (open) {
            handleDisableScroll(ScrollItems.Dialog);
        }

        if (open === false) {
            handleEnableScroll(ScrollItems.Dialog);
        }
    }, [open]);

    const styles: IStyles = {
        root: {
            ...styleGuide.popoverBackground,
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: rootZIndex + 2,
        },
        dialog: {
            backgroundColor: defaultStyleConfig.backgroundColor,
            display: "flex",
            flexDirection: "column",
            padding: defaultStyleConfig.padding ? defaultStyleConfig.padding : `${isMobile ? 56 : 40}px 24px 24px 24px`,
            maxHeight: "calc(100vh - 150px)",
            gridArea: "1/1",
            placeSelf: "center center",
            overflowX: "hidden",
            overflowY: "auto",
            width: defaultStyleConfig.width ?? "100%",
            maxWidth: defaultStyleConfig.maxWidth ?? 500,
            borderRadius: styleGuide.borderRadius,
            margin: defaultStyleConfig.margin ?? isMobile ? styleGuide.spacing(2) : 0,
        },
        title: {
            display: "flex",
            justifyContent: defaultStyleConfig.titleAlignement
                ? defaultStyleConfig.titleAlignement
                : isMobile
                ? "center"
                : "flex-start",
            paddingBottom: 24,
        },
        children: {},
        actions: {
            display: "flex",
            gap: isMobile ? 12 : 16,
            paddingTop: 56,
            justifyContent: isMobile ? "center" : "flex-end",
            flexWrap: "wrap",
            visibility: loader ? "hidden" : "initial",
            flexDirection: isMobile ? "column-reverse" : "row",
        },
    };

    const defaultStyle = {
        transition: `opacity 0.2ms ease-in-out`,
        opacity: 0,
        backdropFilter: "blur(0px)",
    };

    const transitionStyles = {
        entering: {},
        entered: scrollItems.includes(ScrollItems.Popover)
            ? { opacity: 1, transition: "opacity 0.2s ease-in" }
            : { opacity: 1, transition: "opacity 0.2s ease-in", backdropFilter: "blur(8px)" },
        exiting: { opacity: 0, backdropFilter: "unset", transition: "opacity 0.2s ease-out" },
        exited: {},
        unmounted: {},
    };

    return (
        <Portal wrapperId="portal-container">
            <Transition in={open} timeout={{ enter: 100, exit: 200 }} unmountOnExit>
                {(state: TransitionStatus) => (
                    <div
                        style={{
                            ...styles.root,
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        <div style={styles.dialog} ref={node}>
                            {title && (
                                <div style={styles.title}>
                                    <Typography style={styleGuide.typography.heading}>{title}</Typography>
                                </div>
                            )}
                            <div style={styles.children}>{children}</div>
                            {actions && (
                                <div style={styles.actions}>
                                    {actions.map((a, index) =>
                                        a.actionType === undefined || a.actionType === "button" ? (
                                            <Button
                                                key={`dialog_button_${index}`}
                                                variant={a.type === "primary" ? "regular" : "outlinedPrimary"}
                                                onClick={() => {
                                                    a.onClick();
                                                }}
                                                disabled={a.disabled}
                                                isMobile={isMobile}
                                                customStyles={isMobile ? { width: "100%" } : {}}
                                            >
                                                {a.label}
                                            </Button>
                                        ) : (
                                            <NewTabButton
                                                key={`dialog_link_${index}`}
                                                href={a.href ?? "#"}
                                                variant={a.type === "primary" ? "regular" : "outlinedPrimary"}
                                                isMobile={isMobile}
                                            >
                                                {a.label}
                                            </NewTabButton>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Transition>
        </Portal>
    );
};

export default PortalDialog;
