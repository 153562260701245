import { FormEventHandler, ReactNode, useEffect, useState } from "react";
import { ITheme, useTheme } from "@material-ui/core";
import { IStyles, styleGuide } from "theme";
import Portal from "../uiComponents/dialogs/Portal";
import { Transition, TransitionStatus } from "react-transition-group";
import CloseIcon from "assets/icons/CloseIcon";
import { useHistory } from "react-router-dom";
import useIsMobile from "hooks/useIsMobile";
import { rootZIndex } from "../dialogs/Dialog";
import classes from "./popover.module.scss";
import { ScrollItems, useScrollContext } from "contexts/scroll/ScrollContext";
import { isRaceNetMobileApp, isRaceNetTabletApp, webViewPostMessage } from "utils/webViewTools";
import { useNativeAppProps } from "contexts/theme/ThemeContext";
import { WebViewEnum } from "common/enums/WebViewEventsEnum";
import CustomScroll from "../scroll/CustomScroll";
import Spacer from "../Spacer";

const MAX_HEIGHT: number = 1020;

interface IProps {
    headerChildren?: ReactNode;
    headerChildrenAction?: ReactNode;
    contentHeaderChildren?: ReactNode;
    children: ReactNode;
    actionChildren?: ReactNode[];
    isOpen: boolean;
    setOpen?: (open: boolean) => void;
    onClose?: () => void;
    heightOverride?: string;
    maxWidth?: number;
    title?: string;
    scrollConfig?: {
        disableButtons?: boolean;
        currentStep?: number;
        disableShadow?: boolean;
        customHeight?: string;
        mobileShadowHeight?: string;
        shadowHeight?: string;
        color?: string;
        gradientHeight?: string;
    };
    onboarding?: ReactNode;
    onSubmit?: FormEventHandler<HTMLFormElement>;
    padding?: number;
    noPaddingTop?: boolean;
    wrapperId?: string;
}

const Popover = ({
    headerChildren,
    headerChildrenAction,
    children,
    isOpen,
    setOpen,
    onClose,
    heightOverride,
    maxWidth = 600,
    onboarding,
    title,
    contentHeaderChildren,
    actionChildren,
    scrollConfig,
    onSubmit,
    padding,
    noPaddingTop = false,
    wrapperId = "popover-container",
}: IProps) => {
    const theme: ITheme = useTheme();
    const nativeAppProps = useNativeAppProps();
    const nativeAppReturnValue = nativeAppProps.appVersion ? true : false;
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const history = useHistory();
    const isMobile = useIsMobile();

    const { handleDisableScroll, handleEnableScroll } = useScrollContext();
    const [hover, setHover] = useState<boolean>(false);

    const styles: IStyles = {
        root: {
            padding: 0,
            backgroundColor: styleGuide.color.grey,
            position: "fixed",
            overflow: isFirefox && !isMobile ? "unset" : "hidden",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            zIndex: rootZIndex + 1,
        },
        desktopRoot: {
            position: "fixed",
            overflow: isFirefox && !isMobile ? "unset" : "hidden",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: rootZIndex + 1,
            display: "grid",
        },
        headerContent: {
            display: "flex",
            justifyContent: isMobile && headerChildren ? "space-between" : isMobile ? "flex-end" : "center",
            gap: styleGuide.spacing(),
            alignItems: "center",
            flex: 1,
            position: "relative",
        },
        backgroundEffect: {
            gridArea: "1/1",
            backgroundColor: theme.styles.popoverBackground,
        },
        desktopContainer: {
            gridArea: "1/1",
            placeSelf: "center",
            width: "100%",
            height: heightOverride ? heightOverride : "100%",
            maxHeight: isMobile ? "100%" : MAX_HEIGHT,
            minHeight: 500,
            padding: styleGuide.spacing(4),
            overflow: !isMobile && isFirefox ? "hidden" : null,
        },
        desktopContent: {
            backgroundColor: styleGuide.color.grey,
            margin: "0 auto",
            maxWidth: maxWidth,
            height: "100%",
            borderRadius: styleGuide.borderRadius,
            padding: padding ?? styleGuide.spacing(5),
            boxShadow: `1px 1px 5px #000`,
            display: "flex",
            flexDirection: "column",
            overflow: !isMobile && isFirefox ? "hidden" : null,
        },
        desktopScrollContainer: {
            padding: 0,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: !isMobile && isFirefox ? null : "relative",
            zIndex: 1,
        },
        actionChildren: {
            backgroundColor: styleGuide.color.grey,
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row",
            gap: styleGuide.spacing(2),
            justifyContent: "flex-end",
            paddingTop: styleGuide.spacing(2),
            flex: 1,
            zIndex: 3,
        },
        closeButton: {
            position: isMobile ? "initial" : "absolute",
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isMobile ? 44 : 30,
            width: isMobile ? 44 : 30,
            fontWeight: "bold",
            color: hover ? styleGuide.color.coral : styleGuide.color.white,
            backgroundColor: hover ? styleGuide.color.white : styleGuide.color.transparent,
            border: `2px solid ${styleGuide.color.coral}`,
            borderRadius: styleGuide.borderRadius,
            cursor: "pointer",
            transition: theme.styles.hoverEaseInOut + theme.styles.cssImportant,
            zIndex: 3,
        },
        headerChildrenContainer: {
            display: "flex",
            flexDirection: "column",
        },
        mobileContent: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: styleGuide.spacing(2),
            paddingTop:
                (isRaceNetMobileApp || isRaceNetTabletApp) && nativeAppProps.topInset
                    ? noPaddingTop
                        ? nativeAppProps.topInset - 38
                        : nativeAppProps.topInset
                    : styleGuide.spacing(2),
        },
        form: {
            overflowY: "visible",
            overflowX: "hidden",
            height: "100%",
            display: isMobile ? "block" : "grid",
        },
    };

    const defaultStyle = {
        transition: `opacity 0.2ms ease-in-out`,
        opacity: 0,
        backdropFilter: "blur(0px)",
    };

    const transitionStyles = {
        entering: {},
        entered: {
            opacity: 1,
            backdropFilter: "blur(8px)",
            transition: "opacity 0.2s ease-in",
        },
        exiting: {
            opacity: 0,
            backdropFilter: "unset",
            transition: "opacity 0.2s ease-out",
        },
        exited: {},
        unmounted: {},
    };

    const handleOnClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setOpen && setOpen(!isOpen);
        setHover(false);

        // window.alert(nativeAppReturnValue);

        isRaceNetMobileApp && webViewPostMessage(`${WebViewEnum.hideBackButton}:${!nativeAppReturnValue}`); //false Mobile re-enable

        if (onClose) {
            onClose();
        } else history.goBack();
    };

    useEffect(() => {
        if (isOpen) {
            handleDisableScroll(ScrollItems.Popover);
            isRaceNetMobileApp && webViewPostMessage(`${WebViewEnum.hideBackButton}:${nativeAppReturnValue}`); //true Mobile re-enable
        } else {
            handleEnableScroll(ScrollItems.Popover);
        }
        return () => webViewPostMessage(`${WebViewEnum.hideBackButton}:${!nativeAppReturnValue}`);
    }, [isOpen]);

    const headerContent = (
        <div style={styles.headerContent}>
            {headerChildren && headerChildren}
            {headerChildrenAction ? (
                headerChildrenAction
            ) : (
                <button
                    style={styles.closeButton}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={(e) => handleOnClose(e)}
                >
                    <CloseIcon style={{ fontSize: "inherit" }} />
                </button>
            )}
        </div>
    );

    const titleContent = title ? (
        isMobile ? (
            <h2 style={styleGuide.typography.heading}>{title}</h2>
        ) : (
            <h2
                style={{
                    ...styleGuide.typography.title,
                    padding: `0 ${styleGuide.spacing(1)}px`,
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            >
                {title}
            </h2>
        )
    ) : undefined;

    const mobileContent = (
        <div style={styles.mobileContent}>
            {headerContent}
            <Spacer size={headerChildren ? 1 : 0} />
            {titleContent && titleContent}
            {titleContent && contentHeaderChildren && <Spacer />}
            {contentHeaderChildren && (
                <>
                    <Spacer />
                    <div style={styles.headerChildrenContainer}>{contentHeaderChildren}</div>
                </>
            )}
            <Spacer />
            <CustomScroll
                scrollConfig={scrollConfig}
                mobileShadowHeight={
                    scrollConfig?.mobileShadowHeight
                        ? scrollConfig.mobileShadowHeight
                        : actionChildren && actionChildren?.length < 2
                        ? "25%"
                        : "50%"
                }
            >
                {children}
            </CustomScroll>
            {actionChildren && actionChildren.length > 0 && (
                <div style={styles.actionChildren}>{actionChildren?.map((a) => a)}</div>
            )}
        </div>
    );

    const desktopContent = (
        <>
            <div style={styles.backgroundEffect} />
            <div style={styles.desktopContainer}>
                <span style={styles.desktopContent}>
                    <div style={styles.desktopScrollContainer}>
                        {headerContent}
                        {titleContent}
                        {contentHeaderChildren && (
                            <>
                                <Spacer size={1.5} />
                                <div style={styles.headerChildrenContainer}>{contentHeaderChildren}</div>
                            </>
                        )}
                        <Spacer />
                        <CustomScroll scrollConfig={scrollConfig}>{children}</CustomScroll>
                        {actionChildren && actionChildren.length > 0 && (
                            <span style={styles.actionChildren}>{actionChildren?.map((a) => a)}</span>
                        )}
                    </div>
                </span>
            </div>
        </>
    );

    return (
        <Portal wrapperId={wrapperId}>
            <Transition in={isOpen} timeout={{ enter: 100, exit: 200 }} unmountOnExit>
                {(state: TransitionStatus) => (
                    <>
                        <div
                            className={isMobile ? "" : classes.root}
                            style={
                                isMobile
                                    ? isMobile
                                        ? {
                                              ...styles.root,
                                              ...defaultStyle,
                                              ...transitionStyles[state],
                                              //   paddingTop: nativeAppProps.topInset && nativeAppProps.topInset - 30,
                                          }
                                        : {
                                              ...styles.desktopRoot,
                                              ...defaultStyle,
                                              ...transitionStyles[state],
                                          }
                                    : {
                                          ...styles.desktopRoot,
                                          ...defaultStyle,
                                          ...transitionStyles[state],
                                      }
                            }
                        >
                            {onboarding}
                            {onSubmit ? (
                                <form onSubmit={onSubmit} style={styles.form}>
                                    {isMobile ? mobileContent : desktopContent}
                                </form>
                            ) : isMobile ? (
                                mobileContent
                            ) : (
                                desktopContent
                            )}
                        </div>
                    </>
                )}
            </Transition>
        </Portal>
    );
};

export default Popover;
