import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function RacenetEAIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 97.958 99.254" {...props}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
                <style type="text/css"></style>
                <g>
                    <path
                        className="st0"
                        d="M4.25,43.9l3.66,3.77H5.47L1.9,43.83v3.84H0V38h4.32c1.88,0,3.23,1.27,3.23,3.01c0,1.68-1.37,2.88-3.27,2.88
		H4.25z M1.9,39.7v2.76h2.24c0.92,0,1.52-0.54,1.52-1.38c0-0.84-0.59-1.38-1.52-1.38H1.9z"
                    />
                    <path
                        className="st0"
                        d="M7.56,44.22c0-2.06,1.63-3.55,3.42-3.55c0.83,0,1.59,0.33,2.13,0.91v-0.8h1.75v6.89h-1.75v-0.8
		c-0.57,0.57-1.34,0.9-2.19,0.9C9.16,47.76,7.56,46.27,7.56,44.22z M13.14,44.22c0-1.17-0.84-1.99-1.92-1.99
		c-1.06,0-1.92,0.81-1.92,1.99c0,1.17,0.86,1.99,1.92,1.99C12.3,46.2,13.14,45.39,13.14,44.22z"
                    />
                    <path
                        className="st0"
                        d="M15.77,44.23c0-2.08,1.61-3.59,3.6-3.59c1.71,0,3.15,1.17,3.48,2.84h-1.78c-0.28-0.75-0.92-1.21-1.7-1.21
		c-1.04,0-1.86,0.8-1.86,1.99c0,1.16,0.83,1.97,1.86,1.97c0.77,0,1.42-0.47,1.7-1.21h1.78c-0.33,1.67-1.77,2.79-3.48,2.79
		C17.39,47.8,15.77,46.3,15.77,44.23z"
                    />
                    <path
                        className="st0"
                        d="M27.17,47.8c-2.07,0-3.75-1.52-3.75-3.59c0-2.08,1.6-3.59,3.57-3.59c1.71,0,3.59,1.26,3.59,3.91
		c0,0.08-0.01,0.17-0.01,0.26h-5.31c0.23,0.87,1.01,1.45,1.95,1.45c0.54,0,1.04-0.21,1.32-0.63h1.95
		C29.95,46.93,28.71,47.8,27.17,47.8z M25.27,43.54h3.41c-0.15-0.81-0.87-1.38-1.68-1.38C26.18,42.16,25.5,42.7,25.27,43.54z"
                    />
                    <path
                        className="st0"
                        d="M32.74,42.03c0.44-0.86,1.28-1.23,2.15-1.23c1.61,0,2.5,1.16,2.5,2.64v4.22h-1.16v-4.08
		c0-0.99-0.69-1.73-1.67-1.73c-0.98,0-1.82,0.75-1.82,1.7v4.11h-1.16v-6.72h1.16V42.03z"
                    />
                    <path
                        className="st0"
                        d="M41.91,47.79c-1.95,0-3.46-1.48-3.46-3.49s1.49-3.49,3.4-3.49c1.85,0,3.34,1.39,3.34,3.8v0.11h-5.55
		c0.18,1.21,1.12,2.04,2.28,2.04c0.73,0,1.37-0.37,1.78-0.94h1.3C44.47,47.02,43.29,47.79,41.91,47.79z M39.66,43.72h4.31
		c-0.23-1.12-1.09-1.86-2.14-1.86C40.78,41.86,39.9,42.6,39.66,43.72z"
                    />
                    <path
                        className="st0"
                        d="M46.91,41.94h-1.66v-0.99h1.66v-1.35l1.16-0.36v1.71H50v0.99h-1.93v4c0,0.46,0.25,0.7,0.79,0.7H50v1.02h-1.42
		c-1.09,0-1.67-0.58-1.67-1.64V41.94z"
                    />
                </g>
                <path
                    className="st0"
                    d="M25,2C16.18,2,9.04,9.15,9.04,17.96S16.18,33.93,25,33.93s15.96-7.15,15.96-15.96S33.82,2,25,2z M17.27,13.84
	c0.42-0.66,0.83-1.33,1.24-1.99c0.06-0.09,0.12-0.12,0.22-0.12c2.96,0,5.91,0,8.86,0c0.04,0,0.1,0,0.17,0
	c-0.03,0.06-0.05,0.1-0.08,0.13c-0.42,0.67-0.84,1.33-1.25,2.01c-0.05,0.08-0.11,0.11-0.2,0.11c-2.96,0-5.92,0-8.88,0
	c-0.04,0-0.1-0.01-0.16-0.01C17.23,13.92,17.25,13.88,17.27,13.84z M34.02,22.87c-0.1,0-0.16-0.04-0.21-0.12
	c-0.41-0.66-0.82-1.31-1.23-1.97c-0.06-0.1-0.12-0.13-0.24-0.13c-1.24,0.01-2.48,0-3.72,0c-0.06,0-0.12-0.01-0.2-0.01
	c0.04-0.06,0.06-0.1,0.08-0.14c0.42-0.66,0.84-1.33,1.25-2c0.05-0.08,0.1-0.11,0.19-0.1c0.36,0.01,0.73,0,1.14,0
	c-0.51-0.82-1.01-1.62-1.52-2.43c-0.04,0.06-0.07,0.1-0.1,0.14c-1.38,2.2-2.76,4.41-4.13,6.62c-0.07,0.11-0.14,0.15-0.27,0.15
	c-3.73-0.01-7.46-0.01-11.19-0.01c-0.06,0-0.11,0-0.21,0c0.93-1.49,1.85-2.95,2.77-4.43c-0.68,0-1.33,0-2.02,0
	c0.03-0.06,0.05-0.11,0.07-0.15c0.42-0.67,0.84-1.34,1.25-2.01c0.04-0.08,0.1-0.11,0.19-0.11c2.89,0,5.78,0,8.67,0
	c0.04,0,0.08,0.01,0.15,0.01c-0.03,0.06-0.05,0.1-0.08,0.14c-0.41,0.66-0.82,1.32-1.23,1.98c-0.06,0.1-0.12,0.13-0.24,0.13
	c-1.32-0.01-2.64,0-3.95-0.01c-0.11,0-0.17,0.03-0.23,0.13c-0.4,0.65-0.81,1.3-1.21,1.95c-0.02,0.03-0.04,0.06-0.06,0.11
	c0.06,0,0.1,0,0.15,0c1.99,0,3.97,0,5.96,0c0.11,0,0.17-0.03,0.23-0.13c1.79-2.87,3.58-5.73,5.36-8.59
	c0.03-0.06,0.07-0.11,0.12-0.19c2.32,3.72,4.64,7.42,6.97,11.14c-0.07,0.01-0.11,0.01-0.15,0.01
	C35.59,22.87,34.81,22.87,34.02,22.87z"
                />
            </svg>
        </SvgIcon>
    );
}
