import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const TrophyIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
                <g>
                    <path
                        d="M39.13,2.24l-0.78,2.63h2.62c-1.68,6.66-3.9,9.8-7.33,10.38L37.29,0H12.71l3.64,15.25c-3.43-0.58-5.64-3.72-7.33-10.38
		h2.62l-0.78-2.63H5.72l0.36,1.6c1.56,6.98,4.14,14.12,11.46,14.15c1.85,2.65,3.4,2.82,4.76,4.35c0.34,0.38,0.51,0.88,0.51,1.39v4.4
		h-1.57c-0.67,0-1.27,0.44-1.47,1.08l-1.49,4.76c-0.16,0.52,0.22,1.05,0.77,1.05h11.94c0.54,0,0.93-0.53,0.77-1.05l-1.49-4.76
		c-0.2-0.64-0.79-1.08-1.47-1.08h-1.57v-4.4c0-0.51,0.17-1.01,0.51-1.39c1.35-1.53,2.91-1.7,4.76-4.35
		c7.32-0.03,9.9-7.16,11.46-14.15l0.36-1.6H39.13z"
                    />
                    <path d="M15.55,37.26v6.98h18.9v-6.98H15.55z M31.98,42.62H18.02v-3.74h13.95V42.62z" />
                    <polygon points="22.52,41.63 23.67,40.17 22.21,40.17 21.06,41.63 	" />
                    <polygon points="21.23,40.17 20.5,40.17 19.35,41.63 20.08,41.63 	" />
                    <rect x="12.71" y="46.56" width="24.57" height="3.44" />
                </g>
            </svg>
        </SvgIcon>
    );
};

export default TrophyIcon;
