import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import clsx from "clsx";
import CachedIcon from "@material-ui/icons/Cached";
import { ITheme } from "@material-ui/core";

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        "@keyframes spin": {
            from: { transform: "rotate(0deg)" },
            to: { transform: "rotate(-360deg)" },
        },
        rotate: {
            animation: "$spin 1s infinite linear",
        },
        buttonColor: {
            color: theme.palette.text.secondary,
        },
        disabled: {
            color: theme.palette.info.light + theme.styles.cssImportant,
        },
    })
);

interface IProps extends IconButtonProps {
    loading: boolean;
}

export default function RefreshButton({ onClick, loading, disabled = false, ...rest }: IProps) {
    const classes = useStyles();

    return (
        <IconButton
            disabled={loading || disabled}
            onClick={onClick}
            className={classes.buttonColor}
            classes={{ disabled: classes.disabled }}
            {...rest}
        >
            <CachedIcon
                className={clsx({
                    [classes.rotate]: loading,
                })}
            />
        </IconButton>
    );
}
