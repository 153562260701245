import axios, { CancelToken, AxiosResponse } from "axios";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import { IFriendResponse } from "pages/friends/IFriend";
import { apiUrls } from "settings";
import QueryParameter, { constructQueryParameterString } from "../QueryParameter";
import { validateSsid } from "../ApiServiceBase";
import { IFriendsRecommendationsResponse } from "./Responses/IFriendsRecommendationResponse";
import {
    defaultConsolidatedFriendsResponse,
    IFriendsConsolidatedResponse,
} from "./Responses/IFriendsByPlatformResponse";
import { IBulkRequestResponse } from "./Responses/IFriendsBulkRequestResponse";
import Platforms from "common/enums/PlatformsEnum";
import { IFriendsResponse } from "apiServices/common/response/IFriendsResponse";

export async function sendFriendRequest(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    return await axios.post(apiUrls.friends.postFriendRequest(ssid));
}

export async function revokeFriendRequest(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    // TODO: Revoke sent request just blocks the user. There should be a proper revoke request endpoint
    return await axios.put(apiUrls.friends.putDeclineFriend(ssid));
}

export async function acceptFriendRequest(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    return await axios.put(apiUrls.friends.putAcceptFriend(ssid));
}

export async function declineFriendRequest(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    return await axios.put(apiUrls.friends.putDeclineFriend(ssid));
}

export async function cancelFriendRequest(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    return await axios.delete(apiUrls.friends.deleteCancelInviteFriend(ssid));
}

export async function blockFriend(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    return await axios.put(apiUrls.friends.putBlockFriend(ssid));
}

export async function blockFriendWithErr(ssid: string): Promise<AxiosResponse> {
    validateSsid(ssid);

    return await axios.put(apiUrls.friends.putBlockFriend(ssid)).catch((error) => {
        return error;
    });
}

export async function unblockFriend(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    // TODO: Unblock currently makes the users not friends. Should keep them as friends
    return await axios.put(apiUrls.friends.putUnblockFriend(ssid));
}

export async function removeFriend(ssid: string): Promise<boolean> {
    validateSsid(ssid);

    return await axios.delete(apiUrls.friends.deleteFriend(ssid));
}

export async function bulkRequestFriends(data: string[]): Promise<IBulkRequestResponse> {
    const response = await axios.post(apiUrls.friends.bulkRequest, data, {
        headers: {
            "content-type": "application/json",
        },
        validateStatus: () => true,
    });

    return response.data;
}

export async function getFriendsRecommendations(pageSize: number): Promise<IFriendsRecommendationsResponse> {
    const queryParams: QueryParameter[] = [new QueryParameter("pageSize", pageSize)];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get(`${apiUrls.friends.friendsRecommendations}${queryParamString}`);

    return response.data;
}

export async function getFriendsByPlatform(platform: Platforms): Promise<IFriendsConsolidatedResponse> {
    const queryParams: QueryParameter[] = [new QueryParameter("platform", platform)];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get(`${apiUrls.friends.friendsByPlatform}${queryParamString}`, {
        validateStatus: () => true,
    });

    if (response.status === ResponseStatusEnum.BadRequest) {
        return {
            ...defaultConsolidatedFriendsResponse,
            operationStatus: response.status,
            error: response.data,
        };
    } else if (response.status === ResponseStatusEnum.NoContent) {
        return {
            ...defaultConsolidatedFriendsResponse,
            operationStatus: response.status,
            error: {
                requestedPlatform: "",
                message: "",
            },
        };
    }
    return {
        ...response.data,
        operationStatus: response.status,
    };
}

const defaultPageSize = 20;

const defaultFriendResponse: IFriendResponse = {
    pageCount: 0,
    pageNumber: 1,
    totalFriends: 0,
    friends: [],
};

interface IFetchFriendsOptions {
    searchTerm?: string;
    pageSize?: number;
    pageNumber?: number;
    includePreferences?: boolean;
    existingFriends?: boolean;
    cancelToken?: CancelToken;
}

export async function fetchExistingFriends(options: IFetchFriendsOptions): Promise<IFriendResponse> {
    const {
        searchTerm = "",
        pageSize = defaultPageSize,
        pageNumber = 1,
        includePreferences = false,
        cancelToken,
    } = options;

    const queryParams = [
        new QueryParameter("searchTerm", searchTerm),
        new QueryParameter("pageNumber", pageNumber),
        new QueryParameter("pageSize", pageSize),
        new QueryParameter("includePreferences", includePreferences),
    ];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get<IFriendResponse>(`${apiUrls.friends.getProfileFriends}${queryParamString}`, {
        cancelToken: cancelToken,
    });

    if (response.status !== ResponseStatusEnum.Ok) return defaultFriendResponse;

    return response.data;
}

export async function fetchFriends(options: IFetchFriendsOptions): Promise<IFriendResponse> {
    const {
        searchTerm = "",
        pageSize = defaultPageSize,
        pageNumber = 1,
        includePreferences = false,
        cancelToken,
    } = options;

    if (searchTerm.length === 0) return defaultFriendResponse;

    const queryParams = [
        new QueryParameter("searchTerm", searchTerm),
        new QueryParameter("pageNumber", pageNumber),
        new QueryParameter("pageSize", pageSize),
        new QueryParameter("includePreferences", includePreferences),
    ];

    const queryParamString = constructQueryParameterString(queryParams);

    const url = apiUrls.friends.getFriendSearch + queryParamString;

    const response = await axios.get<IFriendResponse>(url, {
        cancelToken: cancelToken,
    });

    if (response.status !== ResponseStatusEnum.Ok) return defaultFriendResponse;

    return response.data;
}

export async function fetchPendingFriends(options: IFetchFriendsOptions): Promise<IFriendResponse> {
    const {
        searchTerm = "",
        pageSize = defaultPageSize,
        pageNumber = 1,
        includePreferences = false,
        cancelToken,
    } = options;

    const queryParams = [
        new QueryParameter("searchTerm", searchTerm),
        new QueryParameter("pageNumber", pageNumber),
        new QueryParameter("pageSize", pageSize),
        new QueryParameter("filter", "Pending"),
        new QueryParameter("includePreferences", includePreferences),
    ];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get<IFriendResponse>(`${apiUrls.friends.getFriends}${queryParamString}`, {
        cancelToken: cancelToken,
    });

    if (response.status !== ResponseStatusEnum.Ok) return defaultFriendResponse;

    return response.data;
}

export async function checkFriends(userSsid: string): Promise<boolean> {
    const queryParams = [new QueryParameter("userSsid", userSsid)];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get(`${apiUrls.friends.checkFriends}${queryParamString}`);

    return response.data;
}

export async function getFriendProfile(pageNumber: number, pageSize: number, ssid: string): Promise<IFriendsResponse> {
    const queryParams = [
        new QueryParameter("pageNumber", pageNumber),
        new QueryParameter("pageSize", pageSize),
        new QueryParameter("ssid", ssid),
    ];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.get(`${apiUrls.friends.getProfileFriends}${queryParamString}`);

    return response.data;
}
