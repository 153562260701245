import { forwardRef, useEffect, useMemo, useState } from "react";
import { ReactComponent as RNLogo } from "assets/images/logos/racenet/icon_racenetlogofull.svg";
import { styleGuide } from "theme";
import NewLoginSignupButton from "containers/navigation/anonymous/NewLoginSignupButton";
import Typography from "@material-ui/core/Typography";
import Spacer from "common/components/Spacer";
import GamesEnum from "common/enums/GamesEnum";
import HeroImageDesktop from "./HeroImageDesktop";
import { ITheme, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import SectionRacenetApp from "../sections/SectionRacenetApp";
import Sticky from "react-sticky-el";

interface IProps {
    id: string;
    cdnPath: string;
    title: string;
    description: string;
    appDescription: string;
    game: GamesEnum;
    nav: { id: string; label: string; action: (id: string) => void }[];
    iOsUrl: string;
    playStoreUrl: string;
}

export const SET_SELECTED_MENU_EVENT = "racenet_selected_landing_menu";
export const SCROLL_OFFSET = 5;

const HeroDesktop = forwardRef(
    ({ cdnPath, title, description, game, nav, iOsUrl, playStoreUrl, appDescription, id }: IProps, ref: any) => {
        const theme: ITheme = useTheme();
        const sm = useMediaQuery(theme.breakpoints.down("sm"));

        const [selected, setSelected] = useState(nav[0].id);

        const heroImage = useMemo(() => {
            switch (game) {
                case GamesEnum.WRC2023:
                    return {
                        laptopImg: `${cdnPath}/Desktop_HeroWRCLaptop.png`,
                        mobileImg: `${cdnPath}/Desktop_HeroWRCMob.png`,
                        sm: sm,
                    };
                default:
                    return {
                        laptopImg: `${cdnPath}/Desktop_HeroF1Laptop.png`,
                        mobileImg: `${cdnPath}/Desktop_HeroMob.png`,
                        sm: sm,
                    };
            }
        }, [game]);

        useEffect(() => {
            const setSelectedMenu = (event: any) => {
                setSelected(event.detail);
            };

            window.addEventListener(SET_SELECTED_MENU_EVENT, setSelectedMenu);

            return () => window.removeEventListener(SET_SELECTED_MENU_EVENT, setSelectedMenu);
        }, []);

        useEffect(() => {
            const sendSelectedMenu = () => {
                if (window.scrollY + SCROLL_OFFSET < ref.current.offsetTop + ref.current.clientHeight) {
                    setSelected(id);
                }
            };

            window.addEventListener("scroll", sendSelectedMenu);

            return () => window.removeEventListener("scroll", sendSelectedMenu);
        }, [ref]);

        return (
            <div
                ref={ref}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    background: `#0D1042 url("${cdnPath}/heroGrad.jpg") no-repeat right`,
                    backgroundSize: "auto 100%",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        padding: `${styleGuide.spacing(3)}px ${styleGuide.spacing(5)}px`,
                        justifyContent: "space-between",
                    }}
                >
                    <RNLogo style={{ height: 50 }} />
                    <div style={{ display: "flex", gap: styleGuide.spacing(2) }}>
                        <NewLoginSignupButton isMobile={false} />
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", overflowX: "clip", alignItems: "center" }}>
                    <div
                        style={{
                            display: "flex",
                            width: sm ? "50%" : "40%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: styleGuide.spacing(),
                                padding: sm
                                    ? `${styleGuide.spacing(4)}px ${styleGuide.spacing(2)}px`
                                    : styleGuide.spacing(2),
                                maxWidth: 600,
                            }}
                        >
                            <Typography
                                style={{
                                    ...(sm ? styleGuide.typography.title : styleGuide.typography.titleXL),
                                    textAlign: "left",
                                    lineHeight: 1,
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography style={{ ...styleGuide.typography.copy, textAlign: "left" }}>
                                {description}
                            </Typography>
                            <Spacer size={0.5} />
                            {/* <Button isMobile={false} size="jumbo" customStyles={{ maxWidth: 220 }}>
                            GET STARTED
                        </Button> */}
                            <NewLoginSignupButton isMobile={false} text="GET STARTED" style={{ maxWidth: 220 }} />
                        </div>
                    </div>
                    <div style={{ display: "flex", width: sm ? "50%" : "60%", overflowX: "clip" }}>
                        <HeroImageDesktop {...heroImage} />
                    </div>
                </div>
                <Sticky stickyStyle={{ zIndex: 10 }}>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            padding: styleGuide.spacing(2),
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                minHeight: 45,
                                borderRadius: 45 / 2,
                                padding: styleGuide.spacing(),
                                backgroundColor: styleGuide.color.blue,
                                alignItems: "center",
                                transition: styleGuide.transitions.hoverEaseInOut,
                            }}
                        >
                            {nav.map((i) => (
                                <div
                                    key={`hero_selector_${i.id}`}
                                    style={{
                                        display: "flex",
                                        padding: `${styleGuide.spacing(1)}px ${styleGuide.spacing(2)}px`,
                                        cursor: "pointer",
                                        borderRadius: 17,
                                        backgroundColor: selected === i.id ? styleGuide.color.white : "transparent",
                                    }}
                                    onClick={() => {
                                        //setSelected(i.id);
                                        i.action(i.id);
                                    }}
                                >
                                    <Typography
                                        style={{
                                            ...styleGuide.typography.copy,
                                            textAlign: "center",
                                            color: selected === i.id ? styleGuide.color.blue : styleGuide.color.white,
                                        }}
                                    >
                                        {i.label}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </div>
                </Sticky>

                <Spacer size={3} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        height: "100%",
                        width: "100%",
                        justifySelf: "end",
                    }}
                >
                    <SectionRacenetApp
                        isMobile={false}
                        cdnPath={cdnPath}
                        playStore={playStoreUrl}
                        iOsUrl={iOsUrl}
                        description={appDescription}
                        id={"racenet_app"}
                    />
                </div>
            </div>
        );
    }
);

export default HeroDesktop;
