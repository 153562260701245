import axios from "axios";
import { ActionMap } from "common/types/ActionMapType";
import React from "react";
import { settings } from "settings";
import { IConfigurationStore } from "../ConfigurationStore";
import { trimEndSlash } from "utils/urlHelper";
import { getFeaturesConfig } from "apiServices/cdn/CdnApiService";
import { IFeeatureCdnConfigResponse } from "apiServices/cdn/responses/IFeatureCdnConfigResponse";

export enum ConfigurationTypes {
    FetchConfiguration = "FETCH_CONFIGURATION",
    FetchConfigurationCompleted = "FETCH_CONFIGURATION_COMPLETED",
    FetchFeatures = "FETCH_FEATURES",
    FetchFeaturesCompleted = "FETCH_FEATURES_COMPLETED",
}

type ConfigurationPayload = {
    [ConfigurationTypes.FetchConfiguration]: IConfigurationStore;
    [ConfigurationTypes.FetchConfigurationCompleted]: IConfigurationStore;
    [ConfigurationTypes.FetchFeatures]: IFeeatureCdnConfigResponse;
    [ConfigurationTypes.FetchFeaturesCompleted]: IFeeatureCdnConfigResponse;
};

export type ConfigurationActions = ActionMap<ConfigurationPayload>[keyof ActionMap<ConfigurationPayload>];

export const dispatchFetchConfiguration = async (dispatch: React.Dispatch<any>) => {
    dispatch({
        type: ConfigurationTypes.FetchConfiguration,
    });

    try {
        const config = await axios.get(
            `${settings.appUrl}/${
                process.env.NODE_ENV === "production" ? "config.json" : process.env.REACT_APP_WEB_API_CONFIG
            }`
        );

        dispatch({
            type: ConfigurationTypes.FetchConfigurationCompleted,
            payload: config.data,
        });

        return config.data;
    } catch {
        throw Error("Something went wrong while trying to retrieve the configuration file");
    }
};

export const dispatchFetchFeatures = async (dispatch: React.Dispatch<any>, cdnUrl: string) => {
    dispatch({
        type: ConfigurationTypes.FetchConfiguration,
    });

    const configPath =
        process.env.NODE_ENV === "production"
            ? `${trimEndSlash(cdnUrl)}/features.json`
            : `${process.env.REACT_APP_FEATURES_CONFIG_TYPE === "local" ? settings.appUrl : trimEndSlash(cdnUrl)}/${
                  process.env.REACT_APP_FEATURES_CONFIG ?? "features.json"
              }`;

    try {
        const config = await getFeaturesConfig(configPath);

        dispatch({
            type: ConfigurationTypes.FetchFeaturesCompleted,
            payload: config,
        });

        return config;
    } catch {
        throw Error("Something went wrong while trying to retrieve the features file");
    }
};

export const configurationReducer = (state: IConfigurationStore, action: ConfigurationActions) => {
    switch (action.type) {
        case ConfigurationTypes.FetchConfiguration:
        case ConfigurationTypes.FetchFeatures: {
            return {
                ...state,
                loading: true,
            };
        }
        case ConfigurationTypes.FetchConfigurationCompleted: {
            return {
                ...action.payload,
                authUrls: {
                    ...action.payload.authUrls,
                    login: `${action.payload.authUrls.login}${settings.appUrl}`,
                    logout: `${action.payload.authUrls.logout}${settings.appUrl}`,
                },
                loading: false,
                hasFetched: true,
            };
        }
        case ConfigurationTypes.FetchFeaturesCompleted:
            return {
                ...state,
                features: action.payload?.features ?? [],
                loading: false,
                hasFetched: true,
            };
        default:
            return state;
    }
};
