import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {},
        icon: {
            transform: "rotate(90deg)",
            transition: theme.transitions.create("transform"),
        },
        closeIcon: {
            transform: "rotate(270deg)",
        },
    });
});

interface IProps extends IconButtonProps {
    isOpen?: boolean;
    iconSize?: "inherit" | "medium" | "small" | "large";
    isDisabled?: boolean;
}

export default function DropDownToggleButton(props: IProps) {
    const { className, size, iconSize = "medium", isOpen = false, isDisabled, ...rest } = props;
    const classes = useStyles(props);

    return (
        <IconButton className={clsx(className, classes.root)} disabled={isDisabled} {...rest}>
            <PlayCircleFilledIcon
                fontSize={iconSize}
                className={clsx(classes.icon, {
                    [classes.closeIcon]: isOpen,
                })}
            />
        </IconButton>
    );
}
