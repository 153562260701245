import { HTMLAttributes, useEffect, useState } from "react";

interface IProps extends HTMLAttributes<HTMLDivElement> {
    delay: number;
}

export default function DelayRender(props: IProps) {
    const { delay, children, ...rest } = props;

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(() => {
            setIsVisible(true);
        }, delay);
        return () => clearTimeout(timeout);
    }, []);

    return isVisible ? <div {...rest}>{children}</div> : null;
}
