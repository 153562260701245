import { SwipeableDrawer, withStyles } from "@material-ui/core";
import Spacer from "common/components/Spacer";
import { ReactNode } from "react";
import ProfileDrawerSkeleton from "./ProfileDrawerSkeleton";
import { IStyles, styleGuide } from "theme";
import { rootZIndex } from "../dialogs/PortalDialog";
import MobileBottomNavSpacer from "containers/navigation/authorised/mobile/MobileBottomNavSpacer";

interface IProps {
    loader?: boolean;
    open: boolean;
    children: ReactNode;
    isMobile?: boolean;
    anchor: "bottom" | "top";
    setOpen: (open: boolean) => void;
    handleClose?: () => void;
}

const Drawer = ({ loader = false, open, children, isMobile = false, anchor, setOpen, handleClose }: IProps) => {
    const styles: IStyles = {
        drawer: {
            zIndex: rootZIndex + 1,
            backgroundColor: "transparent",
            transition: styleGuide.transitions.hoverEaseInOut,
            backdropFilter: "blur(8px)",
            borderTopLeftRadius: styleGuide.borderRadius,
            borderTopRightRadius: styleGuide.borderRadius,
        },
        notch: {
            width: 64,
            height: 8,
            borderRadius: styleGuide.borderRadius,
            background: styleGuide.color.periwinkle,
            margin: "0 auto",
        },
        children: {
            display: "flex",
            flexDirection: "column",
            borderTopLeftRadius: styleGuide.borderRadius,
            borderTopRightRadius: styleGuide.borderRadius,
            backgroundColor: styleGuide.color.richGrey,
        },
    };

    const drawerChildren = loader ? (
        <ProfileDrawerSkeleton isMobile={isMobile} />
    ) : (
        <>
            <div style={styles.notch} />
            <Spacer />
            <div style={styles.children}>
                {children}
                <Spacer />
                <MobileBottomNavSpacer />
            </div>
        </>
    );

    const CustomSwipeableDrawer = withStyles({
        paper: {
            backgroundColor: styleGuide.color.transparent,
        },
    })(SwipeableDrawer);

    const onClose = () => {
        if (handleClose) {
            handleClose();
        } else {
            setOpen(false);
        }
    };

    return (
        <CustomSwipeableDrawer
            anchor={anchor}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={onClose}
            style={styles.drawer}
        >
            {drawerChildren}
        </CustomSwipeableDrawer>
    );
};

export default Drawer;
