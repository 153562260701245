import React, { createContext, useContext, useMemo, useState } from "react";
import { ThemeProvider } from "@material-ui/core";
import { lightTheme, darkTheme } from "theme";
import useLocalStorage from "hooks/useLocalStorage";

interface IThemeContextProps {
    darkMode: boolean;
    setDarkMode(darkMode: boolean): void;
    topInset?: number;
    setTopInset: (value: number) => void;
    appVersion?: string;
    setAppVersion: (version: string) => void;
}

const CustomThemeContext = createContext<IThemeContextProps>({
    darkMode: false,
    setDarkMode: () => {},
    topInset: undefined,
    setTopInset: () => {},
    appVersion: undefined,
    setAppVersion: () => {},
});

interface IProps {
    children?: React.ReactNode;
}

const CustomThemeProvider = ({ children }: IProps) => {
    const [darkMode, setDarkMode] = useLocalStorage("darkMode", false);
    const [topInset, setInset] = useState<number>();
    const [appVersion, setVersion] = useState<string>();

    const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

    const setTopInset = (value: number) => setInset(value);
    const setAppVersion = (value: string) => setVersion(value);

    return (
        <ThemeProvider theme={darkTheme}>
            {/* <ThemeProvider theme={theme}> */}
            <CustomThemeContext.Provider
                value={{
                    darkMode,
                    setDarkMode,
                    topInset,
                    setTopInset,
                    appVersion,
                    setAppVersion,
                }}
            >
                {children}
            </CustomThemeContext.Provider>
        </ThemeProvider>
    );
};

export const useDarkTheme = () => useContext(CustomThemeContext);

export const useNativeAppProps = () => {
    const { setTopInset, topInset, appVersion, setAppVersion } = useDarkTheme();
    return {
        topInset,
        setTopInset,
        appVersion,
        setAppVersion,
    };
};

export { CustomThemeContext, CustomThemeProvider };
