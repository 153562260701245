import { useEffect, useState } from "react";
import InstallAppSnackbar from "./dialogs/InstallAppSnackbar";
import DelayRender from "common/components/DelayRender";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";
import { isMobileSafari } from "react-device-detect";
import useIsMobile from "hooks/useIsMobile";
import useIsLandscape from "hooks/useIsLandscape";

function getDoNotShowAgain(storageId: string) {
    return {
        localStorage: localStorage.getItem(storageId) === "true",
        setLocalStorage: (value: boolean) => localStorage.setItem(storageId, value.toString()),
        sessionStorage: sessionStorage.getItem(storageId) === "true",
        setSessionStorage: (value: boolean) => sessionStorage.setItem(storageId, value.toString()),
    };
}

export default function LaunchDialogsController() {
    const isMobile = useIsMobile();
    const [showInstallAppSnackbar, setShowInstallAppSnackbar] = useState<boolean>(true);
    const isLandscape = useIsLandscape();
    const storageIds = {
        installAppBanner: {
            doNotShowAgain: "doNotShowIntallAppBannerAgain",
        },
    };

    const doNotShowInstallAppBanner = getDoNotShowAgain(storageIds.installAppBanner.doNotShowAgain);
    useEffect(() => {
        if (doNotShowInstallAppBanner.sessionStorage) return;

        setShowInstallAppSnackbar(true);
    }, []);

    const onInstallAppSnackBarClose = (showAgain: boolean) => {
        !showAgain && doNotShowInstallAppBanner.setSessionStorage(true);
        setShowInstallAppSnackbar(false);
    };

    return (
        // It only shows on Android devices
        !isLandscape &&
            isMobile &&
            !isRaceNetMobileApp &&
            !isRaceNetTabletApp &&
            !isMobileSafari &&
            !doNotShowInstallAppBanner.sessionStorage ? (
            <DelayRender delay={2000}>
                <InstallAppSnackbar
                    open={showInstallAppSnackbar}
                    handleClose={onInstallAppSnackBarClose}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                />
            </DelayRender>
        ) : null
    );
}
