import useIsMobile from "hooks/useIsMobile";
import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { isIOS, isMacOs, isTablet } from "react-device-detect";
import { useLocation } from "react-router-dom";

export enum ScrollItems {
    Popover = 0,
    Dialog = 1,
    Select = 2,
}

interface IScrollContext {
    disableScroll: boolean;
    handleDisableScroll: (item: number) => void;
    handleEnableScroll: (item: number) => void;
    handleInitialLoad: (load: boolean) => void;
    scrollItems: number[];
}

const ScrollContext = createContext<IScrollContext>({
    disableScroll: false,
    handleDisableScroll: () => {},
    handleEnableScroll: () => {},
    handleInitialLoad: () => {},
    scrollItems: [],
});

interface IProps {
    children?: React.ReactNode;
}

export const ScrollContextProvider = ({ children }: IProps) => {
    const isMobile = useIsMobile();
    const location = useLocation();
    const [disableScroll, setDisableScroll] = useState<boolean>(false);
    const [scrollItems, setScrollItems] = useState<number[]>([]);
    const [initialLoad, setInitialLoad] = useState<boolean>(false);

    const handleDisableScroll = (item: number) => {
        setDisableScroll(true);

        setScrollItems((prev) => [...prev, item]);
    };

    const handleEnableScroll = (item: number) => {
        setDisableScroll(false);

        setScrollItems((prev) => prev.filter((a) => a !== item));
    };

    const handleInitialLoad = (load: boolean) => {
        setInitialLoad(load);
    };

    useLayoutEffect(() => {
        if (location) {
            setScrollItems([]);
            setDisableScroll(false);
        }
    }, [location]);

    const isExternalMouse = useMemo((): boolean => {
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px";
        document.body.appendChild(scrollDiv);
        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);

        if (scrollbarWidth > 0) {
            return true;
        } else {
            return false;
        }
    }, []);

    function useBodyScrollable() {
        const [bodyScrollable, setBodyScrollable] = useState(document.body.scrollHeight > window.innerHeight);
        useEffect(() => {
            const resizeObserver = new ResizeObserver(() => {
                setBodyScrollable(document.body.scrollHeight > window.innerHeight);
            });
            resizeObserver.observe(document.body);
            return () => {
                resizeObserver.unobserve(document.body);
            };
        }, []);
        return bodyScrollable;
    }

    const bodyScrollable = useBodyScrollable();

    useEffect(() => {
        if (bodyScrollable === false && initialLoad === false) return;

        const settingsDropdown = document.getElementById("settings-dropdown");

        if (disableScroll) {
            document.body.style.overflow = "hidden";
            document.body.style.touchAction = "none";

            if (scrollItems.includes(ScrollItems.Popover)) {
                document.body.style.position = "fixed";
                document.body.style.height = "100%";
                document.body.style.width = "100%";
            }

            if (!isMobile && !isTablet) {
                if (!isExternalMouse && isMacOs) return;

                document.body.style.paddingRight = isMacOs ? "15px" : "17px";
                if (settingsDropdown) {
                    settingsDropdown.style.paddingRight = isMacOs ? "15px" : "17px";
                }
            }
        } else if (disableScroll === false) {
            setTimeout(() => {
                document.body.style.overflow = "unset";
                document.body.style.touchAction = "unset";

                document.body.style.position = "unset";

                if (!isIOS) {
                    document.body.style.paddingRight = "unset";
                    if (settingsDropdown) {
                        settingsDropdown.style.paddingRight = "unset";
                    }
                }
            }, 300);
        }
    }, [disableScroll, initialLoad]);

    return (
        <ScrollContext.Provider
            value={{
                disableScroll,
                handleDisableScroll,
                handleEnableScroll,
                handleInitialLoad,
                scrollItems,
            }}
        >
            {children}
        </ScrollContext.Provider>
    );
};

export const useScrollContext = () => useContext(ScrollContext);
