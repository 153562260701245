import { IdentityActions, identityReducer } from "./actions/IdentityActions";
import { PreferencesActions, preferencesReducer } from "./actions/PreferencesActions";
import { IIdentityStore } from "./IdentityStore";
import { JwtActions, jwtTokensReducer } from "./actions/JwtActions";
import { CompletedOnboardingActions, identityCompletedOnboardingReducer } from "./actions/OnboardingActions";
import {
    NotificationPreferencesActions,
    notificationsPreferencesReducer,
} from "./actions/NotificationPreferencesActions";

export const reducer = (
    { identity, preferences, jwtTokens, completedOnboardings, pushNotificationPreferences }: IIdentityStore,
    action:
        | IdentityActions
        | PreferencesActions
        | JwtActions
        | CompletedOnboardingActions
        | NotificationPreferencesActions
): IIdentityStore => ({
    identity: identityReducer(identity, action as IdentityActions),
    preferences: preferencesReducer(preferences, action as PreferencesActions),
    jwtTokens: jwtTokensReducer(jwtTokens, action as JwtActions),
    completedOnboardings: identityCompletedOnboardingReducer(
        completedOnboardings,
        action as CompletedOnboardingActions
    ),
    pushNotificationPreferences: notificationsPreferencesReducer(
        pushNotificationPreferences,
        action as NotificationPreferencesActions
    ),
});
