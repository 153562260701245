import { IStyles, styleGuide } from "theme";
import { ReactComponent as RNLogo } from "assets/images/logos/racenet/icon_racenetlogofull.svg";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { loadTrustArcScript } from "utils/loadTrustArcScript";
import { ITheme, useMediaQuery, useTheme } from "@material-ui/core";

interface IProps {
    isMobile: boolean;
    termsUrl: string;
    policyUrl: string;
}

const Footer = ({ isMobile, termsUrl, policyUrl }: IProps) => {
    const theme: ITheme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        loadTrustArcScript();
    }, []);

    const styles: IStyles = {
        text: {
            ...styleGuide.typography.copy,
            ...styleGuide.fontTypes.bold,
            color: styleGuide.color.coral,
            textTransform: "uppercase",
            textAlign: "center",
        },
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                padding: isMobile
                    ? `${styleGuide.spacing(5)}px ${styleGuide.spacing(2)}px ${styleGuide.spacing(
                          4
                      )}px ${styleGuide.spacing(2)}px`
                    : styleGuide.spacing(3),
                backgroundColor: styleGuide.color.richGrey,
                justifyContent: "space-between",
                gap: styleGuide.spacing(isMobile ? 6 : 2),
            }}
        >
            <RNLogo style={{ height: 50 }} />
            <div
                style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: styleGuide.spacing(isMobile ? 3 : sm ? 2 : 4),
                    alignItems: "center",
                    paddingRight: isMobile ? 0 : 56,
                }}
            >
                <a href={termsUrl} target="_blank">
                    <Typography style={styles.text}>TERMS & CONDITIONS</Typography>
                </a>
                <a href={policyUrl} target="_blank">
                    <Typography style={styles.text}>PRIVACY POLICY</Typography>
                </a>
                <Typography style={styles.text}>
                    <div id="teconsent"></div>
                </Typography>
            </div>
        </div>
    );
};

export default Footer;
