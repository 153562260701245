import { NewNotificationActions, newNotificationReducer } from "./actions/NewNotificationActions";
import { NotificationActions, notificationReducer } from "./actions/NotificationActions";
import { OldNotificationActions, oldNotificationReducer } from "./actions/OldNotificationActions";
import { INotificationContextStore } from "./NotificationContextStore";

export const reducer = (
    { newNotificationStore, oldNotificationStore, notificationStore }: INotificationContextStore,
    action: OldNotificationActions | NewNotificationActions | NotificationActions
): INotificationContextStore => ({
    newNotificationStore: newNotificationReducer(newNotificationStore, action as NewNotificationActions),
    oldNotificationStore: oldNotificationReducer(oldNotificationStore, action as OldNotificationActions),
    notificationStore: notificationReducer(notificationStore, action as NotificationActions),
});
