export enum NotificationType {
    None = 0,
    FriendRequested = 2,
    FriendRequestAccepted = 3,
    ClubLiked = 4,
    ClubOwnershipTransferred = 5,
    ClubRoleChangedToAdmin = 6,
    ClubMemberUnbanned = 7,
    ClubMemberBanned = 8,
    ClubAccessLevelChanged = 9,
    ClubNameChanged = 10,
    ClubMemberJoined = 11,
    ClubPlatformChanged = 12,
    ClubEventStartingSoon = 13,
    ClubChampionshipCreated = 14,
    ClubChampionshipFinished = 15,
    ClubChampionshipCancelled = 16,
    ProLeagueLeagueLiked = 17,
    ProLeagueCreated = 18,
    ProLeagueSeasonCreated = 19,
    ProLeagueMemberJoined = 20,
    ProLeagueMemberPromotedToAdmin = 21,
    ProLeagueEventStartingSoon = 22,
    ProLeagueNameChanged = 23,
    ProLeagueMemberBanned = 24,
    ProLeagueBanLifted = 25,
    ProLeagueMemberEnroled = 26,
    ProLeaguePlatformChanged = 27,
    ProLeaguePrivacyLevelChanged = 28,
    ProLeagueTieringComplete = 29,
    ProLeagueWarningApplied = 30,
    ProLeagueResultsReceived = 31,
    ProLeaguePenaltyApplied = 32,
    ProLeagueRemovedFromSeason = 33,
    ProLeagueMemberUpdatedAvailability = 34,
    ProLeagueReserveSubbed = 35,
    ProLeagueDriverReplaced = 36,
    ProLeagueEventLobbyOpened = 37,
    ProLeagueTieringFailed = 38,
}
