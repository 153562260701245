import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import { routingUrls } from "settings";
import FeedbackIcon from "assets/icons/navBar/FeedbackIcon";
import { getDesktopToolbarSpacing, layout, styleGuide } from "theme";
import Typography from "@material-ui/core/Typography";
import { useApplicationContext } from "contexts/application/ApplicationContext";
import NewTabLink from "common/components/links/NewTabLink";
import INavigationItem from "../INavigationItem";
import DesktopDropDown from "./DesktopDropDown";
import useIsMobile from "hooks/useIsMobile";
import Notifications from "./notifications/Notifications";
import ResponsiveSpacer from "common/components/ResponsiveSpacer";
import { AppBar, ITheme } from "@material-ui/core";
import RacenetEAIcon from "assets/icons/RacenetEAIcon";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";

const useStyles = makeStyles((theme: ITheme) => {
    const linkSelectionWidth = 4;

    const bodyContainerZIndex = theme.zIndex.drawer + 1;

    const desktopToolbarSpacing = getDesktopToolbarSpacing(theme);

    const heightMediaQueries = {
        downMd: "@media (max-height: 620px)",
        downSm: "@media (max-height: 520px)",
    };

    return createStyles({
        drawer: {
            width: layout.sidebarWidth,
            "& > *::-webkit-scrollbar": {
                display: "none",
            },
            "& -ms-overflow-style": {
                display: "none",
                scrollbarWidth: "none",
            },
            "& > *": {
                scrollbarWidth: "none",
            },
        },
        drawerPaper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: layout.sidebarWidth,
            border: "none",
            //backgroundColor: theme.sidebar.backgroundColor,
            backgroundColor: styleGuide.color.richBlack,
            color: styleGuide.color.white,
        },
        bracketNavItem: {
            marginTop: theme.spacing(2),
            flexShrink: 0,
            [heightMediaQueries.downMd]: {
                marginTop: theme.spacing(1),
            },
            [heightMediaQueries.downSm]: {
                marginTop: theme.spacing(0.5),
            },
        },
        feedback: {
            marginBottom: theme.spacing(2) + theme.styles.cssImportant,
            flexShrink: 0,
            [heightMediaQueries.downMd]: {
                marginBottom: theme.spacing(1) + theme.styles.cssImportant,
            },
            [heightMediaQueries.downSm]: {
                marginBottom: theme.spacing(0.5) + theme.styles.cssImportant,
            },
            [heightMediaQueries.downMd]: {
                fontSize: "0.75rem",
            },
            color: styleGuide.color.white,
        },
        feedbackIcon: {
            color: styleGuide.color.white,
        },
        list: {
            paddingTop: 0,
            paddingBottom: 0,
            "& $navItem": {
                transition: theme.styles.hoverEaseInOut,
                "&:hover": {
                    backgroundColor: styleGuide.color.richGrey,
                },
            },
        },
        navItem: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            "&:hover": {
                "&$bracketNavItem": {
                    opacity: theme.styles.defaultHoverOpacity,
                },
            },
            fontSize: "1.8rem",
            padding: `${theme.spacing(2)}px ${theme.spacing(1.75)}px`,
            [theme.breakpoints.down("md")]: {
                fontSize: "1.7rem",
                padding: `${theme.spacing(1.5)}px ${theme.spacing(1.25)}px`,
            },
            [theme.breakpoints.down("sm")]: {
                padding: `${theme.spacing(1.25)}px ${theme.spacing(1)}px`,
                fontSize: "1.7rem",
            },
        },
        navItemIconContainer: {
            transition: theme.transitions.create("all", {
                duration: theme.transitions.duration.standard,
                easing: theme.transitions.easing.easeInOut,
            }),
            borderRadius: theme.styles.borderRadiusXs,
            display: "flex",
        },
        navItemText: {
            [heightMediaQueries.downMd]: {
                fontSize: "0.75rem",
            },
        },
        icon: {
            color: theme.sidebar.logoColor,
        },
        info: {
            color: theme.palette.info.main,
        },
        activeNavLink: {
            background: styleGuide.color.periwinkle,
            color: `${styleGuide.color.richBlack} !important`,
            backgroundSize: "100%",
            "&:hover": {
                backgroundColor: `${styleGuide.color.periwinkle} !important`,
            },
        },
        linkSelector: {
            width: linkSelectionWidth * 2,
            height: "100%",
            backgroundColor: theme.palette.primary.contrastText,
            position: "absolute",
            left: -linkSelectionWidth * 2,
            top: 0,
            borderBottomRightRadius: linkSelectionWidth,
            borderTopRightRadius: linkSelectionWidth,
        },
        settingsDropdown: {
            display: "flex",
            flexDirection: "row",
            boxShadow: "none",
            top: desktopToolbarSpacing.horizontal,
            right: desktopToolbarSpacing.vertical + layout.scrollbarWidth,
            zIndex: bodyContainerZIndex + 2,
            minWidth: 200,
            width: "unset",
            border: "none",
        },
    });
});

interface IProps {
    items: INavigationItem[];
}

export default function DesktopNavigation(props: IProps) {
    const { items } = props;
    const classes = useStyles();

    const applicationContext = useApplicationContext();

    const navList = (
        <List className={classes.list} classes={{ padding: classes.list }}>
            {items.map((item, i) => {
                const { href, icon, label, matchExactPath = false } = item;
                return (
                    <MenuItem
                        key={`${i}_${label}`}
                        component={NavLink}
                        to={href}
                        exact={matchExactPath}
                        activeClassName={classes.activeNavLink}
                        className={clsx(classes.navItem)}
                    >
                        <div className={classes.linkSelector} />
                        <div className={classes.navItemIconContainer}>{icon}</div>
                        <Typography className={classes.navItemText} variant="body2">
                            {label}
                        </Typography>
                    </MenuItem>
                );
            })}
        </List>
    );

    return (
        <div className="navigationitem">
            <Drawer
                variant="permanent"
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div>
                    <MenuItem
                        component={NavLink}
                        to={routingUrls.landing}
                        exact
                        activeClassName={classes.activeNavLink}
                        className={clsx(classes.navItem, classes.bracketNavItem)}
                        style={{ marginTop: 0 }}
                    >
                        <RacenetEAIcon className={classes.icon} style={{ fontSize: "3.5rem" }} />
                    </MenuItem>
                    {navList}
                </div>
                {typeof applicationContext?.state.applicationStore.surveyUrl === "undefined" ||
                applicationContext.state.applicationStore.surveyUrl === "" ? (
                    <div />
                ) : (
                    <MenuItem
                        component={NewTabLink}
                        href={applicationContext.state.applicationStore.surveyUrl}
                        className={clsx(classes.navItem, classes.bracketNavItem)}
                    >
                        <FeedbackIcon fontSize="inherit" className={classes.feedbackIcon} />
                        <Typography className={classes.feedback} variant="body2">
                            Feedback
                        </Typography>
                    </MenuItem>
                )}
            </Drawer>
            <AppBar position="fixed" color="transparent" id="settings-dropdown" className={classes.settingsDropdown}>
                <div>
                    <Notifications />
                </div>
                <ResponsiveSpacer layer="1" />
                <DesktopDropDown />
            </AppBar>
        </div>
    );
}

export const DesktopNavigationToolbar = () => {
    const isMobile = useIsMobile() || isRaceNetMobileApp || isRaceNetTabletApp;
    const theme = useTheme();
    const desktopToolbarSpacing = getDesktopToolbarSpacing(theme);

    return isMobile ? null : (
        <div
            style={{
                height: desktopToolbarSpacing.height,
                margin: `${desktopToolbarSpacing.horizontal}px ${desktopToolbarSpacing.vertical}px`,
                marginBottom: 0,
            }}
        ></div>
    );
};
