import axios from "axios";
import Loader from "common/components/Loader";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import InternalServerErrorPage from "pages/errorPages/InternalServerErrorPage";
import React, { createContext, FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import { dispatchFetchConfiguration } from "./actions/ConfigurationActions";
import { reducer } from "./ConfigurationReducer";
import { IConfigurationStore, defaultConfigurationStore } from "./ConfigurationStore";

const ConfigurationContext = createContext<{ state: IConfigurationStore; dispatch: React.Dispatch<any> }>({
    state: defaultConfigurationStore,
    dispatch: () => null,
});

export const ConfigurationContextProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultConfigurationStore);
    const [responseStatus, setResponseStatus] = useState<ResponseStatusEnum>(ResponseStatusEnum.Pending);

    useEffect(() => {
        dispatchFetchConfiguration(dispatch)
            .then((configJson) => {
                axios.defaults.baseURL = configJson.apiUrl;
                axios.defaults.withCredentials = true;
                setResponseStatus(ResponseStatusEnum.Ok);
            })
            .catch(() => {
                setResponseStatus(ResponseStatusEnum.Error);
            });
    }, []);

    const getContent = (status: ResponseStatusEnum) => {
        switch (status) {
            case ResponseStatusEnum.Pending:
                return <Loader />;
            case ResponseStatusEnum.Error:
                return <InternalServerErrorPage />;
            case ResponseStatusEnum.Ok:
                return (
                    <ConfigurationContext.Provider value={{ state, dispatch }}>
                        {children}
                    </ConfigurationContext.Provider>
                );
        }
    };

    return <>{getContent(responseStatus)}</>;
};

export const useConfig = () => useContext(ConfigurationContext).state;
export const useConfigDispatch = () => useContext(ConfigurationContext).dispatch;
