import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParams, trimEndSlash } from "../../../utils/urlHelper";
import { IFetchAccessTokenRequest } from "../../../apiServices/identity/requests/IFetchAccessTokenRequest";
import { useConfig } from "../../../contexts/configuration/ConfigurationContext";
import { routingUrls } from "../../../settings";
import { useIdentityContext } from "../../../contexts/identity/IdentityContext";
import { dispatchFetchTokens } from "../../../contexts/identity/actions/JwtActions";
import { useHistory } from "react-router-dom";
import { dispatchFetchIdentity } from "../../../contexts/identity/actions/IdentityActions";
import { setTokenAndUnauthorizedInterceptorIntoAxiosDefaults } from "../../../utils/axiosTools";
import StorageKeyEnum from "../../../common/enums/StorageKeyEnum";
import useIsMobile from "hooks/useIsMobile";

const OauthCallback = () => {
    const location = useLocation();
    const config = useConfig();
    const { state, dispatch } = useIdentityContext();
    const history = useHistory();
    const destinationPath = localStorage.getItem(StorageKeyEnum.OriginalRouteDestination);
    const isMobile = useIsMobile();

    const handleMobilePlatformRedirect = async () => {
        if (window.location.pathname === routingUrls.preferences && isMobile) {
            const search = window.location.search;
            const platform = new URLSearchParams(search).get("platform");
            const isUserAuthorized = state.identity.isAuthenticated;
            if (isUserAuthorized) {
                const destination = `${trimEndSlash(config.authUrls.link)}${platform}`;
                window.location.replace(destination);
                history.push(destination);
                localStorage.setItem(StorageKeyEnum.OriginalRouteDestination, destination);
            }
        }
    };

    useEffect(() => {
        const authorizeUserAsync = async () => {
            const search = location.search;

            if (!search) window.location.href = routingUrls.landing;

            const queryParams = getQueryParams(search);
            if (!queryParams) {
                window.location.href = routingUrls.landing;
            }

            const authCode = queryParams["code"];
            if (!authCode) {
                window.location.href = routingUrls.landing;
            }

            const request: IFetchAccessTokenRequest = {
                authCode: authCode,
                clientId: config.jwtConfig.clientId,
                grantType: config.jwtConfig.grant_type,
                codeVerifier: "",
                redirectUri: config.jwtConfig.redirectUri,
                refreshToken: "",
            };

            try {
                const tokens = await dispatchFetchTokens(dispatch, request);

                setTokenAndUnauthorizedInterceptorIntoAxiosDefaults(tokens.accessToken, dispatch, {
                    ...request,
                    grantType: config.jwtConfig.refresh_grant_type,
                    authCode: "",
                });

                await dispatchFetchIdentity(dispatch);

                const path = destinationPath ? destinationPath : routingUrls.games;
                history.push(path);
                localStorage.removeItem(StorageKeyEnum.OriginalRouteDestination);
                handleMobilePlatformRedirect();
            } catch (e) {
                console.log(`Error during user authorization ${e}`);
                window.location.href = routingUrls.landing;
            }
        };

        authorizeUserAsync();
    }, []);

    return <></>;
};

export default OauthCallback;
