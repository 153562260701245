import { ReactNode, HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { styleGuide } from "theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stickyFooterRoot: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            minHeight: "100vh",
            color: styleGuide.color.white,
            minWidth: 0,
        },
        content: {
            flex: "1 0 auto", // This was 1 1 auto limiting the content to grow on safari.
            display: "flex",
            position: "relative",
            minWidth: 0,
        },
    })
);

interface IStickyFooterProps extends HTMLAttributes<HTMLDivElement> {
    footer: ReactNode;
}

export default function StickyFooter(props: IStickyFooterProps) {
    const classes = useStyles();
    const { children, footer, className, ...rest } = props;

    return (
        <main className={clsx(className, classes.stickyFooterRoot)} {...rest}>
            <div className={classes.content}>{children}</div>
            {footer}
        </main>
    );
}
