import axios from "axios";
import { apiUrls } from "settings";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";
import IIdentityResponse from "./responses/IIdentityResponse";
import { IFetchAccessTokenRequest } from "./requests/IFetchAccessTokenRequest";
import { defaultTokenResponse, IAccessTokenResponse } from "./responses/IAccessTokenResponse";
import IWebAccpetanceTextResponse from "./responses/IWebAcceptanceTextResponse";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import { OnboardingType } from "./types/OnboardingTypes";
import QueryParameter, { constructQueryParameterString } from "apiServices/QueryParameter";
import { IPostNotificationPreferencesRequestBody } from "./requests/IPostNotificationPreferencesRequest";
import { IPostNotificationPreferencesResponse } from "./responses/IPostNotificationPreferences";

export const fetchIdentity = async (): Promise<IIdentityResponse> => {
    const response = await axios
        .get(`${isRaceNetMobileApp || isRaceNetTabletApp ? apiUrls.getMobileIdentity : apiUrls.getIdentity}`, {
            withCredentials: true,
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error", error);
            return error;
        });

    return {
        responseStatus: response.status,
        identity: response.data,
    };
};

export const fetchSecureIdentity = async (): Promise<IIdentityResponse> => {
    let url = axios.defaults.headers.authorization ? apiUrls.identity.getSecuredIdentity : apiUrls.getIdentity;
    const response = await axios.get(`${url}`);

    return {
        responseStatus: response.status,
        identity: { ...response.data, isBetaUser: true },
    };
};

export const fetchAccessToken = async (request: IFetchAccessTokenRequest): Promise<IAccessTokenResponse> => {
    const response = await axios.post<IAccessTokenResponse>(apiUrls.identity.getAccessToken, request, {
        withCredentials: true,
    });

    return response.data;
};

export const refreshAccessToken = async (request: IFetchAccessTokenRequest): Promise<IAccessTokenResponse> => {
    const response = await axios.post<IAccessTokenResponse>(apiUrls.identity.refreshTokens, request, {
        withCredentials: true,
    });

    if (response.status === 204) {
        return defaultTokenResponse;
    }

    return response.data;
};

export const logoutUser = async (): Promise<void> => {
    const response = await axios.post(
        apiUrls.identity.logout,
        {},
        {
            withCredentials: true,
        }
    );
};

export const fetchWebAcceptanceText = async (): Promise<IWebAccpetanceTextResponse | undefined> => {
    const response = await axios.get<IWebAccpetanceTextResponse>(apiUrls.identity.getWebAcceptanceText, {
        validateStatus: function (status) {
            return status < 500;
        },
    });

    if (response.status === ResponseStatusEnum.Ok) return response.data;

    return undefined;
};

export const postWebAcceptance = async (acceptanceId: string): Promise<boolean> => {
    const response = await axios.post(apiUrls.identity.postWebAcceptance(acceptanceId), undefined, {
        validateStatus: function (status) {
            return status < 500;
        },
    });

    if (response.status === ResponseStatusEnum.NoContent) return true;

    return false;
};

export const postOnboarding = async (onboardingTypeId: OnboardingType): Promise<boolean> => {
    const queryParams: QueryParameter[] = [new QueryParameter("onboardingTypeId", onboardingTypeId)];

    const queryParamString = constructQueryParameterString(queryParams);

    const response = await axios.post(
        `${apiUrls.identity.postOnboarding}${queryParamString}`,
        {},
        {
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            },
        }
    );

    if (response.status === ResponseStatusEnum.NoContent) return true;

    return false;
};

export const postNotificationPreferences = async (
    data: IPostNotificationPreferencesRequestBody
): Promise<IPostNotificationPreferencesResponse> => {
    const response = await axios.post(apiUrls.identity.postNotificationPreferences, data, {
        headers: {
            "content-type": "application/json",
        },
    });

    return {
        data: response.data,
        status: response.status,
    };
};

export const fetchLimitedToken = async (): Promise<string> => {
    const response = await axios.get(apiUrls.identity.getLmitedToken);

    return response.data;
};
