import NotificationCard from "./NotificationCard";
import INotification from "../../interfaces/INotification";
import { useHistory } from "react-router-dom";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import { dispatchHandleTray } from "contexts/notification/actions/NotificationActions";

export default function LeagueNotificationCard(notification: INotification) {
    const history = useHistory();
    const { dispatch } = useNotificationContext();

    return (
        <NotificationCard
            notification={notification}
            title={notification.title}
            text={notification.content}
            onClick={() => {
                history.push(`/f1_23/leagues/league/leagueID=${notification.entityID}`);
                dispatchHandleTray(dispatch);
            }}
        />
    );
}
