import { useState, useEffect } from "react";

type ComparisonType = "up" | "down" | "equal";

const useBreakpoint = (value: number, comparisonType: ComparisonType): boolean => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const handleBreakpointChange = (event: MediaQueryListEvent): void => {
            setMatches(event.matches);
        };

        const mediaQueryList =
            comparisonType === "up"
                ? window.matchMedia(`(min-width: ${value}px)`)
                : comparisonType === "down"
                ? window.matchMedia(`(max-width: ${value}px)`)
                : window.matchMedia(`(width: ${value}px)`);

        setMatches(mediaQueryList.matches);
        mediaQueryList.addEventListener("change", handleBreakpointChange);

        return () => {
            mediaQueryList.removeEventListener("change", handleBreakpointChange);
        };
    }, [value, comparisonType]);

    return matches;
};

export default useBreakpoint;
