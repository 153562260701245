import { IPostNotificationPreferencesRequestBody } from "apiServices/identity/requests/IPostNotificationPreferencesRequest";
import { ActionMap } from "common/types/ActionMapType";

enum NotificationPreferencesTypes {
    SetPreferences = "SET_PREFERENCES",
}

type NotificationPreferencesPayload = {
    [NotificationPreferencesTypes.SetPreferences]: IPostNotificationPreferencesRequestBody;
};

export type NotificationPreferencesActions =
    ActionMap<NotificationPreferencesPayload>[keyof ActionMap<NotificationPreferencesPayload>];

export const dispatchSetNotificationPreferences = (
    dispatch: React.Dispatch<any>,
    preferences: IPostNotificationPreferencesRequestBody
) => {
    dispatch({
        type: NotificationPreferencesTypes.SetPreferences,
        payload: preferences,
    });

    return preferences;
};

export const notificationsPreferencesReducer = (
    state: IPostNotificationPreferencesRequestBody,
    action: NotificationPreferencesActions
): IPostNotificationPreferencesRequestBody => {
    switch (action.type) {
        case NotificationPreferencesTypes.SetPreferences:
            return {
                ...state,
                f123: action.payload.f123,
                friendRequests: action.payload.friendRequests,
            };
        default:
            return state;
    }
};
