import { ReactNode, useState, useEffect } from "react";
import { IStyles, styleGuide } from "theme";

export type ButtonVariant =
    | "regular"
    | "outlinedPrimary"
    | "outlinedSecondary"
    | "outlinedBlue"
    | "segmented"
    | "pill"
    | "dialogSelector"
    | "link";
export type ButtonSize = "standard" | "jumbo";
type ButtonType = "button" | "submit";
type ButtonMode = "button" | "toggle";

export interface IButtonProps {
    variant?: ButtonVariant;
    size?: ButtonSize;
    children: ReactNode;
    onClick?: (e: any) => void;
    disabled?: boolean;
    isMobile?: boolean;
    customStyles?: IStyles;
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    type?: ButtonType;
    id?: string;
    mode?: ButtonMode;
    toggled?: boolean;
}

const Button = ({
    variant = "regular",
    size = "standard",
    children,
    onClick,
    disabled = false,
    isMobile = false,
    customStyles,
    endIcon,
    startIcon,
    type = "button",
    id,
    mode = "button",
    toggled = false,
}: IButtonProps) => {
    const [highlighted, setHightlighted] = useState<boolean>(false);

    const { regular, outlinedPrimary, outlinedSecondary, segmented, outlinedBlue, pill, dialogSelector, link } =
        styleGuide.button;

    const styles = {
        regular: {
            default: isMobile ? regular.mobile : size === "jumbo" ? regular.jumbo : regular.default,
            highlighted: {
                ...regular.hover,
            },
            jumbo: {
                ...regular.jumbo,
            },
            disabled: {
                ...regular.inactive,
            },
        },
        outlinedPrimary: {
            default: isMobile
                ? outlinedPrimary.mobile
                : size === "jumbo"
                ? outlinedPrimary.jumbo
                : outlinedPrimary.default,
            highlighted: {
                ...outlinedPrimary.hover,
            },
            jumbo: {
                ...outlinedPrimary.jumbo,
            },
            disabled: {
                ...outlinedPrimary.inactive,
            },
        },
        outlinedSecondary: {
            default: isMobile
                ? outlinedSecondary.mobile
                : size === "jumbo"
                ? outlinedSecondary.jumbo
                : outlinedSecondary.default,
            highlighted: {
                ...outlinedSecondary.hover,
            },
            jumbo: {
                ...outlinedSecondary.jumbo,
            },
            disabled: {
                ...outlinedSecondary.inactive,
            },
        },
        outlinedBlue: {
            default: isMobile ? outlinedBlue.mobile : size === "jumbo" ? outlinedBlue.jumbo : outlinedBlue.default,
            highlighted: {
                ...outlinedBlue.hover,
            },
            jumbo: {
                ...outlinedBlue.jumbo,
            },
            disabled: {
                ...outlinedBlue.inactive,
            },
        },
        segmented: {
            default: isMobile ? segmented.mobile : size === "jumbo" ? segmented.jumbo : segmented.default,
            highlighted: {
                ...segmented.hover,
            },
            jumbo: {
                ...segmented.jumbo,
            },
            disabled: {
                ...segmented.inactive,
            },
        },
        pill: {
            default: isMobile ? pill.mobile : size === "jumbo" ? pill.jumbo : pill.default,
            highlighted: {
                ...pill.hover,
            },
            jumbo: {
                ...pill.jumbo,
            },
            disabled: {
                ...pill.inactive,
            },
        },
        dialogSelector: {
            default: isMobile
                ? dialogSelector.mobile
                : size === "jumbo"
                ? dialogSelector.jumbo
                : dialogSelector.default,
            highlighted: {
                ...dialogSelector.hover,
            },
            jumbo: {
                ...dialogSelector.jumbo,
            },
            disabled: {
                ...dialogSelector.inactive,
            },
        },
        link: {
            default: isMobile ? link.mobile : size === "jumbo" ? link.jumbo : link.default,
            highlighted: {
                ...link.hover,
            },
            jumbo: {
                ...link.jumbo,
            },
            disabled: {
                ...link.inactive,
            },
        },
    };

    const iconStyles: IStyles = {
        display: "flex",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        padding: styleGuide.spacing(),
    };

    const getStyles = (variant: ButtonVariant) => {
        switch (variant) {
            case "outlinedPrimary":
                return styles.outlinedPrimary;
            case "outlinedSecondary":
                return styles.outlinedSecondary;
            case "segmented":
                return styles.segmented;
            case "outlinedBlue":
                return styles.outlinedBlue;
            case "pill":
                return styles.pill;
            case "dialogSelector":
                return styles.dialogSelector;
            case "link":
                return styles.link;
            default:
                return styles.regular;
        }
    };

    const buttonStyles = getStyles(variant);

    const handleOnMouseEnter = () => {
        // TODO Add animation for button click mobile
        if (isMobile || mode === "toggle") return;

        setHightlighted(true);
    };

    const handleOnMouseLeave = () => {
        // TODO Add animation for button click mobile
        if (isMobile || mode === "toggle") return;

        setHightlighted(false);
    };

    useEffect(() => {
        if (mode !== "toggle") return;

        if (toggled) {
            setHightlighted(true);
        } else {
            setHightlighted(false);
        }
    }, [toggled]);

    return (
        <button
            onClick={onClick}
            id={id}
            type={type}
            style={{
                ...buttonStyles.default,
                ...(disabled ? buttonStyles.disabled : highlighted ? buttonStyles.highlighted : null),
                ...customStyles,
            }}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            disabled={disabled}
        >
            {startIcon && <span style={{ ...iconStyles, left: 0 }}>{startIcon}</span>}

            {children}
            {endIcon && <span style={{ ...iconStyles, right: 0 }}>{endIcon}</span>}
        </button>
    );
};

export default Button;
