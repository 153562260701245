import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const TickIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50">
            <path
                d="M25,0C11.19,0,0,11.19,0,25c0,13.81,11.19,25,25,25s25-11.19,25-25C50,11.19,38.81,0,25,0z M21.44,37.09L11.18,26.82
	l4.74-4.74l5.52,5.52l12.63-12.63l4.74,4.74L21.44,37.09z"
            />
        </SvgIcon>
    );
};

export default TickIcon;
