enum Platforms {
    None = 0,
    MicrosoftLive = 1,
    PlaystationNetwork = 2,
    Steam = 3,
    //iPad = 4,
    Stadia = 6,
    Oculus = 7,
    //Rail = 8,
    //Twitch = 9,
    //Feral = 10,
    //NetEase = 11,
    EAPlay = 14,
    WindowsStore = 65,
    PC = 128,
}

export default Platforms;
