import { useNotificationContext } from "contexts/notification/NotificationContext";
import INotification from "../../interfaces/INotification";
import NotificationCard from "./NotificationCard";
import DisplayNameLink from "./components/DisplayNameLink";
import { routingUrls } from "settings";
import { dispatchHandleTray } from "contexts/notification/actions/NotificationActions";

const FriendRequestAcceptedCard = (notification: INotification) => {
    const { dispatch } = useNotificationContext();
    return (
        <NotificationCard
            profileAvatarProps={{
                imageSrc: notification.metadata["SenderAvatar"],
                to: `${routingUrls.profile}/${notification.senderSSID}`,
            }}
            notification={notification}
            text={
                <>
                    <DisplayNameLink
                        displayName={notification.metadata["SenderDisplayName"]}
                        ssid={notification.senderSSID!}
                        onClick={() => dispatchHandleTray(dispatch)}
                    />{" "}
                    has accepted your friend request
                </>
            }
        />
    );
};

export default FriendRequestAcceptedCard;
