import ICookiePreferences from "./ICookiePreferences";

const cookiePreferenceLocalStorageKey = "truste.eu.cookie.notice_gdpr_prefs";

export function getCookiePreferences(): ICookiePreferences | undefined {
    const item = localStorage.getItem(cookiePreferenceLocalStorageKey);

    if (item == null) return undefined;

    return JSON.parse(item);
}
