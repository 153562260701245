import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar, { AvatarProps } from "@material-ui/core/Avatar";
import { images } from "settings";
import clsx from "clsx";
import { useHistory } from "react-router";
import { styleGuide } from "theme";

const getProfileAvatarBorder = (colour: string) => {
    return `solid 2px ${colour}`;
};

interface IStylesOptions {
    borderColor?: string;
}

export type AvatarVariant = "circle" | "circular" | "rounded" | "square";

const useStyles = (options: IStylesOptions) =>
    makeStyles((theme: Theme) => {
        const { borderColor = styleGuide.color.white } = options;

        return createStyles({
            root: {
                border: getProfileAvatarBorder(borderColor),
                color: styleGuide.color.white,
            },
            clickable: {
                cursor: "pointer",
            },
        });
    });

export interface IProfileAvatarProps extends AvatarProps {
    imageSrc?: string;
    borderColor?: string;
    to?: string;
    customVariant?: AvatarVariant;
    borderRadius?: number;
}

export default function ProfileAvatar(props: IProfileAvatarProps) {
    const { borderColor, imageSrc, to, className, onClick, customVariant = "circle", ...rest } = props;
    const classes = useStyles({ borderColor })();

    const history = useHistory();

    return (
        <Avatar
            variant={customVariant}
            className={clsx(className, classes.root, {
                [classes.clickable]: to !== undefined,
            })}
            src={imageSrc && imageSrc != "" ? imageSrc : images.defaultAvatar}
            onClick={(event) => {
                if (onClick !== undefined) {
                    onClick(event);
                }

                if (to !== undefined) {
                    history.push(to);
                }
            }}
            {...rest}
        />
    );
}
