export enum PreferencesValues {
    Required = 0,
    Functional = 1,
    Advertising = 2,
}

export default interface ICookiePreferences {
    expires: number;
    name: string;
    path: string;
    value: string;
}
