import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { Theme, createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import ExclamationIcon from "assets/icons/ExclamationIcon";
import useIsMobile from "hooks/useIsMobile";
import TickIcon from "pages/gamesStats/WRC2023/components/rallyTV/icons/TickIcon";
import { useEffect, useState } from "react";
import { layout, styleGuide } from "theme";

export const snackbarNotificationEventName = "racenet_app_snackbar_notification_event";
export type AppSnackbarType = "success" | "error";

export interface IAppEventDetails {
    content: any;
    callback?: Function;
    snackbarDurationMs?: number;
    type?: AppSnackbarType;
    minHeight?: number;
}

const useStyles = (isMobile: boolean) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                left: isMobile ? styleGuide.spacing(2) : layout.sidebarWidth + styleGuide.spacing(2),
                right: isMobile ? styleGuide.spacing(2) : "inherit",
                bottom: styleGuide.spacing(2),
                minWidth: 200,
                borderRadius: styleGuide.spacing(),
                overflow: "hidden",
                backgroundColor: "transparent",
            },
        })
    );

const SnackbarDurationMs = 3000;
const Interval = 500;

const AppSnackbar = () => {
    const isMobile = useIsMobile();
    const classes = useStyles(isMobile)();

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [eventContent, setEventContent] = useState<IAppEventDetails>();
    const [progress, setProgress] = useState(0);

    const CustomLinearProgress = withStyles((theme) => ({
        root: {
            backgroundColor: eventContent?.type === "error" ? styleGuide.color.richBlack : "#75D05466",
        },
        bar: {
            backgroundColor: eventContent?.type === "error" ? styleGuide.color.red : "#75D054",
        },
    }))(LinearProgress);

    const handleClose = () => {
        setShowSnackbar(false);
        if (eventContent?.callback) {
            eventContent.callback();
        }
    };

    useEffect(() => {
        window.addEventListener(snackbarNotificationEventName, (event) => {
            setEventContent((event as CustomEvent<IAppEventDetails>)?.detail);
            event.stopPropagation();
            setShowSnackbar(true);
        });

        return function cleanup() {
            window.removeEventListener(snackbarNotificationEventName, () => {});
        };
    }, []);

    useEffect(() => {
        if (!showSnackbar) {
            setProgress(0);
            return;
        }

        const step = Math.ceil((Interval * 100) / (eventContent?.snackbarDurationMs ?? SnackbarDurationMs));
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    return 0;
                }
                return Math.min(oldProgress + step, 100);
            });
        }, Interval);

        return () => {
            clearInterval(timer);
        };
    }, [showSnackbar]);

    return (
        <Snackbar
            className={classes.root}
            open={showSnackbar}
            //open={true}
            onClose={handleClose}
            autoHideDuration={eventContent?.snackbarDurationMs ?? SnackbarDurationMs}
            anchorOrigin={
                isMobile
                    ? {
                          vertical: "bottom",
                          horizontal: "center",
                      }
                    : {
                          vertical: "bottom",
                          horizontal: "left",
                      }
            }
        >
            <div
                style={{
                    width: "100%",
                    minHeight: eventContent?.minHeight ? eventContent.minHeight : layout.toolBarHeight,
                    height: "100%",
                    backgroundColor: "#0B0E15",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        top: 0,
                        left: 0,
                        width: "25%",
                        height: "100%",
                        position: "absolute",
                        background:
                            eventContent?.type === "error"
                                ? `linear-gradient(to right, ${styleGuide.color.red}52, ${styleGuide.color.richBlack} 20%)`
                                : "linear-gradient(90deg, #75D054 0%, #0B0E15 100%)",
                        opacity: 0.33,
                        paddingBottom: isMobile ? styleGuide.spacing() : 0,
                        borderRadius: isMobile ? 0 : styleGuide.borderRadius / 2,
                    }}
                />
                <div style={{ display: "flex", gap: styleGuide.spacing(2), zIndex: 2, marginLeft: 16 }}>
                    {eventContent?.type === "error" ? (
                        <ExclamationIcon style={{ color: styleGuide.color.red }} />
                    ) : (
                        <TickIcon style={{ color: "#75D054" }} />
                    )}
                    {eventContent?.content}
                </div>
                <div style={{ position: "absolute", left: 0, bottom: 0, zIndex: 2, width: "100%" }}>
                    <CustomLinearProgress variant="determinate" value={progress} />
                </div>
            </div>
        </Snackbar>
    );
};

export default AppSnackbar;
