// https://css-tricks.com/aspect-ratio-boxes/#article-header-id-3
interface IProps {
    height: number;
    width: number;
}

export default function calculateAspectRatioPaddingTopPercentage(props: IProps): string {
    const { height, width } = props;

    return `${(height / width) * 100}%`;
}
