import { useConfig } from "contexts/configuration/ConfigurationContext";
import Button from "common/components/uiComponents/buttons/Button";
import { IStyles } from "theme";

interface IProps {
    isMobile: boolean;
    style?: IStyles;
    text?: string;
}

const NewLoginSignupButton = ({ isMobile, style, text = "SIGN IN" }: IProps) => {
    const config = useConfig();

    const handleLogin = () => {
        let jwtConfig = config.jwtConfig;

        let url =
            `${config.authUrls.loginJwt}client_id=${jwtConfig.clientId}&` +
            `response_type=${jwtConfig.response_type}&` +
            `redirect_uri=${jwtConfig.redirectUri}`;

        window.location.href = url;
    };

    return (
        <>
            <Button customStyles={style} onClick={handleLogin} size={"jumbo"} isMobile={isMobile}>
                {text}
            </Button>
        </>
    );
};

export default NewLoginSignupButton;
