import { ReactElement } from "react";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";

interface IProps {
    hidden?: boolean;
    children: ReactElement<any, any>;
}

export default function HideableCard(props: IProps) {
    const { hidden, children } = props;

    const transitionTimeout = 800;

    return (
        <Collapse in={!hidden} timeout={transitionTimeout} unmountOnExit>
            <Fade in={!hidden} timeout={{ appear: 0, exit: transitionTimeout }}>
                {children}
            </Fade>
        </Collapse>
    );
}
