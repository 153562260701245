import { HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import StyledCircularProgress, { IStyledCircularProgressProps } from "./StyledCircularProgress";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        pageCircularProgress: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            margin: theme.spacing(2, 0),
        },
        primary: {
            color: theme.palette.info.main,
        },
    });
});

interface IProps extends HTMLAttributes<HTMLDivElement> {
    circularProgressProps?: IStyledCircularProgressProps;
}

export default function PageCircularProgress(props: IProps) {
    const { className, circularProgressProps, ...rest } = props;

    const classes = useStyles(props);

    return (
        <div className={clsx(className, classes.pageCircularProgress)} {...rest}>
            <StyledCircularProgress classes={{ colorPrimary: classes.primary }} {...circularProgressProps} />
        </div>
    );
}
