import { HTMLAttributes } from "react";
import { routingUrls } from "settings";
import ProfileIcon from "assets/icons/navBar/ProfileIcon";
import GamePadIcon from "assets/icons/navBar/GamePadIcon";
import useIsMobile from "hooks/useIsMobile";
import INavigationItem from "./INavigationItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DesktopNavigation from "./desktop/DesktopNavigation";
import MobileNavigation from "./mobile/MobileNavigation";
import clsx from "clsx";
import PageLayout from "containers/layout/PageLayout";
import FriendsIcon from "assets/icons/profileStats/FriendsIcon";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: "flex",
            position: "relative",
            minWidth: 0,
        },
    });
});

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export default function AuthorisedNavigationBar(props: IProps) {
    const { className, children, ...rest } = props;
    const classes = useStyles();
    const isMobile = useIsMobile();

    const navItemIconFontSize = "inherit";
    const navigationItems: INavigationItem[] = [
        {
            icon: <GamePadIcon fontSize={navItemIconFontSize} style={{ marginBottom: -4 }} />,
            label: "Games",
            href: routingUrls.games,
            matchExactPath: true,
        },
        {
            icon: <FriendsIcon fontSize={navItemIconFontSize} />,
            label: "Friends",
            href: routingUrls.friends,
            matchExactPath: true,
        },
        {
            icon: <ProfileIcon fontSize={navItemIconFontSize} style={{ paddingBottom: 2 }} />,
            label: "Profile",
            href: routingUrls.profile,
            matchExactPath: true,
        },
    ];

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            {isRaceNetMobileApp || isRaceNetTabletApp ? null : isMobile ? (
                <MobileNavigation {...rest} />
            ) : (
                <DesktopNavigation items={navigationItems} {...rest} />
            )}
            <PageLayout>
                {/* {!isRaceNetMobileApp && isMobile && <Spacer size={isMobile ? 2 : 0} />} */}
                {children}
            </PageLayout>
        </div>
    );
}
