import { useEffect, useState } from "react";
import INotification from "../../interfaces/INotification";
import NotificationCard from "./NotificationCard";
import DisplayNameLink from "./components/DisplayNameLink";
import { routingUrls } from "settings";
import * as friendsApiService from "apiServices/friends/FriendsApiService";
import {
    dispatchAddActedFriendRequestsSsid,
    dispatchAddReceivedFriendRequestsSsid,
    dispatchHandleTray,
    dispatchSetSkip,
} from "contexts/notification/actions/NotificationActions";
import { postAnalyticsEvent } from "utils/analyticsHelper";
import { useNotificationContext } from "contexts/notification/NotificationContext";

type Status = "pending" | "accepted" | "rejected";

const FriendRequestCard = (notification: INotification) => {
    const { state, dispatch } = useNotificationContext();

    useEffect(() => {
        dispatchAddReceivedFriendRequestsSsid(
            dispatch,
            notification.senderSSID!,
            state.notificationStore.actedFriendRequestSsids
        );
    }, []);

    const [status, setStatus] = useState<Status>("pending");

    useEffect(() => {
        if (status === "accepted" || status === "rejected") {
            dispatchSetSkip(
                dispatch,
                notification.notificationID,
                notification.read,
                // Old must come before new!
                state.oldNotificationStore.notifications,
                state.newNotificationStore.notifications
            );
        }
    }, [status]);

    return (
        <NotificationCard
            profileAvatarProps={{
                imageSrc: notification.metadata["SenderAvatar"],
                to: `${routingUrls.profile}/${notification.senderSSID}`,
            }}
            notification={notification}
            text={
                status === "accepted" || notification.skip ? (
                    <>
                        You accepted{" "}
                        <DisplayNameLink
                            displayName={notification.metadata["SenderDisplayName"]}
                            ssid={notification.senderSSID!}
                            onClick={() => dispatchHandleTray(dispatch)}
                        />
                        's friend request
                    </>
                ) : status === "rejected" || notification.skip ? (
                    <>
                        You declined{" "}
                        <DisplayNameLink
                            displayName={notification.metadata["SenderDisplayName"]}
                            ssid={notification.senderSSID!}
                            onClick={() => dispatchHandleTray(dispatch)}
                        />
                        's friend request
                    </>
                ) : (
                    <>
                        <DisplayNameLink
                            displayName={notification.metadata["SenderDisplayName"]}
                            ssid={notification.senderSSID!}
                            onClick={() => dispatchHandleTray(dispatch)}
                        />{" "}
                        has sent you a friend request
                    </>
                )
            }
            actions={
                status === "pending" && !notification.skip
                    ? [
                          {
                              label: "Accept",
                              onClick: () => {
                                  setStatus("accepted");
                                  postAnalyticsEvent("notification.friend_request.accept");
                                  friendsApiService
                                      .acceptFriendRequest(notification.senderSSID!)
                                      .then(() =>
                                          dispatchAddActedFriendRequestsSsid(
                                              dispatch,
                                              notification.senderSSID!,
                                              state.notificationStore.actedFriendRequestSsids
                                          )
                                      );
                              },
                          },
                          {
                              label: "Reject",
                              onClick: () => {
                                  setStatus("rejected");
                                  postAnalyticsEvent("notification.friend_request.reject");
                                  friendsApiService
                                      .declineFriendRequest(notification.senderSSID!)
                                      .then(() =>
                                          dispatchAddActedFriendRequestsSsid(
                                              dispatch,
                                              notification.senderSSID!,
                                              state.notificationStore.actedFriendRequestSsids
                                          )
                                      );
                              },
                          },
                      ]
                    : []
            }
        />
    );
};

export default FriendRequestCard;
