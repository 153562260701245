import ErrorPage from "./ErrorPage";

export default function InternalServerErrorPage() {
    return (
        <ErrorPage
            title="Red Flag"
            text={
                "Looks like something has gone wrong with EA Racenet. We’re working hard to resolve the issue, please try again later."
            }
        />
    );
}
