import { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { useTheme } from "@material-ui/core/styles";
import { styleGuide } from "theme";

const OfflineBanner = () => {
    const [banner, setBanner] = useState(false);
    const [onlineStatus, setOnlineStatus] = useState(true);
    const theme = useTheme();
    const handleClose = () => {
        setBanner(true);
    };
    const closeButton = (
        <IconButton onClick={handleClose} style={{ padding: 5 }}>
            <CancelIcon style={{ color: styleGuide.color.white }} />
        </IconButton>
    );

    useEffect(() => {
        window.addEventListener("online", () => setOnlineStatus(true));
        window.addEventListener("offline", () => setOnlineStatus(false));

        return function cleanup() {
            window.removeEventListener("online", () => {});
            window.removeEventListener("offline", () => {});
        };
    }, []);

    return (
        <>
            <Snackbar
                open={!onlineStatus && !banner}
                onClose={handleClose}
                message="You are offline!"
                autoHideDuration={10000}
                action={closeButton}
            />
            {!onlineStatus && banner && (
                <div
                    style={{
                        position: "fixed",
                        bottom: 0,
                        width: "100%",
                        height: 15,
                        backgroundColor: theme.palette.primary.dark,
                        zIndex: 1000,
                        color: styleGuide.color.white,
                        textAlign: "center",
                        fontFamily: theme.typography.body1.fontFamily,
                        fontSize: 12,
                    }}
                >
                    Offline
                </div>
            )}
        </>
    );
};
export default OfflineBanner;
