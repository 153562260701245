import Typography from "@material-ui/core/Typography";
import GamesEnum from "common/enums/GamesEnum";
import { forwardRef, useEffect, useMemo } from "react";
import { styleGuide } from "theme";
import { SCROLL_OFFSET, SET_SELECTED_MENU_EVENT } from "../hero/HeroDesktop";
import { ITheme, useMediaQuery, useTheme } from "@material-ui/core";

interface IProps {
    isMobile: boolean;
    cdnPath: string;
    title: string;
    description: string;
    game: GamesEnum;
    id: string;
}

const SectionTimetrialAnalysis = forwardRef(({ isMobile, cdnPath, title, description, game, id }: IProps, ref: any) => {
    const theme: ITheme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    const background = useMemo(() => {
        if (game === GamesEnum.WRC2023)
            return isMobile
                ? `${cdnPath}/analysis_wrc_mobile.jpg`
                : sm
                ? `${cdnPath}/analysis_wrc_tab.jpg`
                : `${cdnPath}/analysis_wrc.jpg`;

        return isMobile
            ? `${cdnPath}/analysis_f1_mobile.jpg`
            : sm
            ? `${cdnPath}/analysis_f1_tab.jpg`
            : `${cdnPath}/analysis_f1.jpg`;
    }, [isMobile, sm, game]);

    useEffect(() => {
        const sendSelectedMenu = () => {
            if (
                window.scrollY + SCROLL_OFFSET >= ref.current.offsetTop &&
                window.scrollY + SCROLL_OFFSET < ref.current.offsetTop + ref.current.clientHeight
            ) {
                window.dispatchEvent(new CustomEvent(SET_SELECTED_MENU_EVENT, { detail: id, bubbles: false }));
            }
        };

        window.addEventListener("scroll", sendSelectedMenu);

        return () => window.removeEventListener("scroll", sendSelectedMenu);
    }, [ref]);

    return (
        <div
            ref={ref}
            style={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : sm ? "1fr 1fr" : "2fr 3fr",
                width: "100%",
                background: `#0D1042 url("${background}") no-repeat`,
                backgroundSize: "cover",
                alignItems: "end",
                justifyContent: "center",
                backgroundPositionX: isMobile ? "60%" : sm ? "40%" : "0%",
                backgroundPositionY: sm ? "center" : "auto",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: styleGuide.spacing(0.5),
                    padding: isMobile
                        ? `120% ${styleGuide.spacing(2)}px ${styleGuide.spacing(8)}px ${styleGuide.spacing(2)}px`
                        : `40% ${styleGuide.spacing(2)}px`,
                    maxWidth: 420,
                    justifySelf: "center",
                }}
            >
                <Typography
                    style={{
                        ...(isMobile ? styleGuide.typography.heading : styleGuide.typography.title),
                        textAlign: isMobile ? "center" : "start",
                    }}
                >
                    {title}
                </Typography>
                <Typography style={{ ...styleGuide.typography.copyText, textAlign: isMobile ? "center" : "start" }}>
                    {description}
                </Typography>
            </div>
        </div>
    );
});

export default SectionTimetrialAnalysis;
