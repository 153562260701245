import useIsMobile from "./useIsMobile";
import { useEffect, useState } from "react";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";

const useIsLandscape = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);

    const mobile = useIsMobile() || isRaceNetMobileApp || isRaceNetTabletApp;
    const isLandscape = mobile && width > height;

    const update = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", update);
        return () => window.removeEventListener("resize", update);
    });

    return isLandscape;
};
export default useIsLandscape;
