import { IIdentityStore, IJwtTokens, IJwtTokensStore, IUserIdentityStore } from "../IdentityStore";
import { ActionMap } from "../../../common/types/ActionMapType";
import { Dispatch } from "react";
import { IFetchAccessTokenRequest } from "../../../apiServices/identity/requests/IFetchAccessTokenRequest";
import { fetchAccessToken, refreshAccessToken } from "../../../apiServices/identity/IdentityApiService";

export enum JwtTypes {
    FetchTokens = "FETCH_TOKENS",
    FetchTokensCompleted = "FETCH_TOKENS_COMPLETED",
    RefreshTokens = "REFRESH_TOKENS",
    RefreshTokensCompleted = "REFRESH_TOKENS_COMPLETED",
}

type JwtTypePayload = {
    [JwtTypes.FetchTokens]: IJwtTokensStore;
    [JwtTypes.FetchTokensCompleted]: IJwtTokensStore;
    [JwtTypes.RefreshTokens]: IJwtTokensStore;
    [JwtTypes.RefreshTokensCompleted]: IJwtTokensStore;
};

export type JwtActions = ActionMap<JwtTypePayload>[keyof ActionMap<JwtTypePayload>];

export const dispatchFetchTokens = async (
    dispatch: Dispatch<any>,
    request: IFetchAccessTokenRequest
): Promise<IJwtTokens> => {
    dispatch({
        type: JwtTypes.FetchTokens,
    });

    const response = await fetchAccessToken(request);

    dispatch({
        type: JwtTypes.FetchTokensCompleted,
        payload: {
            tokens: {
                accessToken: response.access_token,
                refreshToken: response.refresh_token,
            },
        },
    });

    return {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
    };
};

export const dispatchRefreshTokens = async (
    dispatch: Dispatch<any>,
    request: IFetchAccessTokenRequest
): Promise<IJwtTokens> => {
    dispatch({
        type: JwtTypes.RefreshTokens,
    });

    const response = await refreshAccessToken(request);
    if (!response) {
        throw new Error("Error which retrieving refresh token");
    }

    dispatch({
        type: JwtTypes.RefreshTokensCompleted,
        payload: {
            tokens: {
                accessToken: response.access_token,
                refreshToken: response.refresh_token,
            },
        },
    });

    return {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
    };
};

export const jwtTokensReducer = (state: IJwtTokensStore, action: JwtActions): IJwtTokensStore => {
    switch (action.type) {
        case JwtTypes.FetchTokens:
            return {
                ...state,
                loading: true,
            };
        case JwtTypes.FetchTokensCompleted:
            return {
                ...action.payload,
                loading: false,
                hasFetched: true,
            };
        case JwtTypes.RefreshTokens:
            return {
                ...state,
                loading: true,
            };
        case JwtTypes.RefreshTokensCompleted:
            return {
                ...action.payload,
                loading: false,
                hasFetched: true,
            };
        default:
            return state;
    }
};
