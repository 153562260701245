import React, { ReactNode } from "react";
import StyledButton, { IStyledButtonProps } from "./StyledButton";
import RouterLink from "common/components/links/RouterLink";

interface IProps extends IStyledButtonProps {
    children?: ReactNode;
    to: string;
}

export default function RouterLinkButton(props: IProps) {
    const { children, to, ...rest } = props;

    return (
        <StyledButton
            //@ts-ignore
            component={RouterLink}
            to={to}
            {...rest}
        >
            {children}
        </StyledButton>
    );
}
