import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { color: "inherit" },
        underlineNone: {
            textDecoration: "none",
        },
        underlineHover: {
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            },
        },
        underlineAlways: {
            textDecoration: "underline",
        },
    })
);
interface IProps extends LinkProps {
    underline?: "none" | "hover" | "always";
}

export default function RouterLink(props: IProps) {
    const classes = useStyles();
    const { children, underline = "none", className, ...rest } = props;
    return (
        <Link
            className={clsx(className, classes.root, {
                [classes.underlineNone]: underline === "none",
                [classes.underlineHover]: underline === "hover",
                [classes.underlineAlways]: underline === "always",
            })}
            {...rest}
        >
            {children}
        </Link>
    );
}
