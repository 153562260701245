import { routingUrls } from "settings";

export const isAuthorizedRoute = (path: string) => {
    let isRoute = false;

    Object.values(routingUrls).every((v) => {
        if (typeof v === "string") {
            if (v === path) {
                isRoute = true;
                return false;
            }

            return true;
        }

        if (typeof v === "object") {
            Object.values(v).every((vv) => {
                if (path.indexOf(vv) > -1) {
                    isRoute = true;
                    return false;
                }

                return true;
            });

            if (isRoute) return false;

            return true;
        }
    });

    return isRoute;
};
