import { useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { isIOS } from "react-device-detect";

// To be completed - Adds a bottom space for all screen mobile devices like iPhone X
// and newer to avoid the devices bottom line call to action
// https://github.com/MauriceConrad/detect-ios-address-bar

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomNavSpacerCollapsed: {
            minHeight: 0,
            transition: "min-height .1s linear",
        },
        bottomNavSpacerExpanded: {
            transition: "min-height .4s linear",
            minHeight: 14,
        },
    })
);

export default function MobileBottomNavSpacer() {
    const classes = useStyles();
    const [deviceHeight, setDeviceHeight] = useState<number>(window.innerHeight);
    const [addSpacing, setAddSpacing] = useState(false);

    const isInStandaloneMode: boolean =
        (window.matchMedia("(display-mode: standalone)").matches ||
            /*@ts-ignore*/
            window.navigator.standalone) ??
        false;

    const allScreenDevice = isIOS && deviceHeight > 630;

    useEffect(() => {
        const al = () => {
            if (isIOS && deviceHeight)
                if (window.innerHeight > deviceHeight) {
                    setAddSpacing(true);
                    return;
                }
            setAddSpacing(false);
        };
        window.addEventListener("resize", al);
        return () => window.removeEventListener("resize", al, false);
    }, []);

    return allScreenDevice ? (
        <div
            className={clsx(classes.bottomNavSpacerCollapsed, {
                [classes.bottomNavSpacerExpanded]: isInStandaloneMode || addSpacing,
            })}
        />
    ) : null;
}
