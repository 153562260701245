import Spacer from "common/components/Spacer";
import SkeletonElement from "common/components/skeleton/SkeletonElement";
import { styleGuide } from "theme";

interface IProps {
    isMobile?: boolean;
}

const ProfileDrawerSkeleton = ({ isMobile = false }: IProps) => {
    return isMobile ? (
        <>
            <SkeletonElement
                skeletonType="box"
                height={200}
                width={"100%"}
                backgroundColor={styleGuide.color.richGrey}
            />
            <Spacer />
            <SkeletonElement
                skeletonType="box"
                height={80}
                width={"100%"}
                backgroundColor={styleGuide.color.richGrey}
            />
        </>
    ) : (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: styleGuide.spacing(4), height: "100%" }}>
            <SkeletonElement
                skeletonType="box"
                height={"100%"}
                width={"100%"}
                backgroundColor={styleGuide.color.richGrey}
            />
            <SkeletonElement
                skeletonType="box"
                height={"100%"}
                width={"100%"}
                backgroundColor={styleGuide.color.richGrey}
            />
        </div>
    );
};

export default ProfileDrawerSkeleton;
