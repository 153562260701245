import { WebViewEnum } from "common/enums/WebViewEventsEnum";
import { mobileAppUserAgent, tabletAppUserAgent } from "settings";

export const isRaceNetMobileApp = window.navigator.userAgent === mobileAppUserAgent;
export const isRaceNetTabletApp = window.navigator.userAgent === tabletAppUserAgent;

export const webViewPostMessage = (message: string) => {
    if (isRaceNetMobileApp || tabletAppUserAgent) {
        try {
            //@ts-ignore
            window.ReactNativeWebView?.postMessage(message);
        } catch (error) {
            console.log("Error sending the ReactNativeWebView post message", error);
        }
    }
};

export const webViewRouteToNative = (routeInApp: string, params: string) => {
    webViewPostMessage(
        WebViewEnum.NativeRoute +
            `{ 
            "route" : "${routeInApp}",
            "params" : "${params}"
        }`
    );
};
