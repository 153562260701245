import axios from "axios";
import { ILandingPageCdnConfig } from "./responses/ILandigPageCdnConfigResponse";
import { IFeeatureCdnConfigResponse } from "./responses/IFeatureCdnConfigResponse";
import IWRCRallyTVConfigResponse from "./responses/IWRCRallyTVConfigResponse";
import { cdnPaths } from "settings";

const instance = axios.create();

export const getLandingCdnConfig = async (cdnBaseUrl: string): Promise<ILandingPageCdnConfig> =>
    (await instance.get<ILandingPageCdnConfig>(`${cdnBaseUrl}/Landing_Page/landing_content.json`))?.data;

export const getFeaturesConfig = async (filePath: string): Promise<IFeeatureCdnConfigResponse> =>
    (await instance.get<IFeeatureCdnConfigResponse>(filePath))?.data;

export const getRallyTVConfig = async (cdnBaseUrl: string): Promise<IWRCRallyTVConfigResponse> =>
    (await instance.get<IWRCRallyTVConfigResponse>(`${cdnBaseUrl}/${cdnPaths.wrc2023.rallyTVConfig}/rally-tv.json`))
        ?.data;
