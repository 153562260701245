import "./common/polyfills/DragDropTouch";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { analyticsIds, settings } from "settings";
import GlobalCss from "./GlobalCss";
import CookieConsent from "common/components/cookieConsent/CookieConsent";
import TrainTrackProvider from "hooks/useTrainTracks/TrainTrackProvider";
import { CustomThemeProvider } from "contexts/theme/ThemeContext";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics, Analytics, setUserId } from "firebase/analytics";
import { ConfigurationContextProvider } from "contexts/configuration/ConfigurationContext";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";
import { CookieContextProvider } from "common/components/cookieConsent/CookieContext";
import { PreferencesValues } from "common/components/cookieConsent/ICookiePreferences";

const firebaseConfig = {
    apiKey: "AIzaSyCmnihukNeRSP3t4M-SNhJvDzWmu0Wjkls",
    authDomain: "racenet-app.firebaseapp.com",
    projectId: "racenet-app",
    storageBucket: "racenet-app.appspot.com",
    messagingSenderId: "295842656078",
    appId: "1:295842656078:web:8d911d110f2d7c71a1b81f",
    measurementId: "G-VEGXVNQ9M9",
};

export let analytics: Analytics;

export let firebaseApp: FirebaseApp;

export const initialiseFirebase = () => {
    if (!firebaseApp) {
        firebaseApp = initializeApp(firebaseConfig);
    }

    analytics = getAnalytics(firebaseApp);

    //@ts-ignore
    const ssid = document.getElementById(analyticsIds.identitySsid)?.value;
    if (analytics !== undefined && ssid !== undefined) {
        setUserId(analytics, ssid);
    }

    //@ts-ignore
    window.dataLayer = window.dataLayer || [];
};

const app = (
    <ConfigurationContextProvider>
        <TrainTrackProvider>
            <GlobalCss>
                <CustomThemeProvider>
                    <CookieContextProvider
                        onPreferencesUpdate={(preferences) => {
                            if (preferences?.value.includes(PreferencesValues.Functional.toString())) {
                                initialiseFirebase();
                            }
                        }}
                    >
                        {!isRaceNetMobileApp && !isRaceNetTabletApp && <CookieConsent />}
                        <BrowserRouter basename={settings.basePath}>
                            <App />
                        </BrowserRouter>
                    </CookieContextProvider>
                </CustomThemeProvider>
            </GlobalCss>
        </TrainTrackProvider>
    </ConfigurationContextProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
