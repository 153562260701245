import { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LandingPage from "pages/landing/LandingPage";
import { routingUrls } from "settings";
import { useConfig } from "contexts/configuration/ConfigurationContext";
import OauthCallback from "../pages/oauth/pages/OauthCallback";
import { useHistory } from "react-router-dom";
import { useIdentityContext } from "../contexts/identity/IdentityContext";
import { isAuthorized } from "../utils/axiosTools";
import { IFetchAccessTokenRequest } from "../apiServices/identity/requests/IFetchAccessTokenRequest";
import StorageKeyEnum from "../common/enums/StorageKeyEnum";
import { isAuthorizedRoute } from "utils/routeHelper";

export default function AnonymousRoutes() {
    const config = useConfig();
    const history = useHistory();
    const identityContext = useIdentityContext();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const authRequest: IFetchAccessTokenRequest = {
            clientId: config.jwtConfig.clientId,
            grantType: config.jwtConfig.refresh_grant_type,
            refreshToken: "",
            redirectUri: config.jwtConfig.redirectUri,
            authCode: "",
            codeVerifier: "",
        };

        const checkIsAuthorized = async () => {
            if (
                window.location.pathname !== routingUrls.landing &&
                window.location.pathname !== routingUrls.landingWRC &&
                window.location.pathname !== routingUrls.landingF12023 &&
                window.location.pathname !== routingUrls.oauthCallback &&
                window.location.pathname !== routingUrls.loggedOut &&
                isAuthorizedRoute(window.location.pathname)
            ) {
                localStorage.setItem(
                    StorageKeyEnum.OriginalRouteDestination,
                    window.location.pathname + window.location.hash
                );
                const isUserAuthorized = await isAuthorized(identityContext.dispatch, authRequest);
                if (isUserAuthorized) {
                    const destination =
                        localStorage.getItem(StorageKeyEnum.OriginalRouteDestination) ?? routingUrls.profile;
                    history.push(destination);
                    localStorage.removeItem(StorageKeyEnum.OriginalRouteDestination);
                }
            }
        };

        checkIsAuthorized().finally(() => setIsLoading(false));
    }, []);

    return (
        <>
            {!isLoading && (
                <Switch>
                    <Route path={routingUrls.oauthCallback} exact>
                        <OauthCallback />
                    </Route>
                    <Route path={routingUrls.landing} exact>
                        <LandingPage />
                    </Route>
                    <Route path={routingUrls.landingF12023} exact>
                        <LandingPage variant="f12023" />
                    </Route>
                    <Route path={routingUrls.landingWRC} exact>
                        <LandingPage variant="wrc" />
                    </Route>
                    <Redirect to={routingUrls.landing} />
                </Switch>
            )}
        </>
    );
}
