import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ExclamationIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg id="OnboardingIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
                <defs>
                    <style></style>
                </defs>
                <g id="Group_1701">
                    <path
                        id="Path_514"
                        className="cls-1"
                        d="m23,0C10.3,0,0,10.3,0,23s10.3,23,23,23,23-10.3,23-23S35.7,0,23,0m-2.86,8.6c.19-.21.47-.32.75-.32h4.22c.56,0,1.02.46,1.02,1.02,0,.02,0,.04,0,.06l-1.05,17.5c-.03.54-.48.96-1.02.96h-2.13c-.54,0-.98-.42-1.02-.96l-1.05-17.5c-.02-.28.08-.56.28-.77m2.84,29.12c-1.94,0-3.51-1.57-3.51-3.51,0-1.94,1.57-3.51,3.51-3.51s3.51,1.57,3.51,3.51c-.02,1.93-1.58,3.5-3.51,3.51"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export default ExclamationIcon;
