import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import IconButton from "@material-ui/core/IconButton";
import NotificationsPopover from "./NotificationsPopover";
import NotificationsBadge from "common/components/notifications/NotificationsBadge";
import { ITheme } from "@material-ui/core";
import { postAnalyticsEvent } from "utils/analyticsHelper";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import { dispatchHandleTray } from "contexts/notification/actions/NotificationActions";
import { styleGuide } from "theme";

const useStyles = makeStyles((theme: ITheme) => {
    return createStyles({
        notificationIcon: {
            backgroundColor: styleGuide.color.white,
            color: theme.palette.info.main,
            boxShadow: theme.styles.defaultBoxShadow,
            "&:hover": {
                backgroundColor: theme.palette.info.main,
                color: styleGuide.color.white,
            },
        },
        badge: {
            right: -4,
            top: -4,
        },
    });
});

export default function Notifications() {
    const classes = useStyles();
    const { state, dispatch } = useNotificationContext();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleOpenNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
        postAnalyticsEvent("notification.click");
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (!state.notificationStore.openTray) {
            handleClose();
        }
    }, [state.notificationStore.openTray]);

    return (
        <>
            <IconButton
                className={classes.notificationIcon}
                onClick={(e) => {
                    handleOpenNotification(e);
                    !state.notificationStore.openTray && dispatchHandleTray(dispatch);
                }}
                color="inherit"
                disableFocusRipple
            >
                <NotificationsBadge classes={{ badge: classes.badge }}>
                    <NotificationsIcon />
                </NotificationsBadge>
            </IconButton>
            <NotificationsPopover open={open} anchorEl={anchorEl} onClose={handleClose} />
        </>
    );
}
