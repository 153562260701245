import Img from "common/components/img/Img";

interface IProps {
    laptopImg: string;
    mobileImg: string;
    sm?: boolean;
}

const HeroImageDesktop = ({ laptopImg, mobileImg, sm }: IProps) => {
    return (
        <div
            style={{
                display: "flex",
                position: "relative",
                justifyContent: "end",
                width: "100%",
                right: sm ? "-5%" : "-14.5%",
                marginBottom: "6%",
            }}
        >
            <Img src={laptopImg} width={1025} height={651} style={{ width: "80%" }} disableLazyLoad />
            <Img
                src={mobileImg}
                width={295}
                height={515}
                style={{ width: "26%", position: "absolute", bottom: "-6%", right: "65%" }}
                disableLazyLoad
            />
        </div>
    );
};

export default HeroImageDesktop;
