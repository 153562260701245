import React, { CSSProperties } from "react";

import { Fragment } from "react";
import { HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { styleGuide } from "theme";

const useStyles = makeStyles((theme) => {
    return createStyles({
        "@global": {
            "::selection": {
                backgroundColor: styleGuide.color.white,
                color: theme.palette.primary.main,
            },
        },
    });
});

export default function GlobalCss(props: HTMLAttributes<HTMLElement>) {
    useStyles();
    return <Fragment {...props} />;
}

export function customScrollbar(theme: Theme) {
    const scrollBarColor = theme.palette.info.light;
    const scrollBarTrackColor = styleGuide.color.white;
    const thickness = 7;

    return {
        "&::-webkit-scrollbar": { width: thickness },
        "&::-webkit-scrollbar-track": { background: scrollBarTrackColor },
        "&::-webkit-scrollbar-thumb": {
            background: scrollBarColor,
            borderRadius: thickness,
        },
        scrollbarWidth: "thin",
        scrollbarColor: `${scrollBarColor} ${scrollBarTrackColor}`,
    } as CSSProperties;
}
