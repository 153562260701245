import Link, { LinkProps } from "@material-ui/core/Link";

export interface INewTabLinkProps extends LinkProps {}

export default function NewTabLink(props: INewTabLinkProps) {
    const { children, ...rest } = props;

    return (
        <Link target="_blank" rel="noreferrer" {...rest}>
            {children}
        </Link>
    );
}
