import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import useTrainTracks, { TrainTrackLayer } from "./useTrainTracks";

const useStyles = (paddingSize: number) =>
    makeStyles((theme: Theme) => {
        return createStyles({
            responsivePadding: {
                padding: paddingSize,
            },
        });
    });

interface IProps {
    layer?: TrainTrackLayer;
}

export default function useResponsivePaddingClass(props: IProps = {}) {
    const { layer = "0" } = props;

    const trainTracks = useTrainTracks();

    const classes = useStyles(trainTracks[layer].padding)();

    return classes.responsivePadding;
}
