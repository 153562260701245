import { HTMLAttributes } from "react";
import useTrainTracks, { TrainTrackLayer } from "hooks/useTrainTracks";
import Spacer from "./Spacer";

interface IProps extends HTMLAttributes<HTMLDivElement> {
    layer?: TrainTrackLayer;
}

export default function ResponsiveSpacer(props: IProps) {
    const trainTracks = useTrainTracks();

    const { layer = "0", ...rest } = props;

    return <Spacer size={trainTracks[layer].padding} disableTheme {...rest} />;
}
