import Typography from "@material-ui/core/Typography";
import Spacer from "common/components/Spacer";
import Img from "common/components/img/Img";
import GamesEnum from "common/enums/GamesEnum";
import { forwardRef, useEffect, useMemo } from "react";
import { styleGuide } from "theme";
import { SCROLL_OFFSET, SET_SELECTED_MENU_EVENT } from "../hero/HeroDesktop";

interface IProps {
    isMobile: boolean;
    cdnPath: string;
    game: GamesEnum;
    playStore: string;
    iOsUrl: string;
    title: string;
    description: string;
    id: string;
}

const SectionRacenetAppFooter = forwardRef(
    ({ isMobile, cdnPath, game, playStore, iOsUrl, title, description, id }: IProps, ref: any) => {
        const handleRedirectToPlayStore = () => {
            window.open(playStore, "_blank");
        };

        const handleRedirectToAppStore = () => {
            window.open(iOsUrl, "_blank");
        };

        const image = useMemo(() => {
            if (game === GamesEnum.WRC2023) return `${cdnPath}/appscreens_wrc.png`;

            return `${cdnPath}/appscreens_f1.png`;
        }, [game]);

        useEffect(() => {
            const sendSelectedMenu = () => {
                if (
                    window.scrollY >= ref.current.offsetTop - (isMobile ? 0 : ref.current.clientHeight / 2) &&
                    window.scrollY + SCROLL_OFFSET < ref.current.offsetTop + ref.current.clientHeight
                ) {
                    window.dispatchEvent(new CustomEvent(SET_SELECTED_MENU_EVENT, { detail: id, bubbles: false }));
                }
            };

            window.addEventListener("scroll", sendSelectedMenu);

            return () => window.removeEventListener("scroll", sendSelectedMenu);
        }, [ref]);

        return (
            <div
                ref={ref}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: `${styleGuide.spacing(8)}px 0`,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        background: `${styleGuide.color.grey} url("${cdnPath}/${
                            isMobile ? "bg_lines.png" : "bg_lines_desktop.png"
                        }") no-repeat`,
                        backgroundSize: isMobile ? "200%" : "100%",
                        backgroundPosition: isMobile ? "50% 35%" : "50% 40%",
                        alignItems: "center",
                        overflowX: "hidden",
                    }}
                >
                    <Img
                        src={image}
                        width={1144}
                        height={728}
                        style={{ width: isMobile ? "150%" : "70%", maxWidth: 1000 }}
                    />
                    <Spacer size={2} />
                    <Typography
                        style={{
                            ...(isMobile ? styleGuide.typography.heading : styleGuide.typography.title),
                            textAlign: "center",
                        }}
                    >
                        {title}
                    </Typography>
                    <Spacer size={0.5} />
                    <Typography style={{ ...styleGuide.typography.copyText, textAlign: "center" }}>
                        {description}
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            maxWidth: 350,
                            gap: styleGuide.spacing(2),
                            justifyContent: isMobile ? "center" : "end",
                            padding: styleGuide.spacing(2),
                        }}
                    >
                        <Img
                            width={301}
                            height={101}
                            src={`${cdnPath}/app-store-badge.png`}
                            style={{ width: "100%", cursor: "pointer" }}
                            disableLazyLoad
                            onClick={handleRedirectToAppStore}
                        />
                        <Img
                            width={340}
                            height={101}
                            src={`${cdnPath}/google-play-badge.png`}
                            style={{ width: isMobile ? "113%" : "112%", cursor: "pointer" }}
                            onClick={handleRedirectToPlayStore}
                            disableLazyLoad
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default SectionRacenetAppFooter;
