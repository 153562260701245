export const validateSsid = (ssid: string) => {
    if (ssid.length === 0) {
        throw new Error("ssid is required");
    }
};

export const validateTrackId = (trackId: number) => {
    if (trackId <= 0) {
        throw new Error("a track id is required");
    }
};
