import { OnboardingType } from "apiServices/identity/types/OnboardingTypes";
import { ActionMap } from "common/types/ActionMapType";

enum CompletedOnboardingTypes {
    SetOnboarding = "SET_IDENTITY_ONBOARDING",
    AddOnboarding = "ADD_IDENTITY_ONBOARDING",
}

type OnboardingTypePayload = {
    [CompletedOnboardingTypes.AddOnboarding]: OnboardingType;
    [CompletedOnboardingTypes.SetOnboarding]: OnboardingType[];
};

export type CompletedOnboardingActions = ActionMap<OnboardingTypePayload>[keyof ActionMap<OnboardingTypePayload>];

export const dispatchAddCompletedOnboarding = (dispatch: React.Dispatch<any>, onboardingType: OnboardingType) => {
    dispatch({
        type: CompletedOnboardingTypes.AddOnboarding,
        payload: onboardingType,
    });

    return onboardingType;
};

export const dispatchSetCompletedOnboarding = (dispatch: React.Dispatch<any>, onboardingTypes: OnboardingType[]) => {
    dispatch({
        type: CompletedOnboardingTypes.SetOnboarding,
        payload: onboardingTypes,
    });

    return onboardingTypes;
};

export const identityCompletedOnboardingReducer = (
    state: OnboardingType[],
    action: CompletedOnboardingActions
): OnboardingType[] => {
    switch (action.type) {
        case CompletedOnboardingTypes.AddOnboarding:
            return [...state, action.payload];
        case CompletedOnboardingTypes.SetOnboarding:
            return action.payload;
        default:
            return state;
    }
};
