import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { fontTypes, paCar2Colour, styleGuide } from "theme";
import clsx from "clsx";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ITheme } from "@material-ui/core";

export const largeButtonFontSize = "1.1rem";
const buttonBorderRadius = 4;

export function getButtonBorder(colour: string) {
    return `2px solid ${colour}`;
}

type IButtonStyle = {
    [key in StyledButtonVariant]: { highlighted: CSSProperties; default: CSSProperties };
};

export function getButtonStyles(theme: ITheme): IButtonStyle {
    return {
        primary: {
            default: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main + theme.styles.cssImportant,
            },
        },
        primaryWithBorder: {
            default: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.primary.main),
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.primary.main),
            },
        },
        secondary: {
            default: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText + theme.styles.cssImportant,
            },
            highlighted: {
                backgroundColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.main + theme.styles.cssImportant,
            },
        },
        secondaryLight: {
            default: {
                backgroundColor: theme.palette.secondary.light,
                color: "#fff" + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.secondary.light),
            },
            highlighted: {
                backgroundColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.light + theme.styles.cssImportant,
            },
        },
        transparent: {
            default: {
                backgroundColor: theme.variants.transparentColour,
                color: theme.styles.transparentBg + theme.styles.cssImportant,
                border: getButtonBorder(theme.styles.transparentBg),
            },
            highlighted: {
                backgroundColor: theme.styles.transparentBg,
                color: theme.styles.transparentButton + theme.styles.cssImportant,
                border: getButtonBorder(theme.styles.transparentBg),
            },
        },
        transparentLight: {
            default: {
                backgroundColor: theme.variants.transparentColour,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.primary.contrastText),
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.primary.contrastText),
            },
        },
        transparentInfo: {
            default: {
                backgroundColor: theme.variants.transparentColour,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.primary.contrastText),
            },
            highlighted: {
                backgroundColor: theme.palette.info.main,
                color: styleGuide.color.white + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.info.main),
            },
        },
        transparentDark: {
            default: {
                backgroundColor: theme.variants.transparentColour,
                color: theme.palette.background.default + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.background.default),
            },
            highlighted: {
                backgroundColor: theme.palette.background.default,
                color: styleGuide.color.white + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.background.default),
            },
        },
        infoPrimary: {
            default: {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.common.black + theme.styles.cssImportant,
            },
        },
        infoLight: {
            default: {
                backgroundColor: theme.palette.info.light,
                color: theme.palette.primary.dark + theme.styles.cssImportant,
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.common.black + theme.styles.cssImportant,
            },
        },
        infoLightTransparent: {
            default: {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.info.light + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.info.light),
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.common.black + theme.styles.cssImportant,
                border: getButtonBorder(styleGuide.color.white),
            },
        },
        infoSecondary: {
            default: {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.info.main),
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.common.black + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.info.main),
            },
        },
        whitePrimary: {
            default: {
                backgroundColor: styleGuide.color.white,
                color: theme.palette.primary.main + theme.styles.cssImportant,
                border: getButtonBorder(styleGuide.color.white),
            },
            highlighted: {
                backgroundColor: theme.palette.primary.main,
                color: styleGuide.color.white + theme.styles.cssImportant,
                border: getButtonBorder(styleGuide.color.white),
            },
        },
        whiteSecondary: {
            default: {
                backgroundColor: styleGuide.color.white,
                color: theme.palette.secondary.main + theme.styles.cssImportant,
                border: getButtonBorder(styleGuide.color.white),
            },
            highlighted: {
                backgroundColor: theme.palette.secondary.main,
                color: styleGuide.color.white + theme.styles.cssImportant,
                border: getButtonBorder(styleGuide.color.white),
            },
        },
        link: {
            default: {
                backgroundColor: "transparent",
                color: theme.palette.secondary.main + theme.styles.cssImportant,
                textDecoration: "underline",
                ...fontTypes.mediumItalic,
            },
            highlighted: {
                backgroundColor: "transparent",
                color: styleGuide.color.white + theme.styles.cssImportant,
                textDecoration: "underline",
            },
        },
        infoTransparent: {
            default: {
                backgroundColor: theme.variants.transparentColour,
                color: theme.palette.primary.contrastText + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.info.main),
            },
            highlighted: {
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main + theme.styles.cssImportant,
                border: getButtonBorder(theme.palette.primary.contrastText),
            },
        },
        paCompare: {
            default: {
                backgroundColor: "transparent",
                color: paCar2Colour,
                textDecoration: "underline",
                ...fontTypes.mediumItalic,
            },
            highlighted: {
                backgroundColor: "transparent",
                color: "#F2F2F2",
                textDecoration: "underline",
            },
        },
        linkInfo: {
            default: {
                backgroundColor: "transparent",
                color: "#B0B6F4",
                textDecoration: "underline",
                ...fontTypes.mediumItalic,
            },
            highlighted: {
                backgroundColor: "transparent",
                color: "#F2F2F2",
                textDecoration: "underline",
            },
        },
        EAAccount: {
            default: {
                backgroundColor: "#276afc",
                color: styleGuide.color.white + theme.styles.cssImportant,
            },
            highlighted: {
                backgroundColor: "#276afc",
                color: styleGuide.color.white + theme.styles.cssImportant,
            },
        },
    };
}

interface IStylesOptions {
    highlighted: boolean;
    textCase: StyledButtonCase;
    disableDefaultWidth: boolean;
}

export const useStyledButtonStyles = (options: IStylesOptions) =>
    makeStyles((theme: ITheme) => {
        const { highlighted, textCase, disableDefaultWidth } = options;

        const buttonStyles = getButtonStyles(theme);

        const primaryTheme = highlighted ? buttonStyles.primary.highlighted : buttonStyles.primary.default;

        const primaryWithBorderTheme = highlighted
            ? buttonStyles.primaryWithBorder.highlighted
            : buttonStyles.primaryWithBorder.default;

        const secondaryTheme = highlighted ? buttonStyles.secondary.highlighted : buttonStyles.secondary.default;

        const secondaryLightTheme = highlighted
            ? buttonStyles.secondaryLight.highlighted
            : buttonStyles.secondaryLight.default;

        const transparentTheme = highlighted ? buttonStyles.transparent.highlighted : buttonStyles.transparent.default;

        const transparentLightTheme = highlighted
            ? buttonStyles.transparentLight.highlighted
            : buttonStyles.transparentLight.default;

        const transparentInfoTheme = highlighted
            ? buttonStyles.transparentInfo.highlighted
            : buttonStyles.transparentInfo.default;

        const transparentDarkTheme = highlighted
            ? buttonStyles.transparentDark.highlighted
            : buttonStyles.transparentDark.default;

        const infoPrimaryTheme = highlighted ? buttonStyles.infoPrimary.highlighted : buttonStyles.infoPrimary.default;

        const infoLightTheme = highlighted ? buttonStyles.infoLight.highlighted : buttonStyles.infoLight.default;

        const infoLightTransparentTheme = highlighted
            ? buttonStyles.infoLightTransparent.highlighted
            : buttonStyles.infoLightTransparent.default;

        const infoSecondaryTheme = highlighted
            ? buttonStyles.infoSecondary.highlighted
            : buttonStyles.infoSecondary.default;

        const whitePrimaryTheme = highlighted
            ? buttonStyles.whitePrimary.highlighted
            : buttonStyles.whitePrimary.default;

        const whiteSecondaryTheme = highlighted
            ? buttonStyles.whiteSecondary.highlighted
            : buttonStyles.whiteSecondary.default;

        const linkTheme = highlighted ? buttonStyles.link.highlighted : buttonStyles.link.default;

        const infoTransparentTheme = highlighted
            ? buttonStyles.infoTransparent.highlighted
            : buttonStyles.infoTransparent.default;

        const paCompareTheme = highlighted ? buttonStyles.paCompare.highlighted : buttonStyles.paCompare.default;

        const linkInfoTheme = highlighted ? buttonStyles.linkInfo.highlighted : buttonStyles.linkInfo.default;

        const eaAccountTheme = buttonStyles.EAAccount.default;

        const getMinWidth = (minWidth: number) => {
            return disableDefaultWidth ? "unset" : minWidth;
        };

        return createStyles({
            root: {
                boxShadow: "none",
                textTransform: textCase,
                transition: theme.transitions.create("all", {
                    duration: theme.transitions.duration.standard,
                    easing: theme.transitions.easing.easeInOut,
                }),
                padding: `4px 24px`,
                border: getButtonBorder(theme.variants.transparentColour),
                whiteSpace: "nowrap",
                borderRadius: buttonBorderRadius,
                minWidth: getMinWidth(150),
                letterSpacing: 2.4,
            },
            sizeSmall: {
                padding: theme.spacing(0.4, 1),
                minWidth: getMinWidth(100),
            },
            sizeLarge: {
                padding: theme.spacing(0.4, 4),
                fontSize: largeButtonFontSize,
                minWidth: getMinWidth(200),
            },
            primary: {
                ...primaryTheme,
                "&:hover": { ...buttonStyles.primary.highlighted },
            },
            primaryWithBorder: {
                ...primaryWithBorderTheme,
                "&:hover": { ...buttonStyles.primaryWithBorder.highlighted },
            },
            secondary: {
                ...secondaryTheme,
                "&:hover": { ...buttonStyles.secondary.highlighted },
            },
            secondaryLight: {
                ...secondaryLightTheme,
                "&:hover": { ...buttonStyles.secondaryLight.highlighted },
            },
            transparent: {
                ...transparentTheme,
                "&:hover": { ...buttonStyles.transparent.highlighted },
            },
            transparentLight: {
                ...transparentLightTheme,
                "&:hover": { ...buttonStyles.transparentLight.highlighted },
            },
            transparentInfo: {
                ...transparentInfoTheme,
                "&:hover": { ...buttonStyles.transparentInfo.highlighted },
            },
            transparentDark: {
                ...transparentDarkTheme,
                "&:hover": { ...buttonStyles.transparentDark.highlighted },
            },
            infoPrimary: {
                ...infoPrimaryTheme,
                "&:hover": { ...buttonStyles.infoPrimary.highlighted },
            },
            infoLight: {
                ...infoLightTheme,
                "&:hover": { ...buttonStyles.infoLight.highlighted },
            },
            infoLightTransparent: {
                ...infoLightTransparentTheme,
                "&:hover": { ...buttonStyles.infoLightTransparent.highlighted },
            },
            infoSecondary: {
                ...infoSecondaryTheme,
                "&:hover": { ...buttonStyles.infoSecondary.highlighted },
            },
            whitePrimary: {
                ...whitePrimaryTheme,
                "&:hover": { ...buttonStyles.whitePrimary.highlighted },
            },
            whiteSecondary: {
                ...whiteSecondaryTheme,
                "&:hover": { ...buttonStyles.whiteSecondary.highlighted },
            },
            link: {
                ...linkTheme,
                "&:hover": { ...buttonStyles.link.highlighted },
            },
            infoTransparent: {
                ...infoTransparentTheme,
                "&:hover": { ...buttonStyles.infoTransparent.highlighted },
            },
            paCompare: {
                ...paCompareTheme,
                "&:hover": { ...buttonStyles.paCompare.highlighted },
                "&:visited": { ...buttonStyles.paCompare.default },
            },
            linkInfo: {
                ...linkInfoTheme,
                "&:hover": { ...buttonStyles.linkInfo.highlighted },
                "&:visited": { ...buttonStyles.linkInfo.default },
            },
            disabled: {
                backgroundColor: "#343647 " + theme.styles.cssImportant,
                border: getButtonBorder(theme.variants.transparentColour) + theme.styles.cssImportant,
                color: theme.variants.EADisabled + theme.styles.cssImportant,
                opacity: 0.85,
            },
            EAAccount: {
                ...eaAccountTheme,
                "&:hover": { ...buttonStyles.EAAccount.highlighted },
                "&:visited": { ...buttonStyles.EAAccount.default },
            },
        });
    });

export type StyledButtonVariant =
    | "primary"
    | "primaryWithBorder"
    | "secondary"
    | "secondaryLight"
    | "transparent"
    | "transparentLight"
    | "transparentInfo"
    | "transparentDark"
    | "infoPrimary"
    | "infoLight"
    | "infoLightTransparent"
    | "infoSecondary"
    | "whitePrimary"
    | "whiteSecondary"
    | "infoTransparent"
    | "link"
    | "paCompare"
    | "linkInfo"
    | "EAAccount";

type StyledButtonCase = "none" | "capitalize" | "uppercase" | "lowercase";

export interface IStyledButtonProps extends Omit<ButtonProps, "variant"> {
    variant?: StyledButtonVariant;
    highlighted?: boolean;
    disableDefaultWidth?: boolean;
    textCase?: StyledButtonCase;
}

export default function StyledButton(props: IStyledButtonProps) {
    const {
        children,
        className,
        variant = "primary",
        highlighted = false,
        textCase = "uppercase",
        disabled = false,
        disableDefaultWidth = false,
        ...rest
    } = props;
    const classes = useStyledButtonStyles({ highlighted, textCase, disableDefaultWidth })();

    return (
        <Button
            className={clsx(className, classes.root, classes[variant], {
                [classes.disabled]: disabled,
            })}
            classes={{ sizeSmall: classes.sizeSmall, sizeLarge: classes.sizeLarge }}
            disabled={disabled}
            {...rest}
        >
            {children}
        </Button>
    );
}
