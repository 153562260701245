export const loadTrustArcScript = () => {
    var elm, head;

    if (navigator.userAgent.indexOf("RaceNetMobileApp") === -1) {
        head = document.getElementsByTagName("head")[0] || document.body || document.documentElement;

        elm = document.createElement("script");
        elm.src = "//consent.trustarc.com/notice?domain=ea.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&pcookie";
        head.appendChild(elm);
    }
};
