import { markAsRead, removeNotifications } from "apiServices/notifications/NotificationsApiService";
import INotification from "common/components/notifications/interfaces/INotification";
import { ActionMap } from "common/types/ActionMapType";
import { INotificationStore } from "../NotificationContextStore";
import {
    dispatchFetchNewNotifications,
    dispatchSetIsNotificationsLastPage,
    dispatchSetNewNotifications,
    dispatchSetNewNotificationsCount,
} from "./NewNotificationActions";
import {
    dispatchFetchOldNotifications,
    dispatchSetOldNotifications,
    dispatchSetOldNotificationsIsLastPage,
} from "./OldNotificationActions";
import { NotificationType } from "common/enums/NotificationTypeEnum";

export enum NotificationTypes {
    AddActedFriendRequestSsid = "ADD_ACTED_FRIEND_REQUEST_SSID",
    AddReceivedFriendRequestSsid = "ADD_RECEIEVED_FRIEND_REQUEST_SSID",
    HandleTray = "HANDLE_TRAY",
}

type NotificationTypePayload = {
    [NotificationTypes.AddActedFriendRequestSsid]: string[];
    [NotificationTypes.AddReceivedFriendRequestSsid]: string[];
    [NotificationTypes.HandleTray]: boolean;
};

export type NotificationActions = ActionMap<NotificationTypePayload>[keyof ActionMap<NotificationTypePayload>];

export const getNotificiationIds = (notification: INotification): string[] => {
    let notificationIds: string[] = [];

    notificationIds.push(notification.notificationID);

    return notificationIds;
};

export const getNotificationsIds = (notifications: INotification[]): string[] => {
    let notificationIds: string[] = [];

    notifications.forEach((notification) => {
        notificationIds = [...notificationIds, ...getNotificiationIds(notification)];
    });

    return notificationIds;
};

export const dispatchFetchAllNotifications = async (
    dispatch: React.Dispatch<any>,
    oldNotifications: INotification[],
    newNotifications: INotification[],
    newNextPageCursor?: string,
    oldNextPageCursor?: string
) => {
    await Promise.all([
        dispatchFetchOldNotifications(dispatch, oldNotifications, oldNextPageCursor),
        dispatchFetchNewNotifications(dispatch, newNotifications, newNextPageCursor),
    ]);
};

export const dispatchAddActedFriendRequestSsid = (dispatch: React.Dispatch<any>, actedFriendRequestSsids: string[]) => {
    dispatch({
        type: NotificationTypes.AddActedFriendRequestSsid,
        payload: actedFriendRequestSsids,
    });
};

export const dispatchAddReceivedFriendRequestSsid = (
    dispatch: React.Dispatch<any>,
    receivedFriendRequestSsids: string[]
) => {
    dispatch({
        type: NotificationTypes.AddReceivedFriendRequestSsid,
        payload: receivedFriendRequestSsids,
    });
};

export const dispatchSetSkip = (
    dispatch: React.Dispatch<any>,
    notificationId: string,
    isRead: boolean,
    oldNotifications: INotification[],
    newNotifications: INotification[]
) => {
    if (isRead) {
        const filteredOldNotifications = oldNotifications.map((n: INotification) => {
            return n.notificationID === notificationId ? { ...n, skip: true } : n;
        });
        dispatchSetOldNotifications(dispatch, filteredOldNotifications);
    } else {
        const filteredNewNotifications = newNotifications.map((n: INotification) => {
            return n.notificationID === notificationId ? { ...n, skip: true } : n;
        });
        dispatchSetNewNotifications(dispatch, filteredNewNotifications);
    }
};

export const dispatchAddReceivedFriendRequestsSsid = (
    dispatch: React.Dispatch<any>,
    ssid: string,
    receivedFriendRequestSsids: string[]
) => {
    dispatchAddReceivedFriendRequestSsid(dispatch, [...receivedFriendRequestSsids, ssid]);
};

export const dispatchAddActedFriendRequestsSsid = (
    dispatch: React.Dispatch<any>,
    ssid: string,
    actedFriendRequestSsids: string[]
) => {
    dispatchAddActedFriendRequestSsid(dispatch, [...actedFriendRequestSsids, ssid]);
};

export const dispatchClearAllNotifications = async (
    dispatch: React.Dispatch<any>,
    newNotifications: INotification[],
    oldNotifications: INotification[]
) => {
    removeNotifications(getNotificationsIds([...newNotifications, ...oldNotifications]));

    dispatchSetNewNotifications(dispatch, []);
    dispatchSetNewNotificationsCount(dispatch, 0);
    dispatchSetIsNotificationsLastPage(dispatch, true);

    dispatchSetOldNotifications(dispatch, []);
    dispatchSetOldNotificationsIsLastPage(dispatch, true);
};

export const dispatchMarkNewNotificationsAsRead = (
    dispatch: React.Dispatch<any>,
    newNotifications: INotification[],
    oldNotifications: INotification[]
) => {
    const filteredNotifications = newNotifications /*.filter(n => (n?.skip ?? false) !== true)*/
        .map((n) => {
            return { ...n, isRead: true };
        });

    const updatedOldNotifications = [...filteredNotifications, ...oldNotifications];
    dispatchSetOldNotifications(dispatch, updatedOldNotifications);

    dispatchSetNewNotifications(dispatch, []);
    dispatchSetNewNotificationsCount(dispatch, 0);
    dispatchSetIsNotificationsLastPage(dispatch, true);

    let newNotificationIds = getNotificationsIds(newNotifications);

    if (newNotificationIds.length === 0) return;

    markAsRead(newNotificationIds);
};

export const dispatchRemoveNotification = async (
    dispatch: React.Dispatch<any>,
    oldNotifications: INotification[],
    newNotifications: INotification[],
    notificationId: string,
    isRead: boolean
) => {
    if (isRead) {
        const filteredOldNotifications = oldNotifications.filter((n) => n.notificationID !== notificationId);
        dispatchSetOldNotifications(dispatch, filteredOldNotifications);
    } else {
        const filteredNewNotifications = newNotifications.filter((n) => n.notificationID !== notificationId);
        dispatchSetNewNotifications(dispatch, filteredNewNotifications);
    }

    await removeNotifications([notificationId]);
};

export const dispatchHandleTray = (dispatch: React.Dispatch<any>) => {
    dispatch({
        type: NotificationTypes.HandleTray,
    });
};

export const notificationReducer = (state: INotificationStore, action: NotificationActions): INotificationStore => {
    switch (action.type) {
        case NotificationTypes.AddActedFriendRequestSsid:
            return {
                ...state,
                actedFriendRequestSsids: action.payload,
            };
        case NotificationTypes.AddReceivedFriendRequestSsid:
            return {
                ...state,
                receivedFriendRequestSsids: action.payload,
            };
        case NotificationTypes.HandleTray:
            return {
                ...state,
                openTray: !state.openTray,
            };
        default:
            return state;
    }
};
