import React, { useState, createContext, useEffect, useContext, FunctionComponent, useReducer } from "react";
import Loader from "common/components/Loader";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import InternalServerErrorPage from "pages/errorPages/InternalServerErrorPage";
import { IApplicationStore, defaultApplicationContext, IApplicationContextStore } from "./ApplicationContextStore";
import { dispatchFetchApplication } from "./ApplicationActions";
import axios, { AxiosResponse } from "axios";
import { reducer } from "./ApplicationReducer";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";
import { useConfigDispatch } from "contexts/configuration/ConfigurationContext";
import { dispatchFetchFeatures } from "contexts/configuration/actions/ConfigurationActions";

const ApplicationContext = createContext<{ state: IApplicationContextStore; dispatch: React.Dispatch<any> }>({
    state: defaultApplicationContext,
    dispatch: () => null,
});

export const ApplicationContextProvider: FunctionComponent = ({ children }) => {
    const configurationDispatch = useConfigDispatch();
    const [state, dispatch] = useReducer(reducer, defaultApplicationContext);
    const [responseStatus, setResponseStatus] = useState<ResponseStatusEnum>(ResponseStatusEnum.Pending);

    const setAxios = (response: AxiosResponse<IApplicationStore>) => {
        if (isRaceNetMobileApp || isRaceNetTabletApp) return;
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (axios.isCancel(error)) {
                    return response;
                } else if (error.response.status === ResponseStatusEnum.Forbidden) {
                    console.log("Forbiden: Access for restricted users only");
                    setResponseStatus(ResponseStatusEnum.Forbidden);
                }
                return Promise.reject(error);
            }
        );
    };

    useEffect(() => {
        if (responseStatus === ResponseStatusEnum.Pending) {
            dispatchFetchApplication(dispatch)
                .then((response) => {
                    setResponseStatus(ResponseStatusEnum.Ok);
                    return response;
                })
                .then((response) => {
                    dispatchFetchFeatures(configurationDispatch, response.data.cdnUrl);
                    setAxios(response);
                })
                .catch((e) => {
                    console.log("Error fetching application", e);
                    setResponseStatus(ResponseStatusEnum.Error);
                });
        }
    }, [responseStatus]);

    const getContent = (status: ResponseStatusEnum) => {
        switch (status) {
            case ResponseStatusEnum.Pending:
                return !isRaceNetMobileApp && !isRaceNetTabletApp && <Loader backgroundColor={"rgba(0,0,0,.8)"} />;
            case ResponseStatusEnum.Error:
                return <InternalServerErrorPage />;
            case ResponseStatusEnum.Ok:
                return (
                    <ApplicationContext.Provider value={{ state, dispatch }}>{children}</ApplicationContext.Provider>
                );
        }
    };

    return <>{getContent(responseStatus)}</>;
};

export const useApplicationContext = () => useContext(ApplicationContext);
