import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StyledButton, { IStyledButtonProps } from "./StyledButton";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: { position: "relative" },
        buttonProgress: {
            color: theme.palette.primary.main,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    });
});

interface IProps extends IStyledButtonProps {
    isLoading: boolean;
}

export default function LoadableButton(props: IProps) {
    const classes = useStyles();
    const { children, isLoading, disabled, className, ...rest } = props;

    return (
        <StyledButton className={clsx(className, classes.root)} disabled={isLoading || disabled} {...rest}>
            {children}
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </StyledButton>
    );
}
