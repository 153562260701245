import { Typography } from "@material-ui/core";
import Spacer from "common/components/Spacer";
import { IStyles, styleGuide } from "theme";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { useEffect } from "react";
import { useApplicationContext } from "contexts/application/ApplicationContext";
import playStore from "assets/images/appStore/playStore.png";
import appStore from "assets/images/appStore/appStore.png";
import Img from "common/components/img/Img";
import { ReactComponent as RNLogo } from "assets/images/logos/racenet/icon_racenetlogofull.svg";

const AppStoreRedirect = () => {
    const applicationContext = useApplicationContext();

    const styles: IStyles = {
        root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            height: "100%",
            width: "100%",
            padding: styleGuide.spacing(3),
            margin: 0,
        },
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        text: {
            color: styleGuide.color.white,
        },
        row: {
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
        },
    };

    const playStoreUrl = applicationContext.state.applicationStore.playStoreUrl;
    const iOSUrl = applicationContext.state.applicationStore.iosUrl;

    useEffect(() => {
        if (isIOS && isMobile) {
            window.location.href = iOSUrl;
        } else if (isAndroid && isMobile) {
            window.location.href = playStoreUrl;
        }
    }, []);

    return (
        <section style={styles.root}>
            <div style={styles.container}>
                <RNLogo style={{ height: "auto", width: isMobile ? "80%" : 350, fill: styleGuide.color.coral }} />
                <Spacer size={3} />
                <Typography align="center" style={{ ...styleGuide.typography.heading, ...styles.text }}>
                    Our EA Racenet app is now here on iOS and Android.
                </Typography>
                <Spacer size={3} />
                <span style={styles.row}>
                    <a href={iOSUrl}>
                        <Img style={{ width: 180 }} src={appStore} alt="app-store" height={167} width={498} />
                    </a>
                    <Spacer size={3} />
                    <a href={playStoreUrl}>
                        <Img style={{ width: 200 }} src={playStore} alt="app-store" height={207.3} width={700} />
                    </a>
                </span>
            </div>
        </section>
    );
};

export default AppStoreRedirect;
