//TODO: maybe move all the settings url in this store ?

import IFetch from "common/interfaces/IFetch";

export interface IConfigurationStore extends IFetch {
    apiUrl: string;
    notificationHubUrl: string;
    authUrls: {
        login: string;
        loginJwt: string;
        logout: string;
        link: string;
    };
    jwtConfig: {
        clientId: string;
        redirectUri: string;
        response_type: string;
        grant_type: string;
        logoutRedirectUri: string;
        refresh_grant_type: string;
    };
    googleOptimize: {
        experiments: {
            landingPage?: string;
        };
    };
    DR2CarOfWeekVideo: { videoId: string; title: string };
    features: string[];
    redbullContent: {
        title: string;
        logo: string;
        coverMobile: string;
        coverDesktop: string;
        website?: string;
        description: string[];
        steps: string[];
        prizes: { title: string; description: string; coverMobile: string; cover: string }[];
        termsConditionsUrl: string;
        footer: string[];
        knockoutDates: { wheel: string; controller: string };
        finalDates: { wheel: string; controller: string };
    };
    redbullLeaderboardVersion: number;
    wrc2023Esports: {
        terms: {
            preText?: string;
            linkText: string;
            termsLink: string;
            postText?: string;
        };
    };
}

export const defaultConfigurationStore: IConfigurationStore = {
    apiUrl: "",
    notificationHubUrl: "",
    authUrls: {
        login: "",
        loginJwt: "",
        logout: "",
        link: "",
    },
    jwtConfig: {
        clientId: "",
        redirectUri: "",
        response_type: "",
        grant_type: "",
        refresh_grant_type: "",
        logoutRedirectUri: "",
    },
    googleOptimize: {
        experiments: {
            landingPage: undefined,
        },
    },
    loading: false,
    hasFetched: false,
    DR2CarOfWeekVideo: { videoId: "GAvqSOMqawU", title: "Volkswagen Polo GTI R5" },
    features: [],
    redbullContent: {
        title: "",
        logo: "",
        coverMobile: "",
        coverDesktop: "",
        description: [],
        steps: [],
        prizes: [],
        termsConditionsUrl: "",
        footer: [],
        knockoutDates: { wheel: "", controller: "" },
        finalDates: { wheel: "", controller: "" },
    },
    redbullLeaderboardVersion: 0,
    wrc2023Esports: {
        terms: {
            linkText: "",
            termsLink: "",
        },
    },
};
