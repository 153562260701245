import React, { createContext, FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import Loader from "common/components/Loader";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import InternalServerErrorPage from "pages/errorPages/InternalServerErrorPage";
import { defaultIdentity, IIdentityStore } from "./IdentityStore";
import { reducer } from "./IdentityReducer";
import { setUserId } from "firebase/analytics";
import { analytics } from "index";
import { useConfig } from "../configuration/ConfigurationContext";
import { IFetchAccessTokenRequest } from "../../apiServices/identity/requests/IFetchAccessTokenRequest";
import { isAuthorized } from "../../utils/axiosTools";
import { isRaceNetMobileApp, isRaceNetTabletApp, webViewPostMessage } from "utils/webViewTools";
import { dispatchFetchIdentity } from "./actions/IdentityActions";
import axios from "axios";
import { useCookieContext } from "common/components/cookieConsent/CookieContext";
import ErrorPage from "pages/errorPages/ErrorPage";

const IdentityContext = createContext<{ state: IIdentityStore; dispatch: React.Dispatch<any> }>({
    state: defaultIdentity,
    dispatch: () => null,
});

export const IdentityContextProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultIdentity);
    const [responseStatus, setResponseStatus] = useState<ResponseStatusEnum>(ResponseStatusEnum.Pending);

    const config = useConfig();
    const cookieContext = useCookieContext();

    const handleRNmessage = async (event: any) => {
        try {
            // webViewPostMessage("Web received: " + event.data);
            if (event.data && JSON.stringify(event.data).includes("Bearer")) {
                // After receiving the JWT sets the axios header then fetch the Identity
                axios.defaults.headers.authorization = event.data;
                await dispatchFetchIdentity(dispatch);
                setResponseStatus(ResponseStatusEnum.Ok);
            }
        } catch (error) {
            console.log("error");
            setResponseStatus(ResponseStatusEnum.Error);
        }
    };

    useEffect(() => {
        // Mobile app JWT token handler to log in webviews
        if (isRaceNetMobileApp || isRaceNetTabletApp) {
            document.addEventListener("RaceNetApp", handleRNmessage);
            webViewPostMessage("requestToken");
        }

        if (responseStatus === ResponseStatusEnum.Pending && !(isRaceNetMobileApp || isRaceNetTabletApp)) {
            const authRequest: IFetchAccessTokenRequest = {
                clientId: config.jwtConfig.clientId,
                grantType: config.jwtConfig.refresh_grant_type,
                refreshToken: "",
                redirectUri: config.jwtConfig.redirectUri,
                authCode: "",
                codeVerifier: "",
            };

            isAuthorized(dispatch, authRequest)
                .then((response) => {
                    setResponseStatus(ResponseStatusEnum.Ok);
                })
                .catch((error) => {
                    console.log("Error isAuthorised", error);
                    setResponseStatus(ResponseStatusEnum.Error);
                });
        }
        return () => document.removeEventListener("RaceNetApp", handleRNmessage);
    }, [responseStatus]);

    const getContent = (status: ResponseStatusEnum) => {
        switch (status) {
            case ResponseStatusEnum.Pending:
                return !isRaceNetMobileApp && !isRaceNetTabletApp && <Loader backgroundColor={"rgba(0,0,0,.8)"} />;
            case ResponseStatusEnum.Error:
                return <InternalServerErrorPage />;
            case ResponseStatusEnum.Forbidden:
                return (
                    <ErrorPage
                        title={`Access to Racenet restricted`}
                        text={"It looks like you don't currently have access to Racenet."}
                    />
                );
            case ResponseStatusEnum.Ok:
                typeof analytics !== "undefined" && setUserId(analytics, state.identity.ssid);

                return <IdentityContext.Provider value={{ state, dispatch }}>{children}</IdentityContext.Provider>;
        }
    };

    return <>{getContent(responseStatus)}</>;
};

export const useIdentityContext = () => useContext(IdentityContext);
