import PageCircularProgress from "common/components/loaders/PageCircularProgress";
import { useIdentityContext } from "contexts/identity/IdentityContext";
import React, { createContext, FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";
import { dispatchFetchWebAcceptanceText, dispatchSetWebAcceptance, webAcceptanceReducer } from "./WebAcceptanceActions";
import { defaultWebAcceptanceStore, IWebAcceptanceStore } from "./WebAcceptanceStore";

const WebAcceptancePage = React.lazy(() => import("pages/webAcceptance/WebAcceptancePage"));

const WebAcceptanceContext = createContext<{ state: IWebAcceptanceStore; dispatch: React.Dispatch<any> }>({
    state: defaultWebAcceptanceStore,
    dispatch: () => null,
});

export const WebAcceptanceContextProvider: FunctionComponent = ({ children }) => {
    const identityWebAcceptance = useIdentityContext().state.identity.isTermsAccepted;

    const [state, dispatch] = useReducer(webAcceptanceReducer, defaultWebAcceptanceStore);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (isRaceNetMobileApp || isRaceNetTabletApp) {
            setLoader(false);
            return;
        }
        if (identityWebAcceptance === true) {
            dispatchSetWebAcceptance(dispatch, identityWebAcceptance);
            setLoader(false);
            return;
        }

        dispatchFetchWebAcceptanceText(dispatch).finally(() => {
            setLoader(false);
        });
    }, [identityWebAcceptance]);

    if (loader) return <PageCircularProgress />;

    return (
        <WebAcceptanceContext.Provider value={{ state, dispatch }}>
            {state.hasAcceptedTerms || isRaceNetMobileApp || isRaceNetTabletApp ? (
                children
            ) : (
                <React.Suspense fallback={<PageCircularProgress />}>
                    <WebAcceptancePage />
                </React.Suspense>
            )}
        </WebAcceptanceContext.Provider>
    );
};

export const useWebAcceptanceContext = () => useContext(WebAcceptanceContext);
