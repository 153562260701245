import IApplication from "common/interfaces/IApplication";
import IFetch from "common/interfaces/IFetch";

export interface IApplicationStore extends IApplication, IFetch {
    platformsWeb: string;
}

export interface IApplicationContextStore {
    applicationStore: IApplicationStore;
}

export const defaultApplicationContext: IApplicationContextStore = {
    applicationStore: {
        hasFetchedData: false,
        minSearchFriendCharacters: 3,
        youtubeFeedVideoId: "",
        surveyUrl: "",
        gameStatsSurveyUrl: "",
        accountWeb: "",
        registerWeb: "",
        platformsWeb: "",
        cdnUrl: "",
        supportedGames: [],
        loading: false,
        hasFetched: false,
        registrationOpen: false,
        registrationLimit: 0,
        racenetLegacyUrl: "",
        version: {
            major: 0,
            minor: 0,
            patch: 0,
            version: "",
        },
        iosUrl: "",
        playStoreUrl: "",
    },
};
