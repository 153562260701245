interface ICalculateBorderRadiusProps {
    padding: number;
    outerBorderThickness?: number;
    innerBorderThickness?: number;
}

interface ICalculateOuterBorderRadiusProps extends ICalculateBorderRadiusProps {
    innerBorderRadius: number;
}

export interface ICalculateInnerBorderRadiusProps extends ICalculateBorderRadiusProps {
    outerBorderRadius: number;
}

export function calculateInnerBorderRadius(props: ICalculateInnerBorderRadiusProps) {
    const { padding, outerBorderRadius, outerBorderThickness = 0, innerBorderThickness = 0 } = props;

    return outerBorderRadius - padding - (outerBorderThickness - innerBorderThickness) / 2;
}
