interface IProps {
    height: number;
    width: number;
}

export default function calculateAspectRatio(props: IProps): number {
    const { height, width } = props;

    return width / height;
}
