import { useState, useLayoutEffect, ReactNode } from "react";
import { createPortal } from "react-dom";

interface IProps {
    children: ReactNode;
    wrapperId: string;
}

const createWrapperAndAppendToBody = (wrapperId: string) => {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
};

const Portal = ({ children, wrapperId }: IProps) => {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId) as HTMLElement;
        let systemCreated = false;

        // if element is not found with wrapperId or wrapperId is not provided, create and append to body
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        return () => {
            if (systemCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [wrapperId]);

    if (wrapperElement === null) return null;

    return createPortal(children, wrapperElement);
};

export default Portal;
