import { getNotifications } from "apiServices/notifications/NotificationsApiService";
import { NotificationFilter } from "apiServices/notifications/requests/INotificationsRequest";
import INotification from "common/components/notifications/interfaces/INotification";
import { ActionMap } from "common/types/ActionMapType";
import { INewNotificationStore } from "../NotificationContextStore";

export enum NewNotificationTypes {
    FetchNewNotifications = "FETCH_NEW_NOTIFICATIONS",
    FetchNewNotificationsCompleted = "FETCH_NEW_NOTIFICATIONS_COMPLETED",
    SetNewNotifications = "SET_NEW_NOTIFICATIONS",
    SetNewNotificationsCount = "SET_NEW_NOTIFICATIONS_COUNT",
    SetNewNotificationsLoading = "SET_NEW_NOTIFICATIONS_LOADING",
    SetNewNotificationsRefreshable = "SET_NEW_NOTIFICATIONS_REFRESHABLE",
    SetNewNotificationsIsLastPage = "SET_NEW_NOTIFICATIONS_IS_LAST_PAGE",
    SetNextPageCursor = "SET_NEXT_PAGE_CURSOR",
}

type NewNotificationTypePayload = {
    [NewNotificationTypes.FetchNewNotifications]: INotification[];
    [NewNotificationTypes.FetchNewNotificationsCompleted]: INotification[];
    [NewNotificationTypes.SetNewNotifications]: INotification[];
    [NewNotificationTypes.SetNewNotificationsCount]: number;
    [NewNotificationTypes.SetNewNotificationsLoading]: boolean;
    [NewNotificationTypes.SetNewNotificationsRefreshable]: boolean;
    [NewNotificationTypes.SetNewNotificationsIsLastPage]: boolean;
    [NewNotificationTypes.SetNextPageCursor]: string;
};

export type NewNotificationActions = ActionMap<NewNotificationTypePayload>[keyof ActionMap<NewNotificationTypePayload>];

const numberOfNotificationsPerPage = 10;

export const dispatchFetchNewNotifications = async (
    dispatch: React.Dispatch<any>,
    newNotifications: INotification[],
    cursor?: string
) => {
    dispatch({
        type: NewNotificationTypes.FetchNewNotifications,
    });

    const response = await getNotifications({
        cursor: cursor,
        take: numberOfNotificationsPerPage,
        filter: NotificationFilter.UnRead,
    });

    const filteredArr: INotification[] = [];
    const uniqueIds = new Set<string>();

    newNotifications.forEach((item) => {
        if (!uniqueIds.has(item.notificationID)) {
            uniqueIds.add(item.notificationID);
            filteredArr.push(item);
        }
    });

    response.notifications.forEach((item) => {
        if (!uniqueIds.has(item.notificationID)) {
            uniqueIds.add(item.notificationID);

            filteredArr.push(item);
        }
    });

    const sortedByDateArray = filteredArr.sort(
        (a, b) => new Date(b.datePosted as string).valueOf() - new Date(a.datePosted as string).valueOf()
    );

    dispatchSetNewNotifications(dispatch, sortedByDateArray);
    dispatchSetNextPageCursor(dispatch, response.nextPageCursor);
    dispatchSetIsNotificationsLastPage(dispatch, !response.loadMore);
    dispatchSetNewNotificationsCount(dispatch, response.notifications.length);

    return response.notifications;
};

export const dispatchSetNewNotifications = (dispatch: React.Dispatch<any>, notifications: INotification[]) => {
    dispatch({
        type: NewNotificationTypes.SetNewNotifications,
        payload: notifications,
    });
};

export const dispatchSetNewNotificationsCount = (dispatch: React.Dispatch<any>, count: number) => {
    dispatch({
        type: NewNotificationTypes.SetNewNotificationsCount,
        payload: count,
    });
};

export const dispatchSetNewNotificationsRefreshable = (dispatch: React.Dispatch<any>, refreshable: boolean) => {
    dispatch({
        type: NewNotificationTypes.SetNewNotificationsRefreshable,
        payload: refreshable,
    });
};

export const dispatchSetIsNotificationsLastPage = (dispatch: React.Dispatch<any>, isLastPage: boolean) => {
    dispatch({
        type: NewNotificationTypes.SetNewNotificationsIsLastPage,
        payload: isLastPage,
    });
};

export const dispatchSetNextPageCursor = (dispatch: React.Dispatch<any>, cursor?: string) => {
    dispatch({
        type: NewNotificationTypes.SetNextPageCursor,
        payload: cursor,
    });

    return cursor;
};

export const newNotificationReducer = (
    state: INewNotificationStore,
    action: NewNotificationActions
): INewNotificationStore => {
    switch (action.type) {
        case NewNotificationTypes.SetNewNotifications:
            return {
                ...state,
                notifications: action.payload,
            };
        case NewNotificationTypes.SetNewNotificationsCount:
            return {
                ...state,
                count: action.payload,
            };
        case NewNotificationTypes.SetNewNotificationsLoading:
            return {
                ...state,
                loading: action.payload,
            };
        case NewNotificationTypes.SetNewNotificationsRefreshable:
            return {
                ...state,
                refreshable: action.payload,
            };
        case NewNotificationTypes.SetNewNotificationsIsLastPage:
            return {
                ...state,
                isLastPage: action.payload,
            };
        case NewNotificationTypes.SetNextPageCursor:
            return {
                ...state,
                nextPageCursor: action.payload,
            };
        default:
            return state;
    }
};
