import { ActionMap } from "common/types/ActionMapType";
import ResponseStatusEnum from "common/enums/ResponseStatusEnum";
import { IApplicationStore } from "./ApplicationContextStore";
import { getApplication } from "apiServices/application/ApplicationApiService";
import { EAPaths, gameSettings } from "settings";
import IGameSettingsResponse from "apiServices/application/response/IGameSettingsResponse";
import { IGameSettings } from "common/interfaces/IApplication";
import { trimEndSlash } from "utils/urlHelper";

enum ApplicationTypes {
    FetchApplication = "FETCH_APPLICATION",
    FetchApplicationCompleted = "FETCH_APPLICATION_COMPLETED",
    UpdateAxiosHeader = "UPDATE_AXIOS_HEADER",
}

type ApplicationTypePayload = {
    [ApplicationTypes.FetchApplication]: IApplicationStore;
    [ApplicationTypes.FetchApplicationCompleted]: IApplicationStore;
};

export type ApplicationActions = ActionMap<ApplicationTypePayload>[keyof ActionMap<ApplicationTypePayload>];

const resolveSupportedGames = (games: IGameSettingsResponse[]): IGameSettings[] => {
    let supportedGames: IGameSettings[] = [];

    games?.map((g) => {
        const gameSetting = gameSettings.find((f) => f.gameId === g.gameId);
        const game = {
            ...g,
            route: gameSetting?.route,
            activityFeedEnabled: gameSetting?.activityFeedEnabled ?? false,
        };
        supportedGames.push(game);
    });

    return supportedGames;
};

export const dispatchFetchApplication = async (dispatch: React.Dispatch<any>) => {
    dispatch({
        type: ApplicationTypes.FetchApplication,
    });

    const response = await getApplication();

    if (response.status === ResponseStatusEnum.Error) throw new Error("Error while fetching identity data!");

    dispatch({
        type: ApplicationTypes.FetchApplicationCompleted,
        payload: {
            minSearchFriendCharacters: response.data.minSearchFriendCharacters,
            youtubeFeedVideoId: response.data.youtubeFeedVideoId,
            surveyUrl: response.data.surveyUrl,
            gameStatsSurveyUrl: response.data.gameStatsSurveyUrl,
            accountWeb: `${trimEndSlash(response.data.accountWeb)}/${EAPaths.index}`,
            registerWeb: response.data.registerWeb,
            platformsWeb: `${trimEndSlash(response.data.accountWeb)}/${EAPaths.linkedPlatforms}`,
            hasFetchedData: true,
            cdnUrl: response.data.cdnUrl,
            supportedGames: resolveSupportedGames(response.data.supportedGames),
            registrationOpen: response.data.registrationOpen,
            registrationLimit: response.data.registrationLimit,
            racenetLegacyUrl: response.data.racenetLegacyUrl,
            version: {
                major: response.data.version.major,
                minor: response.data.version.minor,
                patch: response.data.version.patch,
                version: response.data.version.version,
            },
            iosUrl: response.data.iosUrl,
            playStoreUrl: response.data.playStoreUrl,
        },
    });
    return response;
};

export const applicationReducer = (state: IApplicationStore, action: ApplicationActions): IApplicationStore => {
    switch (action.type) {
        case ApplicationTypes.FetchApplication:
            return {
                ...state,
                loading: true,
            };
        case ApplicationTypes.FetchApplicationCompleted:
            return {
                ...action.payload,
                loading: false,
                hasFetched: true,
            };
        default:
            return state;
    }
};
