export enum WebViewEnum {
    AppVersion = "appVersion",
    GoBack = "goBackToNative",
    PApage = "perfAnalysis",
    PALoaded = "PALoaded",
    FAQ = "faq",
    GoToLeaderboards = "goToLeaderboard",
    Error = "error",
    Ready = "ready",
    GetToken = "getToken",
    TrackChanged = "trackChanged",
    WebMessage = "webMessage",
    Cookies = "pwebViewReferenceCookies",
    Headers = "PAnativeMessageHeaders",
    openPopup = "openPopup",
    topInset = "topInset",
    hideBackButton = "hideBackButton",
    fullScreen = "fullScreen",
    ProfilePage = "Profile:",
    Share = "share:",
    NativeRoute = "NativeRoute",
    Social = "ExternalSocialLink",
}
