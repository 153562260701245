import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function FriendsIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M11.92,12.58c1.03,0,1.92-0.37,2.65-1.1c0.73-0.73,1.1-1.62,1.1-2.65c0-1.03-0.37-1.92-1.1-2.65 c-0.73-0.73-1.62-1.1-2.65-1.1c-1.03,0-1.92,0.37-2.65,1.1c-0.73,0.73-1.1,1.62-1.1,2.65c0,1.03,0.37,1.92,1.1,2.65 C10,12.21,10.89,12.58,11.92,12.58z" />
                <path d="M18.48,17.05c-0.02-0.3-0.06-0.63-0.13-0.98c-0.06-0.35-0.14-0.68-0.24-0.99c-0.1-0.31-0.24-0.62-0.41-0.92 c-0.18-0.31-0.38-0.58-0.61-0.8c-0.24-0.23-0.54-0.42-0.88-0.55c-0.34-0.14-0.72-0.2-1.12-0.2c-0.16,0-0.31,0.07-0.61,0.26 c-0.18,0.12-0.4,0.26-0.63,0.41c-0.2,0.13-0.48,0.25-0.82,0.36c-0.33,0.11-0.67,0.16-1,0.16c-0.33,0-0.67-0.05-1-0.16 c-0.34-0.11-0.62-0.23-0.82-0.36c-0.24-0.15-0.45-0.29-0.64-0.41c-0.3-0.19-0.45-0.26-0.61-0.26c-0.4,0-0.78,0.07-1.12,0.2 c-0.34,0.14-0.64,0.32-0.88,0.55c-0.23,0.22-0.44,0.49-0.61,0.8c-0.17,0.3-0.31,0.61-0.41,0.92c-0.1,0.3-0.18,0.64-0.24,0.99 c-0.06,0.35-0.11,0.68-0.13,0.98c-0.02,0.3-0.03,0.61-0.03,0.92c0,0.81,0.26,1.47,0.77,1.96c0.5,0.48,1.17,0.72,1.98,0.72h7.49 c0.81,0,1.47-0.24,1.98-0.72c0.51-0.48,0.77-1.14,0.77-1.96C18.51,17.66,18.5,17.35,18.48,17.05z" />
            </g>
            <path d="M18.92,8.61c0.79,0,1.48-0.28,2.04-0.85c0.56-0.56,0.85-1.25,0.85-2.04c0-0.79-0.28-1.48-0.85-2.04 c-0.56-0.56-1.25-0.85-2.04-0.85c-0.79,0-1.48,0.28-2.04,0.85c-0.56,0.56-0.85,1.25-0.85,2.04c0,0.79,0.29,1.48,0.85,2.04 C17.43,8.33,18.12,8.61,18.92,8.61z" />
            <path d="M4.93,8.61c0.79,0,1.48-0.28,2.04-0.85C7.54,7.2,7.82,6.51,7.82,5.72c0-0.79-0.28-1.48-0.85-2.04 C6.42,3.11,5.73,2.83,4.93,2.83c-0.79,0-1.48,0.28-2.04,0.85C2.33,4.24,2.04,4.92,2.04,5.72c0,0.79,0.29,1.48,0.85,2.04 C3.45,8.33,4.14,8.61,4.93,8.61z" />
            <g>
                <path d="M23.98,12.06c-0.02-0.23-0.05-0.49-0.1-0.76c-0.05-0.27-0.11-0.53-0.19-0.76c-0.08-0.24-0.18-0.48-0.31-0.71 c-0.14-0.24-0.29-0.45-0.47-0.62c-0.19-0.18-0.42-0.32-0.68-0.43c-0.26-0.1-0.56-0.16-0.87-0.16c-0.12,0-0.24,0.05-0.47,0.2 c-0.14,0.09-0.31,0.2-0.49,0.32c-0.16,0.1-0.37,0.19-0.63,0.28c-0.26,0.08-0.52,0.13-0.77,0.13c-0.26,0-0.52-0.04-0.77-0.13 c-0.26-0.08-0.48-0.18-0.63-0.28c-0.18-0.12-0.35-0.22-0.49-0.32c-0.22-0.14-0.34-0.2-0.46-0.2c0,0.07,0,0.14,0,0.2 c0,0.64-0.12,1.25-0.35,1.81c-0.15,0.36-0.35,0.71-0.6,1.03c0.3,0.04,0.6,0.12,0.87,0.23c0.46,0.18,0.86,0.44,1.19,0.75 c0.3,0.29,0.56,0.63,0.79,1.02c0.2,0.36,0.37,0.73,0.49,1.11c0.01,0.02,0.01,0.04,0.02,0.05h2.83c0.62,0,1.14-0.19,1.53-0.56 C23.8,13.9,24,13.4,24,12.77C24,12.53,23.99,12.29,23.98,12.06z" />
            </g>
            <g>
                <path d="M5,14.78c0.12-0.38,0.28-0.75,0.49-1.11c0.22-0.39,0.49-0.73,0.79-1.02C6.6,12.33,7,12.08,7.46,11.9 c0.22-0.09,0.46-0.16,0.7-0.2c-0.26-0.33-0.46-0.68-0.62-1.06C7.31,10.08,7.2,9.47,7.2,8.83c0-0.06,0-0.11,0-0.17 C7.12,8.69,7.03,8.75,6.91,8.83C6.77,8.92,6.6,9.02,6.42,9.14c-0.16,0.1-0.37,0.19-0.63,0.28C5.53,9.5,5.27,9.55,5.01,9.55 S4.49,9.5,4.23,9.42C3.97,9.34,3.76,9.24,3.6,9.14C3.42,9.03,3.25,8.92,3.11,8.83c-0.23-0.15-0.35-0.2-0.47-0.2 c-0.31,0-0.6,0.05-0.87,0.16c-0.26,0.1-0.49,0.25-0.68,0.43C0.92,9.38,0.76,9.59,0.62,9.83c-0.13,0.23-0.24,0.47-0.31,0.71 c-0.08,0.23-0.14,0.49-0.19,0.76c-0.05,0.27-0.08,0.52-0.1,0.76C0.01,12.29,0,12.53,0,12.77c0,0.63,0.2,1.13,0.59,1.51 c0.39,0.37,0.9,0.56,1.53,0.56h2.86C4.98,14.82,4.99,14.8,5,14.78z" />
            </g>
        </SvgIcon>
    );
}
