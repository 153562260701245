import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scroll({
            top: 1, //fixes the blank issue on iOs app
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    return null;
};

export default withRouter(ScrollToTop);
