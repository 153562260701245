import { HTMLAttributes } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";

const setBacgroundColor = (props: IProps) => {
    if (props.noBackground) {
        return "rgba(0,0,0,0)";
    }
    if (props.backgroundColor) {
        return props.backgroundColor;
    }

    return isRaceNetMobileApp || isRaceNetTabletApp ? "#0D1043" : "rgba(0,0,0,.8)";
};

const useStyles = makeStyles((theme) =>
    createStyles({
        container: (props: IProps) => ({
            width: "100%",
            height: "100%",
            position: props.contained ? "relative" : "fixed",
            top: 0,
            left: 0,
            backgroundColor: setBacgroundColor(props),
            zIndex: props.noBackground ? "auto" : 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }),
        loader: {
            color: theme.palette.info.main,
        },
    })
);

export enum SizeEnum {
    small = 25,
    medium = 40,
    large = 60,
}

interface IProps extends HTMLAttributes<HTMLDivElement> {
    backgroundColor?: string;
    noBackground?: boolean;
    contained?: boolean;
    size?: SizeEnum;
}

const Loader = (props: IProps) => {
    const { className, size, noBackground, ...rest } = props;

    const classes = useStyles(props);

    return (
        <div className={clsx(className, classes.container)} {...rest}>
            <div>
                <CircularProgress className={classes.loader} size={size ? size : SizeEnum.medium} />
            </div>
        </div>
    );
};

export default Loader;
