import { useEffect } from "react";
import Routing from "routing/Routing";
import { IdentityContextProvider } from "contexts/identity/IdentityContext";
import { ApplicationContextProvider } from "contexts/application/ApplicationContext";
import ErrorPage from "pages/errorPages/ErrorPage";
import OfflineBanner from "common/components/OfflineBanner";
import { useCookieContext } from "common/components/cookieConsent/CookieContext";
import { getCookiePreferences } from "common/components/cookieConsent/CookiePreferenceStorageService";
import { PreferencesValues } from "common/components/cookieConsent/ICookiePreferences";
import { ScrollContextProvider } from "contexts/scroll/ScrollContext";
import AppSnackbar from "common/components/AppSnackbar";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";

const App = () => {
    const cookieContext = useCookieContext();

    const validIosVersion = () => {
        let userOS;
        let userOSver;
        let ua = navigator.userAgent;
        let uaindex;
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            userOS = "iOS";
            uaindex = ua.indexOf("OS ");
        }
        //@ts-ignore
        if (userOS === "iOS" && uaindex > -1) {
            //@ts-ignore
            userOSver = ua.substr(uaindex + 3, 2).replace("_", ".");
        }
        //@ts-ignore
        if (userOS === "iOS" && Number(userOSver) <= 10) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        const prefsChange = () => {
            const localStoragePreferences = getCookiePreferences();

            if (localStoragePreferences) {
                cookieContext.savePreferences(localStoragePreferences);

                if (!localStoragePreferences.value.includes(PreferencesValues.Functional.toString())) {
                    window.location.reload();
                }
            }
        };

        document.body.addEventListener("truste-consent", () => prefsChange());

        return () => {
            document.body.removeEventListener("truste-consent", () => {});
        };
    }, []);

    return (
        <>
            {validIosVersion() ? (
                <>
                    <OfflineBanner />
                    {!isRaceNetMobileApp && !isRaceNetTabletApp && <AppSnackbar />}
                    <ApplicationContextProvider>
                        <IdentityContextProvider>
                            <ScrollContextProvider>
                                <Routing />
                            </ScrollContextProvider>
                        </IdentityContextProvider>
                    </ApplicationContextProvider>
                </>
            ) : (
                <ErrorPage text="EA Racenet requires iOS 11.0 or later." />
            )}
        </>
    );
};

export default App;
