import { ReactNode, useEffect, useRef } from "react";
import { createStyles, ITheme, makeStyles, Typography } from "@material-ui/core";
import useIsMobile from "hooks/useIsMobile";
import { useClickOutside } from "hooks/useClickOutside";
import Portal from "../uiComponents/dialogs/Portal";
import { Transition, TransitionStatus } from "react-transition-group";
import Spacer from "../Spacer";
import CloseIcon from "assets/icons/CloseIcon";
import StyledButton, { StyledButtonVariant } from "../buttons/StyledButton";
import { ScrollItems, useScrollContext } from "contexts/scroll/ScrollContext";
import { styleGuide } from "theme";

export const rootZIndex: number = 3001;

export type DialogBorderVariant = "single" | "double";

const useStyles = (
    isMobile: boolean,
    backgroundColorAction: string,
    saving: boolean,
    maxWidth?: number,
    borderVariant?: DialogBorderVariant
) =>
    makeStyles((theme: ITheme) =>
        createStyles({
            root: {
                backgroundColor: theme.styles.popoverBackground,
                position: "fixed",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: rootZIndex + 2,
            },
            dialog: {
                position: "relative",
                maxWidth: isMobile && !maxWidth ? 500 : maxWidth ? maxWidth : 600,
                width: "100%",
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "calc(100vh - 150px)",
                gridArea: "1/1",
                placeSelf: "center center",
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.secondary,
                boxShadow:
                    borderVariant === "double"
                        ? `0px 0px 0 2px ${theme.palette.info.main}, -8px 8px 0 0px ${theme.palette.info.main}`
                        : "none",
                border: borderVariant === "double" ? "none" : `2px solid ${theme.palette.info.main}`,
                borderRadius: theme.styles.borderRadiusXs,
                padding: isMobile ? theme.spacing(2) : theme.spacing(4),
                margin: isMobile ? theme.spacing(2) : theme.spacing(3),
                display: "flex",
                flexDirection: "column",
            },
            children: {},
            dialogButton: {
                opacity: saving ? 0.4 : 1,
                position: "absolute",
                padding: theme.spacing(1),
                right: "-2px",
                top: "-2px",
                fontWeight: "bold",
                color: theme.variants.lightWhite,
                backgroundColor: theme.palette.info.main,
                border: "none",
                borderRadius: theme.styles.borderRadiusXxs,
                cursor: "pointer",
                display: "flex",
                transition: theme.styles.hoverEaseInOut + theme.styles.cssImportant,
                "&:hover": {
                    backgroundColor: styleGuide.color.white,
                    color: theme.palette.info.main,
                },
            },
            actionContainer: {
                backgroundColor: backgroundColorAction ? backgroundColorAction : theme.palette.primary.main,
                color: theme.palette.info.main,
                borderRadius: theme.styles.borderRadiusSm,
                padding: backgroundColorAction ? 0 : isMobile ? theme.spacing(1.5) : theme.spacing(3),
                flex: 2,
                overflowY: "auto",
            },
            actionChildren: {
                marginTop: isMobile ? theme.spacing(2) : theme.spacing(3),
                flex: 1,
                display: "flex",
                justifyContent: "center",
                [theme.breakpoints.down(400)]: {
                    flexDirection: "column",
                },
            },
            header: {
                color: theme.palette.info.main,
                marginBottom: isMobile ? theme.spacing(2) : theme.spacing(3),
            },
            label: {
                color: styleGuide.color.white,
            },
        })
    );

interface IProps {
    children: ReactNode;
    childrenAction?: ReactNode;
    backgroundColorAction?: string;
    infoBoxChildren?: ReactNode;
    isOpen: boolean;
    handleClose?: () => void;
    setOpen?: (open: boolean) => void;
    loader?: boolean;
    buttonVariant?: StyledButtonVariant | undefined;
    buttonText?: string;
    saving?: boolean;
    header?: string;
    maxWidth?: number;
    width?: string;
    borderVariant?: DialogBorderVariant;
    disabled?: boolean;
}

const Dialog = ({
    children,
    childrenAction,
    infoBoxChildren,
    isOpen,
    handleClose,
    setOpen,
    buttonVariant = "infoPrimary",
    buttonText,
    backgroundColorAction = "",
    loader = false,
    saving = false,
    header,
    maxWidth,
    width,
    borderVariant = "double",
}: IProps) => {
    const node = useRef<HTMLDivElement>(null!);
    const { disableScroll, handleDisableScroll, handleEnableScroll, scrollItems } = useScrollContext();
    const isMobile = useIsMobile();
    const classes = useStyles(isMobile, backgroundColorAction, saving, maxWidth, borderVariant)();

    useClickOutside(node, () => {
        if (saving) return;

        if (handleClose) {
            handleClose();
        } else if (setOpen) {
            setOpen(false);
        }
    });

    const handleOnClose = () => {
        if (saving) return;

        if (handleClose) {
            handleClose();
        } else if (setOpen) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (scrollItems.includes(ScrollItems.Popover)) return;

        if (isOpen) {
            handleDisableScroll(ScrollItems.Dialog);
        }

        if (isOpen === false) {
            handleEnableScroll(ScrollItems.Dialog);
        }
    }, [isOpen]);

    const defaultStyle = {
        transition: `opacity 0.2ms ease-in-out`,
        opacity: 0,
        backdropFilter: "blur(0px)",
    };

    const transitionStyles = {
        entering: {},
        entered: scrollItems.includes(ScrollItems.Popover)
            ? { opacity: 1, transition: "opacity 0.2s ease-in" }
            : { opacity: 1, transition: "opacity 0.2s ease-in", backdropFilter: "blur(8px)" },
        exiting: { opacity: 0, backdropFilter: "unset", transition: "opacity 0.2s ease-out" },
        exited: {},
        unmounted: {},
    };

    return (
        <Portal wrapperId="portal-container">
            <Transition in={isOpen} timeout={{ enter: 100, exit: 200 }} unmountOnExit>
                {(state: TransitionStatus) => (
                    <>
                        {childrenAction ? (
                            <div
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state],
                                }}
                                className={classes.root}
                            >
                                <div ref={node} className={classes.dialog}>
                                    <button className={classes.dialogButton} onClick={handleOnClose} disabled={saving}>
                                        <CloseIcon
                                            style={{
                                                height: 15,
                                                width: 15,
                                            }}
                                        />
                                    </button>
                                    <Typography style={{ textAlign: "center" }} variant="h5">
                                        {header}
                                    </Typography>
                                    <Spacer />
                                    <span className={classes.actionContainer}>{children}</span>
                                    <span className={classes.actionChildren}>{childrenAction}</span>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    ...defaultStyle,
                                    ...transitionStyles[state],
                                }}
                                className={classes.root}
                            >
                                <div ref={node} className={classes.dialog}>
                                    <button className={classes.dialogButton} onClick={handleOnClose} disabled={saving}>
                                        <CloseIcon
                                            style={{
                                                height: 15,
                                                width: 15,
                                            }}
                                        />
                                    </button>
                                    {infoBoxChildren && (
                                        <>
                                            <span className={classes.actionContainer}>{infoBoxChildren}</span>
                                            <Spacer />
                                        </>
                                    )}
                                    <span className={classes.children}>{children}</span>
                                    <Spacer size={2} />
                                    {buttonText && (
                                        <StyledButton variant={buttonVariant} onClick={handleOnClose} disabled={saving}>
                                            {buttonText}
                                        </StyledButton>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Transition>
        </Portal>
    );
};

export default Dialog;
