import axios from "axios";
import ResponseStatusEnum from "../common/enums/ResponseStatusEnum";
import { routingUrls } from "../settings";
import { dispatchRefreshTokens } from "../contexts/identity/actions/JwtActions";
import { IFetchAccessTokenRequest } from "../apiServices/identity/requests/IFetchAccessTokenRequest";
import { defaultTokenResponse } from "../apiServices/identity/responses/IAccessTokenResponse";
import { dispatchFetchIdentity } from "../contexts/identity/actions/IdentityActions";
import React from "react";

export const setTokenAndUnauthorizedInterceptorIntoAxiosDefaults = (
    accessToken: string,
    dispatch: React.Dispatch<any>,
    refreshRequest: IFetchAccessTokenRequest
) => {
    axios.defaults.headers.authorization = `Bearer ${accessToken}`;

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === ResponseStatusEnum.Unauthorized) {
                console.log("Unauthorized: Trying refresh scenario");

                dispatchRefreshTokens(dispatch, refreshRequest)
                    .then((response) => {
                        if (response.accessToken != defaultTokenResponse.access_token) {
                            axios.defaults.headers.authorization = `Bearer ${response.accessToken}`;

                            dispatchFetchIdentity(dispatch);
                        } else {
                            window.location.href = `${routingUrls.landing}`;
                        }
                    })
                    .catch((reason) => {
                        console.log(`Refresh token failed, reason ${reason}`);
                        window.location.href = `${routingUrls.landing}`;
                    });
            }
        }
    );
};

export const isAuthorized = async (
    dispatch: React.Dispatch<any>,
    request: IFetchAccessTokenRequest
): Promise<boolean> => {
    let authorized = false;

    const tokenResponse = await dispatchRefreshTokens(dispatch, request);

    if (tokenResponse.accessToken !== defaultTokenResponse.access_token) {
        setTokenAndUnauthorizedInterceptorIntoAxiosDefaults(tokenResponse.accessToken, dispatch, request);
        authorized = true;
    }
    await dispatchFetchIdentity(dispatch);
    return authorized;
};
