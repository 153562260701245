import { ImgHTMLAttributes, HTMLAttributes } from "react";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import LazyLoad from "react-lazyload";
import ProgressiveImage from "react-progressive-image";
import calculateAspectRatio from "utils/calculateAspectRatio";
import calculateAspectRatioPaddingTopPercentage from "utils/calculateAspectRatioPaddingTopPercentage";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import { ITheme } from "@material-ui/core";

const useStyles = (hoverState: boolean) =>
    makeStyles((theme: Theme) => {
        const zoomPercentageAmount = 5;

        const hoverableImageHoverStateStyles = {
            height: `${100 + zoomPercentageAmount}%`,
            width: `${100 + zoomPercentageAmount}%`,
            top: `-${zoomPercentageAmount / 2}%`,
            left: `-${zoomPercentageAmount / 2}%`,
        };

        const getBackgroundImageTransitions = (properties: string[]) =>
            properties.map((property) => theme.transitions.create(property)).join(", ");

        return createStyles({
            root: {
                overflow: "hidden",
                display: "flex",
                position: "relative",
                "& .lazyload-wrapper": {
                    display: "flex",
                    flex: "1",
                },
            },
            hoverable: {
                "@media (hover: hover) and (pointer: fine)": {
                    "&:hover": {
                        "& $hoverableImage": {
                            ...hoverableImageHoverStateStyles,
                        },
                    },
                },
            },
            absoluteFull: {
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
            },
            imageContainer: {
                display: "flex",
                transition: getBackgroundImageTransitions(["height", "width", "top", "left"]),
            },
            hoverableImage: hoverState
                ? {
                      ...hoverableImageHoverStateStyles,
                  }
                : {},
        });
    });

type ImagePropsTypes = Omit<
    Omit<
        Omit<
            Omit<Omit<Omit<Omit<ImgHTMLAttributes<HTMLImageElement>, "src">, "placeholder">, "alt">, "height">,
            "width"
        >,
        "srcSet"
    >,
    "sizes"
>;

type objectFit = "cover" | "contain";

export interface IImgProps extends HTMLAttributes<HTMLDivElement> {
    src: string;
    height: number;
    width: number;
    placeholder?: string;
    alt?: string;
    srcSet?: string;
    sizes?: string;
    loading?: boolean;
    imageProps?: ImagePropsTypes;
    loadingOpacity?: number;
    hoverable?: boolean;
    hoverState?: boolean;
    progressiveImageDelay?: number;
    disableLazyLoad?: boolean;
    lazyLoadOffset?: number;
    objectFit?: objectFit;
    objectPosition?: string;
}

export default function Img(props: IImgProps) {
    const {
        src,
        placeholder = "",
        alt,
        srcSet = "",
        sizes = "",
        width,
        height,
        loading,
        imageProps = {},
        loadingOpacity = 0.8,
        hoverable,
        hoverState = false,
        progressiveImageDelay = 0,
        disableLazyLoad,
        lazyLoadOffset = 500,
        children,
        className,
        objectFit = "cover",
        objectPosition = "unset",
        ...rest
    } = props;

    const classes = useStyles(hoverState)();
    const theme: ITheme = useTheme();

    const { style: imageStyle, className: imageClassName, ...imagePropsRest } = imageProps;

    const aspectRatio = calculateAspectRatio({ height, width });

    const paddingTopPercentage = calculateAspectRatioPaddingTopPercentage({
        width: aspectRatio,
        height: 1,
    });

    const placeholderComponent = (
        <Skeleton
            animation={theme.styles.skeleton.animation}
            variant="rect"
            width="100%"
            height={0}
            className={classes.absoluteFull}
            style={{
                paddingTop: paddingTopPercentage,
                backgroundColor: theme.styles.skeleton.color(theme),
                opacity: loadingOpacity,
            }}
        />
    );

    const loadingStyles = (_loading: boolean) => {
        const opacityImageTransition = theme.transitions.create("all", {
            duration: 150,
            easing: theme.transitions.easing.easeInOut,
        });

        return {
            opacity: _loading ? loadingOpacity : 1,
            filter: `blur(${_loading ? 10 : 0}px)`,
            transition: opacityImageTransition,
        };
    };

    const imageContent = (
        <ProgressiveImage
            src={src}
            placeholder={placeholder}
            delay={progressiveImageDelay}
            srcSetData={{
                srcSet: srcSet,
                sizes: sizes,
            }}
        >
            {(_src: string, _loading: boolean, srcSetData: { srcSet: string; sizes: string }) => (
                <div
                    className={clsx(classes.imageContainer, classes.absoluteFull, {
                        [classes.hoverableImage]: hoverable || (hoverState && !_loading),
                        // [classes.hoverableImage]: hoverState && !_loading
                    })}
                >
                    <img
                        className={imageClassName}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: objectFit,
                            objectPosition: objectPosition,
                            ...loadingStyles(_loading),
                            ...imageStyle,
                        }}
                        src={_src}
                        alt={alt}
                        width={width}
                        height={height}
                        srcSet={srcSetData.srcSet}
                        sizes={srcSetData.sizes}
                        {...imagePropsRest}
                    />
                </div>
            )}
        </ProgressiveImage>
    );

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.hoverable]: hoverable,
            })}
            {...rest}
        >
            <div style={{ paddingTop: paddingTopPercentage, height: 0, flex: "1" }}>
                {loading ? (
                    placeholderComponent
                ) : disableLazyLoad ? (
                    imageContent
                ) : (
                    <LazyLoad height="100%" offset={lazyLoadOffset} once placeholder={placeholderComponent}>
                        {imageContent}
                    </LazyLoad>
                )}
            </div>
        </div>
    );
}
