import { ActionMap } from "common/types/ActionMapType";
import { saveCoverPhoto, fetchCoverPhotos, fetchProviders } from "apiServices/preferences/PreferenceApiService";
import IPreference from "pages/preferences/interfaces/IPreference";
import IAccountProviders from "pages/preferences/interfaces/IAccountProviders";
import { images } from "settings";
import { IUserPreferencesStore } from "contexts/identity/IdentityStore";
import IPlatform from "common/interfaces/IPlatform";

export enum PreferencesTypes {
    SaveIdentityCoverPhoto = "SET_IDENTITY_COVER_PHOTO",
    SaveIdentityCoverPhotoCompleted = "SET_IDENTITY_COVER_PHOTO_COMPLETED",
    SaveIdentityProfilePicture = "SET_IDENTITY_PROFILE_PICTURE",
    SaveIdentityProfilePictureCompleted = "SET_IDENTITY_PROFILE_PICTURE_COMPLETED",
    LinkIdentityProvider = "LINK_IDENTITY_PROVIDER",
    LinkIdentityProviderCompleted = "LINK_IDENTITY_PROVIDER_COMPLETED",
    FetchIdentityPreferences = "FETCH_IDENTITY_PREFERENCES",
    FetchIdentityPreferencesCompleted = "FETCH_IDENTITY_PREFERENCES_COMPLETED",
    FetchCoverPhotos = "FETCH_COVER_PHOTOS",
    FetchCoverPhotosCompleted = "FETCH_COVER_PHOTOS_COMPLETED",
    FetchProfilePictures = "FETCH_PROFILE_PICTURES",
    FetchProfilePicturesCompleted = "FETCH_PROFILE_PICTURES_COMPLETED",
    FetchProviders = "FETCH_PROVIDERS",
    FetchProvidersCompleted = "FETCH_PROVIDERS_COMPLETED",
}

type PreferencesTypePayload = {
    [PreferencesTypes.SaveIdentityCoverPhoto]: string;
    [PreferencesTypes.SaveIdentityCoverPhotoCompleted]: string;
    [PreferencesTypes.SaveIdentityProfilePicture]: string;
    [PreferencesTypes.SaveIdentityProfilePictureCompleted]: string;
    [PreferencesTypes.LinkIdentityProvider]: IAccountProviders[];
    [PreferencesTypes.LinkIdentityProviderCompleted]: IAccountProviders[];
    [PreferencesTypes.FetchIdentityPreferences]: IPreference;
    [PreferencesTypes.FetchIdentityPreferencesCompleted]: IPreference;
    [PreferencesTypes.FetchCoverPhotos]: string[];
    [PreferencesTypes.FetchCoverPhotosCompleted]: string[];
    [PreferencesTypes.FetchProfilePictures]: string[];
    [PreferencesTypes.FetchProfilePicturesCompleted]: string[];
    [PreferencesTypes.FetchProviders]: IPlatform[];
    [PreferencesTypes.FetchProvidersCompleted]: IPlatform[];
};

export type PreferencesActions = ActionMap<PreferencesTypePayload>[keyof ActionMap<PreferencesTypePayload>];

const handleSetCoverPhoto = (coverPhoto: string) =>
    coverPhoto !== null && coverPhoto !== "" ? coverPhoto : images.defaultCover;

const handleSetProfilePicture = (profilePicture: string) =>
    profilePicture !== null && profilePicture !== "" ? profilePicture : images.defaultAvatar;

export const dispatchSaveIdentityCoverPhoto = async (
    dispatch: React.Dispatch<any>,
    coverPhotoUrl: string
): Promise<string> => {
    dispatch({
        type: PreferencesTypes.SaveIdentityCoverPhoto,
    });

    await saveCoverPhoto(coverPhotoUrl);

    dispatch({
        type: PreferencesTypes.SaveIdentityCoverPhotoCompleted,
        payload: handleSetCoverPhoto(coverPhotoUrl),
    });

    return coverPhotoUrl;
};

export const dispatchSetIdentityPreferences = (dispatch: React.Dispatch<any>, preferences: IPreference) => {
    dispatch({
        type: PreferencesTypes.FetchIdentityPreferencesCompleted,
        payload: {
            coverPhotoUrl: handleSetCoverPhoto(preferences?.coverPhotoUrl),
            privacyLevelId: preferences?.privacyLevelId,
            profileImageUrl: handleSetProfilePicture(preferences?.profileImageUrl),
            providers: preferences?.providers,
        },
    });

    return preferences;
};

export const dispatchFetchCoverPhotos = async (dispatch: React.Dispatch<any>): Promise<string[]> => {
    dispatch({
        type: PreferencesTypes.FetchCoverPhotos,
    });

    const result = await fetchCoverPhotos();

    dispatch({
        type: PreferencesTypes.FetchCoverPhotosCompleted,
        payload: result,
    });

    return result;
};

export const dispatchFetchProviders = async (dispatch: React.Dispatch<any>): Promise<IPlatform[]> => {
    dispatch({
        type: PreferencesTypes.FetchProviders,
    });

    const result = await fetchProviders();

    dispatch({
        type: PreferencesTypes.FetchProvidersCompleted,
        payload: result,
    });

    return result;
};

export const preferencesReducer = (state: IUserPreferencesStore, action: PreferencesActions): IUserPreferencesStore => {
    switch (action.type) {
        case PreferencesTypes.SaveIdentityCoverPhoto:
        case PreferencesTypes.SaveIdentityProfilePicture:
        case PreferencesTypes.FetchIdentityPreferences:
        case PreferencesTypes.FetchCoverPhotos:
        case PreferencesTypes.FetchProfilePictures:
        case PreferencesTypes.FetchProviders:
        case PreferencesTypes.LinkIdentityProvider:
            return {
                ...state,
                loading: true,
            };
        case PreferencesTypes.SaveIdentityCoverPhotoCompleted:
            return {
                ...state,
                userPreferences: {
                    ...state.userPreferences,
                    coverPhotoUrl: action.payload,
                },
                loading: false,
            };
        case PreferencesTypes.SaveIdentityProfilePictureCompleted:
            return {
                ...state,
                userPreferences: {
                    ...state.userPreferences,
                    profileImageUrl: action.payload,
                },
                loading: false,
            };
        case PreferencesTypes.LinkIdentityProviderCompleted:
            return {
                ...state,
                userPreferences: {
                    ...state.userPreferences,
                    providers: action.payload,
                },
            };
        case PreferencesTypes.FetchIdentityPreferencesCompleted:
            return {
                ...state,
                userPreferences: action.payload,
                loading: false,
            };
        case PreferencesTypes.FetchCoverPhotosCompleted:
            return {
                ...state,
                preferencesValues: {
                    ...state.preferencesValues,
                    coverPhotos: action.payload,
                },
                loading: false,
            };
        case PreferencesTypes.FetchProfilePicturesCompleted:
            return {
                ...state,
                preferencesValues: {
                    ...state.preferencesValues,
                    profileImages: action.payload,
                },
                loading: false,
            };
        case PreferencesTypes.FetchProvidersCompleted:
            return {
                ...state,
                preferencesValues: {
                    ...state.preferencesValues,
                    providers: action.payload,
                },
                loading: false,
            };
        default:
            return state;
    }
};
