import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ArrowLeftIconV2 = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
                <path
                    id="Path_13"
                    d="M32.4,33.66l-7.86-7.86c-0.44-0.44-0.44-1.15,0-1.59l7.86-7.86V0L8.2,24.2c-0.44,0.44-0.44,1.15,0,1.59
	L32.4,50V33.66z"
                />
            </svg>
        </SvgIcon>
    );
};

export default ArrowLeftIconV2;
