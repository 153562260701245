import { useEffect, ReactNode } from "react";
import Typography from "@material-ui/core/Typography";
import { useNotificationContext } from "contexts/notification/NotificationContext";
import NotificationsCardResolver from "common/components/notifications/cardResolver/NotificationsCardResolver";
import INotification from "./interfaces/INotification";
import LoadableButton from "../buttons/LoadableButton";
import ResponsiveSpacer from "../ResponsiveSpacer";
import NotificationsActions from "./NotificationsActions";
import {
    dispatchFetchNewNotifications,
    dispatchSetNewNotificationsCount,
} from "contexts/notification/actions/NewNotificationActions";
import { dispatchFetchOldNotifications } from "contexts/notification/actions/OldNotificationActions";
import { ITheme, useTheme } from "@material-ui/core";

interface IProps {
    disableActions?: boolean;
}

export default function NotificationsContent({ disableActions = false }: IProps) {
    const { state, dispatch } = useNotificationContext();
    const theme: ITheme = useTheme();

    useEffect(() => {
        dispatchFetchNewNotifications(
            dispatch,
            state.newNotificationStore.notifications,
            state.newNotificationStore.nextPageCursor
        ).then(() => {
            dispatchSetNewNotificationsCount(dispatch, state.newNotificationStore.count);
        });
    }, []);

    return (
        <>
            {state.newNotificationStore.notifications.length === 0 &&
                state.oldNotificationStore.notifications.length === 0 && (
                    <Typography style={{ color: theme.palette.text.secondary }} variant="h6" align="center">
                        You don't have any notifications.
                    </Typography>
                )}

            {state.newNotificationStore.notifications.length > 0 && (
                <NotificationsSection
                    title="New notifications"
                    notifications={state.newNotificationStore.notifications}
                    isLastPage={state.newNotificationStore.isLastPage}
                    loadMore={() =>
                        dispatchFetchNewNotifications(
                            dispatch,
                            state.newNotificationStore.notifications,
                            state.newNotificationStore.nextPageCursor
                        )
                    }
                    loading={state.newNotificationStore.loading}
                    titleSuffix={disableActions ? undefined : <NotificationsActions />}
                />
            )}

            {state.newNotificationStore.notifications.length > 0 &&
                state.oldNotificationStore.notifications.length > 0 && <ResponsiveSpacer layer="1" />}

            {state.oldNotificationStore.notifications.length > 0 && (
                <NotificationsSection
                    title="Older notifications"
                    notifications={state.oldNotificationStore.notifications}
                    isLastPage={state.oldNotificationStore.isLastPage}
                    loadMore={() =>
                        dispatchFetchOldNotifications(
                            dispatch,
                            state.oldNotificationStore.notifications,
                            state.oldNotificationStore.nextPageCursor
                        )
                    }
                    loading={state.oldNotificationStore.loading}
                    titleSuffix={
                        disableActions || state.newNotificationStore.notifications.length > 0 ? undefined : (
                            <NotificationsActions />
                        )
                    }
                />
            )}
        </>
    );
}

interface INotificationsSectionProps {
    title: string;
    notifications: INotification[];
    isLastPage: boolean;
    loadMore: () => void;
    loading: boolean;
    titleSuffix?: ReactNode;
}

const NotificationsSection = (props: INotificationsSectionProps) => {
    const { title, notifications, isLastPage, loadMore, loading, titleSuffix } = props;
    const theme: ITheme = useTheme();

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 8 }}>
                <Typography style={{ color: theme.palette.text.secondary }} variant="h6">
                    {title}
                </Typography>
                {titleSuffix}
            </div>
            {notifications.map((notification) => (
                <NotificationsCardResolver {...notification} />
            ))}
            {!isLastPage && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <LoadableButton variant="transparentLight" size="small" onClick={loadMore} isLoading={loading}>
                        Load more
                    </LoadableButton>
                </div>
            )}
        </>
    );
};
