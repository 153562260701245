enum GamesEnum {
    DirtRally = 1,
    Dirt4 = 3,
    DirtRally2 = 7,
    Grid = 9,
    F12020 = 10,
    Dirt5 = 11,
    DirtRally3 = 12,
    Grid2021 = 13,
    F12021 = 14,
    F12022 = 15,
    F12023 = 21,
    F12024 = 22,
    FastAndFuriousCC = 16,
    WRC2023 = 20,
}

export default GamesEnum;
