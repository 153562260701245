import { ITheme, useTheme } from "@material-ui/core";
import ArrowLeftIconV2 from "assets/icons/ArrowLeftIconV2";
import useResponsivePadding from "hooks/useResponsivePadding";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { routingUrls } from "settings";
import { IStyles, styleGuide } from "theme";
import { escapeUrl } from "utils/urlHelper";

interface IProps {
    disabled?: boolean;
}

const HubV3MobileBackButton = ({ disabled }: IProps) => {
    const theme: ITheme = useTheme();
    const responsivePadding = useResponsivePadding();
    const history = useHistory();
    const location = useLocation();

    const handleGoBack = () => {
        //WRC Hub
        if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.root)})$`).test(location.pathname)) {
            history.push(routingUrls.games);
        }
        //WRC Stats
        else if (
            new RegExp(`(${escapeUrl(routingUrls.wrc2023.playerStatsCompare)}\/[0-9]+\/[0-9]\/[a-zA-Z]+)$`).test(
                location.pathname
            )
        ) {
            history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
        } else if (
            new RegExp(`(${escapeUrl(routingUrls.wrc2023.playerStatsCompare)}\/[0-9]+\/[0-9])$`).test(location.pathname)
        ) {
            history.push(routingUrls.wrc2023.root);
        } else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.playerStats)}\/[a-zA-Z]+)$`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.playerStats);
        } else if (new RegExp(escapeUrl(routingUrls.wrc2023.playerStats)).test(location.pathname)) {
            history.push(routingUrls.wrc2023.root);
        } else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.playerStatsCompare)})$`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.root);
        }
        //WRC leaderboard
        else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.leaderboards)})`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.root);
        }
        //WRC Clubs
        else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.club)})$`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.root);
        } else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.club)}\/[0-9]+)$`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.club);
        } else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.club)}\/[0-9]+\/members)$`).test(location.pathname)) {
            history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
        }
        // PA
        else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.performanceAnalysis)})$`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.root);
        }
        //News
        else if (new RegExp(`(${escapeUrl(routingUrls.wrc2023.news)})$`).test(location.pathname)) {
            history.push(routingUrls.wrc2023.root);
        } else {
            history.goBack();
        }
    };

    const styles: IStyles = {
        root: {
            opacity: disabled ? 0.4 : 1,
            zIndex: 1,
            pointerEvents: "all",
            backgroundColor: "rgba(4, 12, 30, 0.8)",
            border: "none",
            borderRadius: "50%",
            display: "flex",
            margin: 0,
            padding: responsivePadding * 1.25,
        },
        icon: { display: "flex", color: styleGuide.color.white },
    };

    return (
        <button style={styles.root} onClick={handleGoBack} type="button" disabled={disabled}>
            <ArrowLeftIconV2 style={styles.icon} />
        </button>
    );
};

export default HubV3MobileBackButton;
