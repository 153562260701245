import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import { ITheme } from "@material-ui/core";
import { styleGuide } from "theme";

const useStyles = makeStyles((theme: ITheme) => {
    return createStyles({
        root: {
            backgroundColor: styleGuide.color.white,
            border: `solid 3px ${theme.palette.info.dark}`,
            borderRadius: theme.styles.borderRadiusSm,
        },
        content: {
            color: theme.palette.secondary.dark,
            flex: 1,
        },
    });
});

export interface IStyledSnackbarProps extends SnackbarProps {}

export default function StyledSnackbar(props: IStyledSnackbarProps) {
    const classes = useStyles();
    const { className, children, ...rest } = props;

    return (
        <Snackbar className={clsx(className, classes.root)} {...rest}>
            <div className={clsx(classes.content)} style={rest.style}>
                {children}
            </div>
        </Snackbar>
    );
}
