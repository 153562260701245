import { ITheme, makeStyles, createStyles, Typography, useTheme } from "@material-ui/core";
import useIsMobile from "hooks/useIsMobile";
import { HTMLAttributes } from "react";

const useStyles = (isMobile: boolean, backgroundColor: string, margin: number) =>
    makeStyles((theme: ITheme) =>
        createStyles({
            // Animation
            "@keyframes pulse": {
                " 0%": {
                    transform: "scale(.99)",
                    opacity: 0.4,
                },
                "100%": {
                    transform: "scale(1)",
                    opacity: 1,
                },
            },
            root: {
                display: "block",
                backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.light,
                animation: "$pulse 0.75s alternate infinite",
                marginBottom: theme.spacing(margin),
            },
        })
    );

export type SkeletonVariant = "title" | "box" | "circle";

interface IProps extends HTMLAttributes<HTMLDivElement> {
    skeletonType: SkeletonVariant;
    height?: number | string;
    width?: number | string;
    backgroundColor?: string;
    margin?: number;
}

const SkeletonElement = ({ skeletonType, height, width, backgroundColor = "", margin = 1.5, ...rest }: IProps) => {
    const isMobile = useIsMobile();
    const theme: ITheme = useTheme();
    const classes = useStyles(isMobile, backgroundColor, margin)();

    const styles = {
        title: {
            height: height ? height : isMobile ? 15 : 20,
            width: width ? width : "100%",
            borderRadius: theme.styles.borderRadiusXs,
        },
        box: {
            height: height ? height : isMobile ? 120 : 150,
            width: width ? width : "100%",
            borderRadius: theme.styles.borderRadiusXs,
        },
        circle: {
            height: height ? height : isMobile ? 30 : 50,
            width: width ? width : isMobile ? 30 : 50,
            borderRadius: "100%",
        },
    };

    const getStyles = (skeletonType: SkeletonVariant) => {
        switch (skeletonType) {
            case "title":
                return styles.title;
            case "box":
                return styles.box;
            case "circle":
                return styles.circle;
        }
    };

    const skeletonStyles = getStyles(skeletonType);

    return (
        <div
            className={classes.root}
            style={{
                ...skeletonStyles,
            }}
            {...rest}
        ></div>
    );
};

export default SkeletonElement;
