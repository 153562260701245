export interface IAccessTokenResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    id_token: null;
}

export const defaultTokenResponse: IAccessTokenResponse = {
    access_token: "",
    refresh_token: "",
    expires_in: 0,
    token_type: "",
    id_token: null,
};
