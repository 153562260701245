import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function FeedbackIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M21.78,6.42c0.53,0,0.96,0.43,0.96,0.96v8.9c0,0.53-0.43,0.96-0.96,0.96h-6.32c-0.57,0-1.12,0.23-1.51,0.63L12,19.86 l-1.94-1.97c-0.4-0.4-0.95-0.63-1.51-0.63H2.22c-0.53,0-0.96-0.43-0.96-0.96v-8.9c0-0.53,0.43-0.96,0.96-0.96H21.78 M21.78,5.16 H2.22C0.99,5.16,0,6.16,0,7.38v8.9c0,1.23,0.99,2.22,2.22,2.22h6.32c0.23,0,0.46,0.09,0.62,0.26l2.33,2.37 c0.14,0.14,0.32,0.21,0.51,0.21c0.18,0,0.37-0.07,0.51-0.21l2.33-2.37c0.16-0.17,0.39-0.26,0.62-0.26h6.32 c1.23,0,2.22-0.99,2.22-2.22v-8.9C24,6.16,23.01,5.16,21.78,5.16L21.78,5.16z" />
            <g>
                <path d="M4.93,13.99L3.2,14.89c-0.24,0.12-0.51-0.08-0.47-0.34l0.33-1.92l-1.4-1.36c-0.19-0.19-0.09-0.51,0.18-0.55l1.93-0.28 l0.86-1.75c0.12-0.24,0.46-0.24,0.58,0l0.86,1.75l1.93,0.28c0.26,0.04,0.37,0.36,0.18,0.55l-1.4,1.36l0.33,1.92 c0.05,0.26-0.23,0.46-0.47,0.34L4.93,13.99z" />
            </g>
            <g>
                <path d="M12.02,13.99l-1.73,0.91c-0.24,0.12-0.51-0.08-0.47-0.34l0.33-1.92l-1.4-1.36c-0.19-0.19-0.09-0.51,0.18-0.55l1.93-0.28 l0.86-1.75c0.12-0.24,0.46-0.24,0.58,0l0.86,1.75l1.93,0.28c0.26,0.04,0.37,0.36,0.18,0.55l-1.4,1.36l0.33,1.92 c0.05,0.26-0.23,0.46-0.47,0.34L12.02,13.99z" />
            </g>
            <g>
                <path d="M19.12,13.99l-1.73,0.91c-0.24,0.12-0.51-0.08-0.47-0.34l0.33-1.92l-1.4-1.36c-0.19-0.19-0.09-0.51,0.18-0.55l1.93-0.28 l0.86-1.75c0.12-0.24,0.46-0.24,0.58,0l0.86,1.75l1.93,0.28c0.26,0.04,0.37,0.36,0.18,0.55l-1.4,1.36l0.33,1.92 c0.05,0.26-0.23,0.46-0.47,0.34L19.12,13.99z" />
            </g>
        </SvgIcon>
    );
}
