import moment, { Moment } from "moment";

const getLongDate = (date: string | Date) => {
    if (moment().diff(date, "days") < 7) {
        return "MMMM Do YYYY";
    } else if (moment().diff(date, "weeks", true) < 4) {
        return `[${moment().diff(date, "weeks")}w]`;
    } else {
        return `[${moment().diff(date, "months")}m]`;
    }
};

export const getLocalDate = (date: string | Date | number) => {
    const locale = navigator.language;
    const d = new Date(date);
    const localFormat = new Intl.DateTimeFormat(locale).format;
    return localFormat(d);
};

export const getUserTimeZone = () => {
    // Get user timezone, if not best guess: https://stackoverflow.com/questions/40401543/get-timezone-from-users-browser-using-momenttimezone-js
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (userTimeZone) {
        return userTimeZone;
    }
};

export const getLocalDateWithDash = (date: string | Date | number) => {
    const locale = navigator.language;
    const d = new Date(date);
    const localFormat = new Intl.DateTimeFormat(locale).format;
    const regEx = /\//g;

    return localFormat(d).replace(regEx, "-");
};

export const getLocalDefaultDate = (utcDate: string | Date | number) => {
    const localDate = moment.utc(utcDate).local().format("YYYY-MM-DD");
    return localDate;
};

export const getLocalDefaultDateWithSlash = (utcDate: string | Date | number) => {
    const locale = navigator.language;

    const localDate = moment.utc(utcDate).local().format("L");
    const d = moment(localDate).toDate();
    const regEx = /\//g;
    const localFormat = new Intl.DateTimeFormat(locale).format;
    return localFormat(d).replace(regEx, "-");
};

export const getLocalDefaultDateWithMinutes = (utcDate: string | Date | number) => {
    const localDate = moment.utc(utcDate).local().format("YYYY-MM-DD HH:mm");
    return localDate;
};

export const getLocalDefaultDateWithTime = (utcDate: string | Date | number) => {
    const localDate = moment.utc(utcDate).local().format("YYYY-MM-DD HH:mm:ss");
    return localDate;
};

export const getLocalDefaultDateWithTimeMinutesAndSeconds = (utcDate: string | Date | number) => {
    const localDate = moment.utc(utcDate).local().format("YYYY-MM-DDTHH:mm:ss.SSS");
    return localDate;
};

//function above and below do the same thing, needs to cleanup

export const getLocalShortDateFromUTC = (utcTime: string | Date | number) => {
    const localDate = moment.utc(utcTime).local().format("MMM Do");
    return localDate;
};

export const getLocalShortNoAbvDateFromUTC = (utcTime: string | Date | number) => {
    const localDate = moment.utc(utcTime).local().format("MMM D");
    return localDate;
};

export const getLocalShortNoAbvDateWithTimeFromUTC = (utcTime: string | Date | number) => {
    const localDate = moment.utc(utcTime).local().format("MMM D, h:mma");
    return localDate;
};

export const getLocalDateFromUTC = (utcTime: string | Date | number) => {
    const localDate = moment.utc(utcTime).local().format("MMM D, YYYY");
    return localDate;
};

// Format Mar 8, 2022
export const getLocalShortDateWithYearFromUTC = (utcTime: string | Date | number) => {
    const localDate = moment.utc(utcTime).local().format("MMM D, YYYY");
    return localDate;
};

export const getLocalTimefromUTC = (utcTime: string | Date | number) => {
    const localTime = moment.utc(utcTime).local().format("h:mma");
    return localTime;
};

export const getEventRangeDate = (start: string, end: string) => {
    if (moment(start) > moment(end)) return "";

    const startDate = moment(start).format("Do MMMM");
    const endDate = moment(end).format("Do MMMM");

    return startDate + " - " + endDate;
};

export const getEventStartDate = (date: string | Date, textOverride?: string) => {
    return moment(date).calendar(null, {
        sameDay: `[${textOverride ? textOverride : "Event"} starts today]`,
        nextDay: `[${textOverride ? textOverride : "Event"} starts tomorrow]`,
        nextWeek: `[${textOverride ? textOverride : "Event"} starts on] dddd`,
        lastDay: `[${textOverride ? textOverride : "Event"} started yesterday]`,
        lastWeek: "dddd",
        sameElse: "[on] MMM D, YYYY",
    });
};

export const getActivityFeedDate = (date: string | Date) => {
    if (moment().diff(date, "days") < 0) return;
    return moment(date).calendar(null, {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: getLongDate(date),
        sameElse: getLongDate(date),
    });
};

export const getLastPlayedDate = (date: string | Date) => {
    return moment(date).calendar(null, {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "dddd",
        sameElse: "MMM D, YYYY",
    });
};

export const getShortMonthName = (date: string | Date) => moment(date).format("MMM");

export const getDayNumber = (date: string | Date) => moment(date).date();

export const isUTCDateBeforeNow = (date: string | Date) => {
    const now = moment().utc();
    const utcDate = moment(date).utc();

    return utcDate.isBefore(now);
};

export const isUTCDateAfterNow = (date: string | Date) => {
    const now = moment().utc();
    const utcDate = moment(date).utc();

    return utcDate.isAfter(now);
};

// 22 minutes/ a few seconds ago...
export const getTimeDifferenceInUTC = (date: string | Date) => {
    const timeNow = moment().utc();
    const diff = timeNow.diff(moment(date));
    const duration = moment.duration(diff);

    return duration.humanize();
};

// 1s, 2m, 3h, 4d...
export const calculateFormattedTimeDifference = (date: string, maxDays?: number): string => {
    const max = maxDays ? maxDays : 365;

    const timeNow = moment.utc();
    const diff = timeNow.diff(moment(date));
    const duration = moment.duration(diff);

    if (duration.asSeconds() < 60) {
        return `${Math.floor(duration.asSeconds())}s`;
    } else if (duration.asMinutes() < 60) {
        return `${Math.floor(duration.asMinutes())}m`;
    } else if (duration.asHours() < 24) {
        return `${Math.floor(duration.asHours())}h`;
    } else if (duration.asDays() <= max) {
        return `${Math.floor(duration.asDays())}d`;
    } else {
        return `+${max}d`;
    }
};
