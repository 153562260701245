import { getNotifications } from "apiServices/notifications/NotificationsApiService";
import { NotificationFilter } from "apiServices/notifications/requests/INotificationsRequest";
import INotification from "common/components/notifications/interfaces/INotification";
import { ActionMap } from "common/types/ActionMapType";
import { IOldNotificationStore } from "../NotificationContextStore";

export enum OldNotificationTypes {
    FetchOldNotifications = "FETCH_OLD_NOTIFICATIONS",
    FetchOldNotificationsCompleted = "FETCH_OLD_NOTIFICATIONS_COMPLETED",
    SetOldNotifications = "SET_OLD_NOTFICATIONS",
    SetOldNotificationsIsLastPage = "SET_OLD_NOTFICATIONS_IS_LAST_PAGE",
    SetOldNotificationsLoading = "SET_OLD_NOTFICATIONS_IS_LOADING",
    SetOldNotificationsNextPageCursor = "SET_NOTIFICATIONS_NEXT_PAGE_CURSOR",
}

type OldNotificationTypePayload = {
    [OldNotificationTypes.FetchOldNotifications]: INotification[];
    [OldNotificationTypes.FetchOldNotificationsCompleted]: INotification[];
    [OldNotificationTypes.SetOldNotifications]: INotification[];
    [OldNotificationTypes.SetOldNotificationsIsLastPage]: boolean;
    [OldNotificationTypes.SetOldNotificationsLoading]: boolean;
    [OldNotificationTypes.SetOldNotificationsNextPageCursor]: string;
};

export type OldNotificationActions = ActionMap<OldNotificationTypePayload>[keyof ActionMap<OldNotificationTypePayload>];

const numberOfNotificationsPerPage = 10;

export const dispatchFetchOldNotifications = async (
    dispatch: React.Dispatch<any>,
    oldNotifications: INotification[],
    oldNextPageCursor?: string
) => {
    dispatch({
        type: OldNotificationTypes.FetchOldNotifications,
    });

    dispatchSetOldNotificationsLoading(dispatch, true);

    const response = await getNotifications({
        cursor: oldNextPageCursor,
        take: numberOfNotificationsPerPage,
        filter: NotificationFilter.Read,
    });

    dispatchSetOldNotifications(dispatch, oldNotifications.concat(response.notifications));
    dispatchSetNextPageCursor(dispatch, response.nextPageCursor);
    dispatchSetOldNotificationsIsLastPage(dispatch, !response.loadMore);
    dispatchSetOldNotificationsLoading(dispatch, false);

    return response.notifications;
};

export const dispatchSetOldNotifications = (dispatch: React.Dispatch<any>, oldNotifications: INotification[]) => {
    dispatch({
        type: OldNotificationTypes.SetOldNotifications,
        payload: oldNotifications,
    });
};

export const dispatchSetOldNotificationsIsLastPage = (dispatch: React.Dispatch<any>, isLastPage: boolean) => {
    dispatch({
        typye: OldNotificationTypes.SetOldNotificationsIsLastPage,
        payload: isLastPage,
    });
};

export const dispatchSetOldNotificationsLoading = (dispatch: React.Dispatch<any>, loading: boolean) => {
    dispatch({
        type: OldNotificationTypes.SetOldNotificationsLoading,
        payload: loading,
    });
};

export const dispatchSetNextPageCursor = (dispatch: React.Dispatch<any>, cursor?: string) => {
    dispatch({
        type: OldNotificationTypes.SetOldNotificationsNextPageCursor,
        payload: cursor,
    });

    return cursor;
};

export const oldNotificationReducer = (
    state: IOldNotificationStore,
    action: OldNotificationActions
): IOldNotificationStore => {
    switch (action.type) {
        case OldNotificationTypes.SetOldNotifications:
            return {
                ...state,
                notifications: action.payload,
            };
        case OldNotificationTypes.SetOldNotificationsIsLastPage:
            return {
                ...state,
                isLastPage: action.payload,
            };
        case OldNotificationTypes.SetOldNotificationsLoading:
            return {
                ...state,
                loading: action.payload,
            };
        case OldNotificationTypes.SetOldNotificationsNextPageCursor:
            return {
                ...state,
                nextPageCursor: action.payload,
            };
        default:
            return state;
    }
};
