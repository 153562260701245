import React from "react";
import StickyFooter from "./StickyFooter";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Spacer from "common/components/Spacer";
import useIsMobile from "hooks/useIsMobile";
import { isRaceNetMobileApp, isRaceNetTabletApp } from "utils/webViewTools";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {},
        childContainer: {
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            minWidth: 0,
        },
    });
});

interface IProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function PageLayout(props: IProps) {
    const { children, className, ...rest } = props;
    const isMobile = useIsMobile();

    const classes = useStyles();

    return (
        <StickyFooter
            className={clsx(className, classes.root)}
            footer={<Spacer size={isMobile && !isRaceNetMobileApp && !isRaceNetTabletApp ? 8 : 0} />}
            {...rest}
        >
            <div className={classes.childContainer}>{children}</div>
        </StickyFooter>
    );
}
