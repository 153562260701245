import React, { ReactNode } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { layout } from "theme";
import clsx from "clsx";
import useResponsivePaddingClass from "hooks/useResponsivePaddingClass";

const useStyles = (maxWidth: number | "unset") =>
    makeStyles(() => {
        return createStyles({
            pageSection: {
                flex: "1 1 auto",
                display: "flex",
                justifyContent: "center",
                minWidth: 0,
            },
            maxWidthContainer: {
                minWidth: 0,
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                maxWidth: maxWidth,
            },
        });
    });

type IClasses = Partial<ReturnType<ReturnType<typeof useStyles>>>;

interface IPageSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
    noPadding?: boolean;
    maxWidth?: number | "unset";
    classes?: IClasses;
}

export default function PageSection(props: IPageSectionProps) {
    const {
        children,
        className,
        noPadding = false,
        maxWidth = layout.defaultPageSectionMaxWidth,
        classes: overrideClasses,
        ...rest
    } = props;
    const classes = useStyles(maxWidth)();
    const responsivePaddingClassName = useResponsivePaddingClass();

    return (
        <div className={clsx(className, classes.pageSection, overrideClasses?.pageSection)} {...rest}>
            <div
                className={clsx(classes.maxWidthContainer, overrideClasses?.maxWidthContainer, {
                    [responsivePaddingClassName]: !noPadding,
                })}
            >
                {children}
            </div>
        </div>
    );
}
