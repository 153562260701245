interface IProps {
    open: boolean;
}

const CookieBanner = ({ open }: IProps) => {
    return open ? (
        <div
            id="consent_blackbar"
            style={{ position: "fixed", bottom: 0, zIndex: 100000, width: "100%" }}
            className="consent-blackbar"
        ></div>
    ) : (
        <></>
    );
};

export default CookieBanner;
