enum ResponseStatusEnum {
    Pending = 1,
    Ok = 200,
    Created = 201,
    NoContent = 204,
    MultipleChoices = 300,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    Error = 500,
}

export default ResponseStatusEnum;
